import React, { useState } from "react";
import "../assets/styles/settings.scss";
import cross from "../assets/icons/cross.svg";
import { TrashIcon } from "../commons/icons/TrashIcon";
import { EditIcon } from "../commons/icons/Editicon";
import { useHistory } from "react-router-dom";

export default ({
  //States
  screenSize,
  darkModeState,
  language,

  isLoadingSave,
  questionsAssistantState,
  //Handlers
  handleEditQuestion,
  handleAddQuestionAssistant,
  handleRemoveQuestion,
  inputRef,
  handleGenerateQuestions,
}) => {
  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
      //padding: `${screenSize > 800 ? '3% 2% 3% 210px' : '30px'}`,
      padding: `${screenSize > 800 ? "3% 2% 3% 260px" : "30px"}`,
      height: "100%",
      overflowY: `auto`,
    },
    containerLoader: {
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      height: "80%",
    },
    navbar: {
      display: "flex",
      flex: 1.5,
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    textUser: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: `transparent`,
    },
    buttonNew: {
      textDecoration: "none",
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      margin: "20px",
      // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      width: `${screenSize > 800 ? "180px" : <></>}`,
    },
    settings: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
      alignItems: "center",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      gap: 5,
    },
    singleSettingQualification: {
      display: "flex",
      boxShadow: "2px 2px 5px #4b4f5067",

      width: `${screenSize > 800 ? "100%" : "80vw"}`,

      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    singleSettingRecommendation: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      alignItems: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      justifyContent: "space-between",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    singleSetting: {
      display: "flex",
      //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",

      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    lineDetail: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    nameSetting: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: "0px 0px 5px 0px",
      fontSize: "18px",
      fontFamily: "InterBold",
    },
    valueSetting: {
      display: "flex",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "center",
      position: "relative",
    },
    loader: {
      display: "flex",
      alignSelf: "center",
    },
    sliderTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    slider: {
      width: "25%",
      height: "5px",
      borderRadius: "7px",
      cursor: "pointer",
      backgroundColor: "rgb(77, 194, 241)",
      WebkitAppearance: "none", // remueve los estilos por defecto del browser
    },
    inputWords: {
      display: "flex",
      justifyContent: "space-between",
      alignItem: "center",
    },
    badWordsInput: {
      border: "none",
      borderBottom: "1px solid  hsl(228, 12%, 44%)",
      color: `${darkModeState ? "white" : "black"}`,
      fontFamily: "InterBold",
      height: "40px",
      padding: "5px 10px",
      backgroundColor: "transparent",
      outline: "none",
    },
    defaultButton: {
      backgroundColor: "rgb(77, 194, 241)",
      boxShadow: "2px 3px 10px rgba(0, 0, 0, 0.127)",
      fontWeight: "400",
      outline: "none",
      padding: "10px 15px",
      border: "transparent",
      borderRadius: "7px",
      fontSize: ".9rem",
      width: "auto",
      transition: "all ease-in 0.3s",
      color: "white",
    },
    wordContainer: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
    },
    boxWords: {
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "#fff"}`,
      borderRadius: 7,
      padding: 10,
      height: "80%",
      overflowY: "auto",
      display: "none",
      marginTop: "8px",
      color: `${darkModeState ? "white" : "hsl(228, 12%, 44%)"}`,
    },
    ul: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      margin: 0,
      gap: 10,
    },
    li: {
      borderRadius: 7,
      listStyle: "none",
      padding: 5,
      display: "flex",
      alignItems: "center",
      width: "80%",
      boxShadow: "2px 3px 10px rgba(0, 0, 0, 0.127)",

      justifyContent: "space-between",
    },
    crossImg: {
      paddingLeft: 5,
      marginTop: "1px",
      height: "20px",
      cursor: "pointer",
    },
    custom: {
      color: "rgb(77, 194, 241)",
    },
    buttonUpload: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      padding: "10px",
      border: "0px",
      cursor: "pointer",
    },
  };

  const styleIcons = {
    marginRight: 7,
    maxWidth: 26,
    maxHeight: 30,
    minWidth: 26,
    minHeight: 30,
    color: "rgb(139, 151, 198)",
  };

  {
    const translateTechnicality = {
      english: {
        formal: "Formal",
        informal: "Friendly",
      },
      spanish: {
        formal: "Formal",
        informal: "Amigable",
      },
    };

    const btnGenerateQuestion = {
      english: "Generate questions",
      spanish: "Generar  preguntas",
    };

    const history = useHistory();
    const [selectedInput, setSelectedInput] = useState(null);
    return (
      <>
        <div style={styles.backColor} />
        <div style={styles.container}>
          <div style={styles.navbar}>
            <div style={styles.textsNavbar}>
              <h3 style={styles.titleNavbar}>
                {language === "english" ? "Questions" : "Preguntas"} ⚙️
              </h3>
              <h3 style={styles.subtitleNavbar}>
                {language === "english"
                  ? "Here you can add questions that will later be used to evaluate the agents."
                  : "Aquí puedes agregar preguntas que posteriormente serán evaluadas a los agentes."}
              </h3>
              <button
                onClick={handleGenerateQuestions}
                style={{
                  ...styles.buttonUpload,
                  width: "fit-content",
                  minWidth: "180px",
                  marginTop: 20,
                }}
              >
                {questionsAssistantState.isLoading && darkModeState ? (
                  <div class="spinner-border text-light" role="status"></div>
                ) : questionsAssistantState.isLoading && !darkModeState ? (
                  <div class="spinner-border text-dark" role="status"></div>
                ) : (
                  btnGenerateQuestion[language]
                )}
              </button>
              <button
                style={{
                  ...styles.buttonUpload,
                  position: "absolute",
                  right: 50,
                }}
                onClick={() => {
                  history.push("/knowledge");
                }}
              >
                {language === "english" ? "Knowledge" : "Conocimiento"}
              </button>
            </div>
          </div>

          {/* S */}
          <div style={styles.settings}>
            <div style={{ alignSelf: "start", marginTop: 10 }}>
              <h2
                style={{
                  fontSize: "1.5rem",
                  color: `${
                    darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"
                  }`,
                }}
              ></h2>
            </div>
            <form
              // className={darkModeState ? 'buttonDark' : 'buttonLight'}
              style={{
                ...styles.singleSettingRecommendation,
                padding: "20px 25px",
                marginBottom: 10,
                backgroundColor: darkModeState
                  ? "hsl(228, 28%, 20%)"
                  : "hsl(227, 47%, 96%)",
              }}
              onSubmit={handleAddQuestionAssistant}
            >
              <input
                disabled={isLoadingSave}
                ref={inputRef}
                required
                id="textDark"
                name="calification"
                className={
                  darkModeState
                    ? "buttonDark textDarkToggle"
                    : "buttonLight textLightToggle"
                }
                style={{
                  ...styles.badWordsInput,
                  width: "100%",
                }}
                placeholder={
                  language === "english"
                    ? "Add your question here..."
                    : "Agrega tu pregunta aquí..."
                }
                minLength={10}
              />
            </form>

            {questionsAssistantState.questions?.map((question, i) => {
              return (
                <ItemsQuestions
                  key={i}
                  value={question.message}
                  darkModeState={darkModeState}
                  i={i}
                  handleRemove={handleRemoveQuestion}
                  styles={styles}
                  language={language}
                  id={question.id}
                  handleEditQuestion={handleEditQuestion}
                  selectedInput={selectedInput}
                  setSelectedInput={setSelectedInput}
                />
              );
            })}

            {/* <form
              className={darkModeState ? "buttonDark" : "buttonLight"}
              style={{
                ...styles.singleSettingRecommendation,
                padding: "20px 25px",
                margin: 0,
              
              }}
              onSubmit={handleAddQuestionAssistant}
            >
              <input
                ref={inputRef}
                required
                name="calification"
                className={darkModeState ? "buttonDark" : "buttonLight"}
                style={{
                  ...styles.badWordsInput,
                  width: "100%",
                }}
                placeholder={
                  language === "english"
                    ? "Here you can add a question, always referring to the documentation."
                    : "Aqui puedes agregar una pregunta, referida siempre a la documentación"
                }
              />
            </form> */}
          </div>
        </div>
      </>
    );
  }
};

function ItemsQuestions({
  darkModeState,
  styles,
  value,
  i,
  handleRemove,
  language,
  id,
  handleEditQuestion,

  selectedInput,
  setSelectedInput,
}) {
  const [sureToDeleteChatModal, setSureToDeleteChatModal] = useState(false);
  const [questionInput, setQuestionInput] = useState(null);

  const styleIcons = {
    marginRight: 7,
    maxWidth: 26,
    maxHeight: 30,
    minWidth: 26,
    minHeight: 30,
    color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
  };

  const handleSubmitNewQuestion = (e) => {
    e.preventDefault();

    if (questionInput.message !== value) {
      handleEditQuestion(questionInput);
    }

    setQuestionInput(null);
  };

  return (
    <div
      className={darkModeState ? "buttonDark" : "buttonLight"}
      style={{
        ...styles.singleSettingRecommendation,
        minHeight: 90,
        margin: "0px 0px 5px 0px",
        position: "relative",
        alignItems: "center",
        padding: "15px 25px",
      }}
    >
      {questionInput && (
        <div
          style={{
            position: "absolute",
            right: "10px",
            top: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setQuestionInput(null);
          }}
        >
          <img src={cross} alt="x" />
        </div>
      )}
      {!sureToDeleteChatModal && (
        <>
          {questionInput && selectedInput.id === id ? (
            <form onSubmit={handleSubmitNewQuestion} style={{ width: "100%" }}>
              <input
                style={{
                  ...styles.badWordsInput,
                  width: "100%",
                }}
                type="text"
                value={questionInput.message}
                onChange={(e) =>
                  setQuestionInput({
                    ...questionInput,
                    message: e.target.value,
                  })
                }
              />
            </form>
          ) : (
            <>
              <span
                style={{
                  color: `${
                    darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"
                  }`,
                }}
              >
                {value}
              </span>

              <div style={{ display: "flex", gap: 20 }}>
                <span
                  onClick={() => {
                    const obj = {
                      id: id,
                      role: "assistant",
                      message: value,
                    };

                    setSelectedInput(obj);
                    setQuestionInput(obj);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <EditIcon styles={styleIcons} />
                </span>

                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setSureToDeleteChatModal(true)}
                >
                  <TrashIcon styles={styleIcons} />
                </span>
              </div>
            </>
          )}
        </>
      )}

      {sureToDeleteChatModal && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: `${
              darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
            }`,
            top: 0,
            left: 0,
            borderRadius: 5,
          }}
        >
          <span
            style={{
              color: darkModeState ? "rgb(255, 255, 255)" : "rgb(99, 104, 126)",
            }}
          >
            {language === "english"
              ? "Are you sure you want to delete this question?"
              : "¿Estás seguro de eliminar esta pregunta?"}
          </span>
          <div style={{ display: "flex", gap: 10 }}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRemove({
                  value: value,
                  index: i,
                  id: id,
                });
                setSureToDeleteChatModal(false);
              }}
              className="defaultButton"
              style={{ padding: "5px 20px", backgroundColor: "red" }}
            >
              Sí
            </button>

            <button
              className="defaultButton"
              style={{
                padding: "5px 20px",
                backgroundColor: "transparent",
                color: darkModeState
                  ? "hsl(228, 34%, 66%)"
                  : "hsl(228, 12%, 44%)",
                border: darkModeState
                  ? "1px solid hsl(228, 34%, 66%)"
                  : "1px solid hsl(228, 12%, 44%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSureToDeleteChatModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
