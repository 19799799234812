import axios from "axios";

export const GeneratingDefaultAssistants = async ( { companyName }) => {

  try {
    // const urlDefaultConfig = 'http://localhost:5000/insideone-onetraining/us-central1/generateDefaultAssistants'
    const urlDefaultConfig =
      "https://generatedefaultassistants-cfcs6xjhea-uc.a.run.app"; 
    console.log("companyName",companyName);
    const response = await axios(`${urlDefaultConfig}?instanceName=${companyName}`);
    console.log(response);
    return
  } catch (error) {
    console.error("Error al generar asistentes por defecto ", error);
    return
  }
  
}