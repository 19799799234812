const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: undefined,
    },
  },
};

export const updatedTitle = (title, subtitle, boolean, legendBoolean, darkTheme) => {
  let titleFontSize = 26;
  let subtitleFontSize = 14;

  if (window.innerWidth <= 1440) {
    titleFontSize = 20;
    subtitleFontSize = 12;
  }
  return {
    chartOptions,
    plugins: {
      ...chartOptions.plugins,
      title: {
        ...chartOptions?.plugins.title,
        text: title,
        color: `${darkTheme ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
        font: {
          size: titleFontSize,
        },
      },
      subtitle: {
        ...chartOptions.plugins.title,
        text: subtitle,
        display: boolean,
        color: `${darkTheme ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
        font: {
          size: subtitleFontSize,
        },
      },
      legend: {
        display: legendBoolean,
        color: `${darkTheme ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      },
    },
  };
};
