import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { loginUserPreferences } from "../store/actions/loginAction";
import Profile from "../components/Profile";
import Sidebar from "./Sidebar";
import firebase from "../config/firebaseIO";
import AuthService from "../services/auth.service";
import axios from "axios";
const urlListUsers = "https://listusers-cfcs6xjhea-uc.a.run.app";
//const urlListUsers = 'http://localhost:5000/insideone-onetraining/us-central1/listUsers'

const db = firebase.firestore();

const SettingsClient = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [popUp, setPopUp] = useState(false);
  const [recoverMail, setRecoverMail] = useState("");
  const [recoverMessage, setRecoverMessage] = useState(false);
  const [loadingDeleteUser, setLoadingDeleteUser] = useState(false);

  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.user.loginUser);
  const company = useSelector((state) => state.user.companyInfo);

  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);

  const [usersCompany, setUsersCompany] = useState(null);
  const [reloadUsers, setReloadUsers] = useState(false);
  //Solo debe hacer este pedido si es administrador

  useEffect(() => {
    if (loginUser.rol !== "Administrator") return;
    axios(
      `${urlListUsers}?adminID=${loginUser.userUID}&instance=${company.name}`
    )
      .then(({ data }) => {
        setUsersCompany(data);
      })
      .catch((err) => console.error(err));
  }, [reloadUsers]);

  const handleDeleteUser = async ({ userID, adminID }) => {
    const urlDeleteUser = `https://deleteuser-cfcs6xjhea-uc.a.run.app/?adminID=${adminID}&userID=${userID}`;
    try {
      setLoadingDeleteUser(true);
      await axios.delete(urlDeleteUser);
      setLoadingDeleteUser(false);
      setReloadUsers((prev) => !prev);
      return toast.success(language === 'english' ? 'User deleted' : 'Usuario eliminado' )
    } catch (error) {
      
      console.error(error);
      return toast.warn(language === 'english' ? 'It was not possible to delete the user' : 'No fue posible eliminar el usuario' )
    }
  };

  const handlePopUp = (e) => {
    setPopUp(!popUp);
    setRecoverMail("");
    setRecoverMessage(false);
  };

  const handleRecoverMail = (e) => {
    const value = e.target.value;
    setRecoverMail(value);
  };

  const handleRecover = (e) => {
    if (recoverMail != "") {
      AuthService.recover(recoverMail).then(
        (res) => {
          if (res) {
            setRecoverMail("");
            setRecoverMessage("yes");
          }
        },
        (error) => {
          setRecoverMessage("no");
        }
      );
    }
  };

  useEffect(() => {
    window.onresize = () => {
      setScreenSize(window.innerWidth);
    };
  }, []);

  return (
    <div>
      <Sidebar />
      <Profile
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        loginUser={{ ...loginUser, companyName: company.name }}
        popUp={popUp}
        handlePopUp={handlePopUp}
        handleRecover={handleRecover}
        handleRecoverMail={handleRecoverMail}
        recoverMessage={recoverMessage}
        usersCompany={usersCompany}
        setReloadUsers={setReloadUsers}
        handleDeleteUser={handleDeleteUser}
        loadingDeleteUser={loadingDeleteUser}
      />
    </div>
  );
};

export default SettingsClient;
