import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { toast } from "react-toastify";

export default ({
  isOpen,
  handleClose,
  darkModeState,
  language,
  loginUser,
  setReloadUsers,
}) => {
  if (!isOpen) return null;

  const [loading, setLoading] = useState(false);
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [jsonFile, setJsonFile] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [file, setFile] = useState(null);
  const [inputErrors, setInputErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  
  // valores iniciales del formulario
  const initialValuesForm = {
    firstName: "",
    lastName: "",
    email: "",
    rol: "Agent",
  };
  const [formData, setFormData] = useState(initialValuesForm);
  // funcion para manejar el click en el containerrr
  const handleContainerClick = () => {
    const dataInputs =
      formData.firstName || formData.lastName || formData.email;
    if (inputsDisabled || dataInputs) return;
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  // funccion para validar los inputs
  const validateInputs = () => {
    if (file) return true;
    const errors = {
      firstName: !formData.firstName,
      lastName: !formData.lastName,
      email: !formData.email || !validateEmail(formData.email),
    };
    setInputErrors(errors);
    return !errors.firstName && !errors.lastName && !errors.email;
  };
  // funcion para validar y limpiar el JSON
  const validateAndCleanJson = (json) => {
    const requiredProperties = ["firstName", "lastName", "mail", "rol"];

    // Filtrar y limpiar el JSON
    return json
      .map((item) => {
        let validItem = {};
        requiredProperties.forEach((prop) => {
          if (item.hasOwnProperty(prop)) {
            validItem[prop] = item[prop];
          }
        });
        return validItem;
      })
      .filter((item) => Object.keys(item).length > 0);
  };
  //funcion para subir archivo
  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      processFile(selectedFile);
    }
  };
  // funcion para procesar el archivo
  const processFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      let json = XLSX.utils.sheet_to_json(worksheet);

      json = validateAndCleanJson(json);

      setFile(file);
      setJsonFile(json);
      setInputsDisabled(true);

      console.log("Archivo subido:", file);
      console.log("Contenido JSON:", json);
    };
    reader.readAsArrayBuffer(file);
  };

  // funcion para arrastra y soltar
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  const handleDragLeave = () => {
    setDragOver(false);
  };

  // funcion para subir archivo
  const handleDrop = (e) => {
    e.preventDefault();
    const dataInputs =
      formData.firstName || formData.lastName || formData.email;
    if (dataInputs) return;

    setDragOver(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let json = XLSX.utils.sheet_to_json(worksheet);

        json = validateAndCleanJson(json);

        setFile(droppedFile);
        setJsonFile(json);
        setInputsDisabled(true);

        console.log("Archivo subido:", droppedFile);
        console.log("Contenido JSON:", json);
      };
      reader.readAsArrayBuffer(droppedFile);
    }
  };
  // funcion para limpiar el archivo
  const clearFile = () => {
    setFile(null);
    setInputsDisabled(false);
  };
  // funcion para manejar el cambio de inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({
      ...inputErrors,
      [name]: name === "email" ? !value || !validateEmail(value) : !value,
    });
  };
  // funcion para guardar el usuario
  const handleSaveUser = async () => {
    if (!file && !validateInputs()) {
      toast.error("Todos los campos son requeridos");
      return;
    }
    const dataInputs =
      formData.firstName || formData.lastName || formData.email;
    if (!dataInputs && !file) return alert("Tienes que completar algun campo");
    const instance = loginUser.companyName;
    const adminRol = loginUser.rol;
    const adminID = loginUser.userUID;
    console.log(loginUser);

    const urlAddUser = `https://adduser-cfcs6xjhea-uc.a.run.app/`;

    const urlAddUserByExcelFile = `https://adduserbyexcelfile-cfcs6xjhea-uc.a.run.app/`;

    let url = file ? urlAddUserByExcelFile : urlAddUser;
    let body = file
      ? {
          listUsers: jsonFile,
        }
      : {
          firstName: formData.firstName,
          lastName: formData.lastName,
          mail: formData.email,
          rol: formData.rol,
        };

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${url}?instance=${instance}&adminID=${adminID}`,
        body
      );

      setJsonFile(null);
      setFile(null);
      setFormData(initialValuesForm);
      setReloadUsers((prev) => !prev);
      toast.success("Usuario creado exitosamente!");
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Error al crear el usuario");
    } finally {
      setLoading(false);
    }
  };
  // Agregar una función de validación de email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const styles = {
    modalStyles: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: !darkModeState ? "white" : "hsl(230, 17%, 14%)",
      padding: "20px",
      zIndex: 1000,
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "80%",
      maxWidth: "600px",
    },
    overlayStyles: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 999,
    },
    closeButtonStyles: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      color: !darkModeState ? "black" : "white",
      fontSize: "20px",
      cursor: "pointer",
    },
    inputStyles: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #4dc2f1",
      fontFamily: "InterRegular",
      backgroundColor: !darkModeState ? "white" : "hsl(228, 34%, 20%)",
      color: !darkModeState ? "black" : "white",
    },
    dropdownStyles: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "5px",
      border: "1px solid #4dc2f1",
      fontFamily: "InterRegular",
      backgroundColor: !darkModeState ? "white" : "hsl(228, 34%, 20%)",
      color: darkModeState ? "white" : "black",
    },
    dragDropStyles: {
      border: `2px dashed ${dragOver ? "#4dc2f1" : "#4dc2f1"}`,
      borderRadius: "5px",
      padding: "20px",
      textAlign: "center",
      color: darkModeState ? "white" : "black",
      backgroundColor: dragOver
        ? "rgba(77, 194, 241, 0.1)"
        : darkModeState
        ? "hsl(228, 34%, 20%)"
        : "white",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    buttonAdd: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      padding: "10px",
      border: "0px",
      cursor: "pointer",
      height: "2.8rem",
      minWidth: "100px",
    },
  };

  return (
    <>
      {/* AGREGAR USUARIOS DE UNO */}
      <div style={styles.overlayStyles} onClick={handleClose} />
      <div style={styles.modalStyles}>
        <button style={styles.closeButtonStyles} onClick={handleClose}>
          ×
        </button>
        <h5 style={{ color: darkModeState ? "white" : "black" }}>
          {language === "english" ? "Add User" : "Agregar usuario"}👍
        </h5>
        <h5 style={styles.subtitleNavbar}>
          {language === "english"
            ? "You can add users one at a time."
            : "Podes agregar los usuarios de a uno a la vez."}
        </h5>
        <input
          type="text"
          name="firstName"
          placeholder={language === "english" ? "First Name" : "Nombre"}
          value={formData.firstName}
          onChange={handleInputChange}
          style={{
            ...styles.inputStyles,
            borderColor: inputErrors.firstName ? "red" : "#4dc2f1",
          }}
          disabled={inputsDisabled}
        />
        <input
          type="text"
          name="lastName"
          placeholder={language === "english" ? "Last Name" : "Apellido"}
          value={formData.lastName}
          onChange={handleInputChange}
          style={{
            ...styles.inputStyles,
            borderColor: inputErrors.lastName ? "red" : "#4dc2f1",
          }}
          disabled={inputsDisabled}
        />
        <input
          type="email"
          name="email"
          placeholder={language === "english" ? "Email" : "Correo Electrónico"}
          value={formData.email}
          onChange={handleInputChange}
          style={{
            ...styles.inputStyles,
            borderColor: inputErrors.email ? "red" : "#4dc2f1",
          }}
          disabled={inputsDisabled}
        />
        <select
          name="rol"
          value={formData.rol}
          onChange={handleInputChange}
          style={styles.dropdownStyles}
          disabled={inputsDisabled}
        >
          <option value="Administrator">
            {language === "english" ? "Administrator" : "Administrador"}
          </option>
          <option value="Supervisor">
            {language === "english" ? "Supervisor" : "Supervisor"}
          </option>
          <option value="Agent">
            {language === "english" ? "Agent" : "Agente"}
          </option>
        </select>

        <hr />

        {/* AGREGAR USUARIOS DE UN EXCEL */}
        <h5 style={{ color: darkModeState ? "white" : "black" }}>
          {language === "english"
            ? "Add multiple users"
            : "Agregar multiples usuarios"}
          ✌️
        </h5>
        <h5 style={styles.subtitleNavbar}>
          {language === "english"
            ? "Here you can add multiple users."
            : "Acá podes agregar multiples usuarios."}
        </h5>
        <div
          style={{ ...styles.dragDropStyles, marginTop: "20px" }}
          onDragOver={
            inputsDisabled ? (e) => e.preventDefault() : handleDragOver
          }
          onDragLeave={inputsDisabled ? null : handleDragLeave}
          onDrop={inputsDisabled ? null : handleDrop}
          onClick={handleContainerClick}
        >
          {file ? (
            <div>
              <p>{file.name}</p>
              <button style={styles.buttonAdd} onClick={clearFile}>
                {language === "english" ? "Cancel" : "Cancelar"}
              </button>
            </div>
          ) : (
            <div>
              <p>
                {language === "english"
                  ? "Drag and drop a file here .xlsx"
                  : "Arrastra y suelta un archivo aquí .xlsx"}
              </p>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <label htmlFor="fileInput" style={{ ...styles.dragDropLabel }}>
                {language === "english"
                  ? "or click here to select a file"
                  : "o haz clic aquí para seleccionar un archivo"}
              </label>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{ ...styles.buttonAdd, margin: "20px" }}
            onClick={handleSaveUser}
            disabled={loading}
          >
            {loading ? (
              <div class="spinner-border text-dark" role="status" />
            ) : language === "english" ? (
              "Save"
            ) : (
              "Guardar"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
