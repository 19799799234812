import React, { useState } from 'react';
import { dateFormater } from '../../utils/dateFormater';
import { useLocation } from 'react-router';
export default ({ handleFilter, screenSize, darkModeState, language, handleChangeRating, handleClickRange, refFilter, handleChangeDates, filters, handleChangeInputSearch, handleClearButton }) => {
    const location = useLocation().pathname.slice(1);
    const styles = {
        filters: {
            display: 'flex',
            /* flex: 1, */
            flexDirection: screenSize <= 1200 ? 'column' : 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            margin: `${screenSize > 800 ? '0px 20px' : '0px'}`,
            /* margin: `${screenSize > 800 ? "10px 20px 20px" : "0px"}`, */
            marginTop: '20px',
            height: screenSize <= 800 && screenSize > 410 ? '16rem' : screenSize <= 409 ? '22rem' : 'auto',
        },
        filterGroup: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0px 20px 0px 0px',
        },
        filterDateGroup: {
            display: 'flex',
            flexDirection: screenSize <= 400 ? 'column' : 'row',
            gap: '8px',
        },
        filterLabel: {
            fontFamily: 'InterBold',
            color: `${darkModeState ? 'hsl(228, 34%, 66%)' : 'hsl(228, 12%, 44%)'}`,
            /* padding: "0px 5px", */
        },
        filterInputDate: {
            border: 'none',
            color: `${darkModeState ? 'hsl(228, 34%, 66%)' : 'hsl(228, 12%, 44%)'}`,
            fontFamily: 'InterBold',
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            padding: '5px 10px',
            margin: '0px 5px 0px 0px',
            maxWidth: '300px',
        },
        filterInput: {
            border: 'none',
            color: `${darkModeState ? 'hsl(228, 34%, 66%)' : 'hsl(228, 12%, 44%)'}`,
            fontFamily: 'InterBold',
            width: '100%',
            height: '40px',
            borderRadius: '5px',
            padding: '5px 10px',
            maxWidth: '300px',
        },
        filterButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            outline: 0,
            color: '#fff',
            backgroundColor: '#4dc2f1',
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '8px 13px',
            width: '100px',
            /*  height: "2.5rem" */
        },
        filterButton2: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            outline: 0,
            color: '#fff',
            backgroundColor: '#4dc2f1',
            border: 'none',
            borderRadius: 5,
            margin: 0,
            padding: '8px 13px',
            fontSize: '.9rem',
            minWidth: '120px',
        },
        resetButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            outline: 0,
            color: '#4dc2f1',
            backgroundColor: 'transparent',
            border: '1px #4dc2f1 solid',
            borderRadius: 5,
            margin: '0px 0px 0px 10px',
            padding: '8px 13px',
            width: '100px',
            /*  height: "2.5rem" */
        },
        boxFilter: {
            display: 'none',
            backgroundColor: 'white',
            border: '1px solid #4dc2f1',
            borderRadius: 5,
            width: 200,
        },
        boxFilterAbs: {
            position: 'absolute',
            zIndex: 999,
            bottom: -135,
            right: 75,
            padding: '20px',
        },
        boxFilterFlex: {
            display: 'flex',
            flexDirection: 'column',
        },
        marginLabel: {
            marginBottom: '-1rem',
        },
        boxToRelative: {
            position: 'relative',
        },
    };

    const nameRangeFilter = {
        agents: language === 'english' ? 'Average Rating Filter' : 'Filtro por promedio',
        evaluations: language === 'english' ? 'Rating Filter' : 'Filtro por calificación',
    };

    return (
        <section style={styles.filters}>
            <div style={{ ...styles.filterGroup, ...{ justifyContent: 'start', width: '100%' } }}>
                <label style={styles.filterLabel}>{language === 'english' ? 'Date filter' : 'Filtro por fecha'}</label>
                <div style={styles.filterDateGroup}>
                    <input className={darkModeState ? 'buttonDark' : 'buttonLight'} style={styles.filterInputDate} type="date" id="start" name="start" value={dateFormater(filters.date.start, true, true)} onChange={handleChangeDates} />
                    <input className={darkModeState ? 'buttonDark' : 'buttonLight'} style={styles.filterInputDate} type="date" id="end" name="end" value={dateFormater(filters.date.end, true, true)} onChange={handleChangeDates} />
                </div>
            </div>

            {screenSize <= 930 ? (
                <div style={{ ...styles.filterGroup, width: '100%' }}>
                    <label style={styles.filterLabel}>{language === 'english' ? 'Search filter' : 'Filtro por búsqueda'}</label>
                    <input className={darkModeState ? 'buttonDark' : 'buttonLight'} style={styles.filterInput} type="text" name="inputSearch" id="searchBar" onChange={handleChangeInputSearch} value={filters.inputSearch} placeholder={language === 'english' ? 'Search...' : 'Buscar...'} />
                </div>
            ) : (
                <></>
            )}

            <div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
                <div style={{ ...styles.filterGroup, ...styles.boxToRelative }}>
                    <label style={styles.filterLabel}>{nameRangeFilter[location]}</label>
                    <button style={styles.filterButton2} onClick={handleClickRange}>
                        {screenSize <= 970 ? '' : 'Min'} {filters.rating.min}%-{screenSize <= 970 ? '' : 'Max '}
                        {filters.rating.max}%{' '}
                    </button>
                    <div style={{ ...styles.boxFilter, ...styles.boxFilterAbs }} ref={refFilter}>
                        <div style={styles.boxFilterFlex}>
                            <label htmlFor="filter__input_min_range" style={styles.marginLabel}>
                                <p>Min:{filters.rating.min}%</p>
                            </label>
                            <input id="filter__input_min_range" className="filterRating__input" min={0} max={100} onInput={handleChangeRating} value={filters.rating.min} type="range" name="min" />
                            <label htmlFor="filter__input_min_range" style={styles.marginLabel}>
                                <p>Max:{filters.rating.max}%</p>
                            </label>
                            <input id="filter__input_min_range" className="filterRating__input" min={0} max={100} onInput={handleChangeRating} value={filters.rating.max} type="range" name="max" />
                        </div>
                    </div>
                    {/* 
              <Select
                name="opciones"
                id="opciones"
                isMulti
                options={opcionesArrayFiltrar} 
                onChange={(valor) => {
                  onChange(valor);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              /> */}
                </div>

                {screenSize > 930 ? (
                    <div style={styles.filterGroup}>
                        <label style={styles.filterLabel}>{language === 'english' ? 'Search filter' : 'Filtro por búsqueda'}</label>
                        <input
                            className={darkModeState ? 'buttonDark' : 'buttonLight'}
                            style={styles.filterInput}
                            type="text"
                            name="inputSearch"
                            id="searchBar"
                            onChange={handleChangeInputSearch}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleFilter();
                                }
                            }}
                            placeholder={language === 'english' ? 'Search...' : 'Buscar...'}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <button
                    onClick={handleFilter}
                    style={styles.filterButton}
                    /*  onClick={() => {
              handleClick(arrayDeOpciones, startDate, endDate, searchBar);
              }} */
                >
                    {language === 'english' ? 'Apply' : 'Filtrar'}
                </button>
                <button style={styles.resetButton} onClick={handleClearButton}>
                    {language === 'english' ? 'Reset' : 'Limpiar'}
                </button>
            </div>
        </section>
    );
};
