import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversation: [],
  threadID: "",
  loadingConversation: false,
  history: [],
  loadingDelete: false,
  questions: [],
  isLoading: false,
};
export const questionsAssistant = createSlice({
  name: "questionsAssistant",

  initialState,
  reducers: {
    setQuestionsConversation: (state, { payload }) => {
      state.conversation = payload.conversation;
      state.threadID = payload.threadID;
      state.loadingConversation = false;
      state.questions = payload.questions;
    },
    setLoadingQuestionsConversation: (state, { payload }) => {
      state.loadingConversation = payload;
    },
    resetQuestionsTraining: (state) => {
      state.conversation = [];
      state.threadID = "";
    },

    setQuestionsHistory: (state, { payload }) => {
      state.history = payload;
    },
    setLoadingDelete: (state, { payload }) => {
      state.loadingDelete = payload;
    },
    removeQuestion: (state, { payload: questionID }) => {
      state.questions = state.questions.filter(({id}) => {
       return id !== questionID;
      });
    },
    setQuestions: (state, { payload }) => {
        state.questions = payload;
      },
    setIsLoading: (state,{payload}) =>{
      state.isLoading = payload
    }
  },
});

export const {
  setLoadingDelete,
  setQuestionsHistory,
  setQuestionsConversation,
  setLoadingQuestionsConversation,
  resetQuestionsTraining,
  pushToQuestionsConversation,
  removeQuestion,
  setQuestions,
  setIsLoading
} = questionsAssistant.actions;
