import { createSlice } from '@reduxjs/toolkit';
import firebase from '../../../../config/firebaseIO'
const db = firebase.firestore()





export const settingsSlice = createSlice({
    name: 'settings',

    initialState: {
        alertRating: null,
        badWordsArray: null,
        companyId : null,
        testRealAgent: false,
    },
    reducers: {
        setAlertRating: (state, { payload }) => {

            if(payload.type === 'login'){
                state.alertRating = payload.alertRating
            }else{
                const docRef = db.collection('companies').doc(state?.companyId);
                // Obtén el documento
                docRef.get();
                // Actualiza el array utilizando arrayUnion
                 docRef.update({
                    alertRating: payload.alertRating
                });
            }

        },
        resetAlertRating: (state) => {
            const docRef = db.collection('companies').doc(state?.companyId);
            // Obtén el documento
            docRef.get();
            // Actualiza el array utilizando arrayUnion

            
             docRef.update({
                alertRating: 39
            });
           
        },
        setTestRealAgent: (state,{payload})=>{
            state.testRealAgent = payload

        },
        setBadWordsArray: (state, { payload }) => {
            if (typeof payload  === 'object' && !Array.isArray(payload)) {
                state.companyId = payload.companyId
                state.badWordsArray = payload.badWordsArr
            } else {
                const docRef = db.collection('companies').doc(state.companyId);
                // Obtén el documento
                docRef.get();
                // Actualiza el array utilizando arrayUnion
                 docRef.update({
                    badWordsArray: Array.isArray(payload) ? payload : [ ...state.badWordsArray , payload]
                });
            }
        }
    }
})

export const { setAlertRating, resetAlertRating, setBadWordsArray, setTestRealAgent } = settingsSlice.actions