import React, { useState } from "react";
import axios from "axios";

import FineTuning from "../components/FineTuning";
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  pushToQualification,
  pushToRecommendations,
  removeRecommendation,
  setDropdownQualification,
  setDropdownRecommendations,
  setTechnicalityLevel,
  removeQualification,
} from "../store/reducers/slice/fineTuning/fineTuning";
//components
import Sidebar from "./Sidebar";
import { compareTwoArrays } from "../utils/compareTwoArrays";

const FineTuningClient = () => {
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const screenSize = useSelector((state) => state.screenSizeReducer.size);
  const fineTuningState = useSelector((state) => state.fineTuning);
  const { name: companyName } = useSelector((state) => state.user.companyInfo);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  

  const dispatch = useDispatch();

  //*compare original data
  const isOriginalDataChange = () => {
    const originalData = fineTuningState.originalData;
    if (originalData) {
      const currentRecommendations = fineTuningState.recommendations;
      const currentTechnicalityLevel = fineTuningState.technicalityLevel;
      const currentQualification = fineTuningState.qualifications;
      const isTechnicalityLevelChange =
        originalData?.technicalityLevel === currentTechnicalityLevel;
      return (
        compareTwoArrays(
          originalData?.recommendations,
          currentRecommendations
        ) &&
        compareTwoArrays(originalData?.qualification, currentQualification) &&
        isTechnicalityLevelChange
      );
    }
    return;
  };
  //Handle select
  /**
   * Handles the selection of the technicality level.
   */
  const hanldeSelectTechnicalityLevel = () => {
    //Get current technicality level
    const currentTechnicalityLevel = fineTuningState.technicalityLevel;
    //Array of technicality options
    const arrayTechnicalityOptions = ["formal", "informal"];
    //Get index of current technicality level
    const index = arrayTechnicalityOptions.indexOf(currentTechnicalityLevel);
    const nextIndex = index + 1;
    //Check if is the last index
    const isLastIndex = nextIndex === arrayTechnicalityOptions.length;
    //Get next technicality level
    const nextTechnicality = isLastIndex
      ? arrayTechnicalityOptions[0]
      : arrayTechnicalityOptions[nextIndex];
    dispatch(setTechnicalityLevel(nextTechnicality));
  };

  //Handle submit
  const handleSubmitRecommendations = (e) => {
    e.preventDefault();
    dispatch(pushToRecommendations(e.target[0].value));
    e.target[0].value = "";
  };

  const handleSubmitQualification = (e) => {
    e.preventDefault();
    dispatch(pushToQualification(e.target[0].value));
    e.target[0].value = "";
  };
  //Handle dropdown
  const handleDropdownRecommendations = () =>
    dispatch(
      setDropdownRecommendations(!fineTuningState.dropdownRecommendations)
    );
  const handleDropdownQualification = () =>
    dispatch(setDropdownQualification(!fineTuningState.dropdownQualifications));

  //!Removes
  const handleRemoveRecommendation = (e) => dispatch(removeRecommendation(e));
  const handleRemoveQualification = (e) => dispatch(removeQualification(e));

  //SEND
  const handleSendFineTuning = async () => {
    const endpoint =
      "https://updatefinetunning-cfcs6xjhea-uc.a.run.app";
    const body = {
      calification: fineTuningState.qualifications,
      technicalityLevel: fineTuningState.technicalityLevel,
      recommendation: fineTuningState.recommendations,
    };
    setIsLoadingSave(true);
    try {
      await axios.post(`${endpoint}/?instanceName=${companyName}`, body);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingSave(false);
  };

  return (
    <div>
      <Sidebar />
      <FineTuning
        //STATES
        fineTuningState={fineTuningState}
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        isLoadingSave={isLoadingSave}
        //Handlers
        //*Recomendation
        handleDropdownRecommendations={handleDropdownRecommendations}
        handleSubmitRecommendations={handleSubmitRecommendations}
        handleRemoveRecommendation={handleRemoveRecommendation}
        //*Technicality
        hanldeSelectTechnicalityLevel={hanldeSelectTechnicalityLevel}
        //*Qualification
        handleSubmitQualification={handleSubmitQualification}
        handleDropdownQualification={handleDropdownQualification}
        handleRemoveQualification={handleRemoveQualification}
        //*Send fine tuning
        handleSendFineTuning={handleSendFineTuning}
        //Functions
        isOriginalDataChange={isOriginalDataChange}
      />
    </div>
  );
};

export default FineTuningClient;
