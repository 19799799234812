import React, { useState, useEffect } from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
//container
import Sidebar from "./Sidebar";
//components

// actions redux
// import { thunkDemoEmotionAnalysis } from "../store/reducers/slice/demoEmotionAnalysisSlice/thunks";
// import {
//   resetConversation,
//   setConversation,
//   setSingleEmotionAnalisys,
// } from "../store/reducers/slice/demoEmotionAnalysisSlice/demoEmotionAnalysisSlice";

import {
  resetPlaygroundTraining,
  setConversation,
  pushToConversation,
  setIsLoading,
  setResponseTime,
} from "../store/reducers/slice/playgroundTraining";

//image
import { imgLogoGalicia } from "../assets/imgs_logo/imgs";
import { toast } from "react-toastify";
import NewPlayground from "../components/NewPlayground";
import { dateFormater } from "../utils/dateLogic/dateFormater";
import axios from "axios";

const PlaygroundClient = () => {
  const [messageToSend, setMessageToSend] = useState("");
  const screenSize = useSelector((state) => state.screenSizeReducer.size);

  const playgroundTrainingState = useSelector(
    (state) => state.playgroundTraining
  );

  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);

  const { name: companyName } = useSelector((state) => state.user.companyInfo);
  const testRealAgentState = useSelector(
    (state) => state.settingsReducer.testRealAgent
  );

  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.user.loginUser);
  const [popUp, setPopUp] = useState(false);

  const handleChangeText = (e) => {
    const value = e.target.value;
    setMessageToSend(value);
  };

  const infoUser = {
    name: loginUser.firstName + " " + loginUser.lastName,
    userId: loginUser.userUID,
  };
  console.log(">>>>>>>>>InicialState", playgroundTrainingState);
  console.log(">>>>>>>>>", setResponseTime());
  //SUBMIT
  const handleSubmit = async (e) => {
    const startTime = playgroundTrainingState.responseTime;
    e.preventDefault();
    //! GUARDAR EL FINAL DEL TIIEMPO
    const endTime = new Date().getTime();
    const responseTime = (endTime - startTime) / 1000;
    console.log("responseTime==>", responseTime);
    if (messageToSend.length <= 5)
      return toast.warn(
        language === "english"
          ? "Your message must be at least 5 characters long"
          : "Tu mensaje debe tener al menos 5 caracteres"
      );
    const message = messageToSend;

    dispatch(pushToConversation(message));
    setMessageToSend("");
    dispatch(setIsLoading(true));
    // const url ="http://localhost:5000/insideone-onetraining/us-central1/runAssistantClient";
      
    const url = "https://runassistantclient-cfcs6xjhea-uc.a.run.app/";


    const conversation = [
      ...playgroundTrainingState.conversation,
      { role: "user", message },
    ];
    const questionID = playgroundTrainingState.questionID;
    const { data } = await axios.post(
      `${url}?queryRefID=${playgroundTrainingState.queryRefID}&instanceName=${companyName}`,
      {
        messageUser: message,
        responseTime: responseTime,
        infoUser,
        conversation: conversation.reverse(),
        questionID,
      }
    );

    dispatch(setConversation(data));
  };

  // TODO Cuando

  const handleInitTest = async () => {
    try {
      dispatch(setIsLoading(true));
      const url = "https://runassistantclient-cfcs6xjhea-uc.a.run.app/";
      //const url =
        // "http://localhost:5000/insideone-onetraining/us-central1/runAssistantClient";
      //const url = 'https://runassistant-cfcs6xjhea-uc.a.run.app/'
      const { data } = await axios.post(`${url}?instanceName=${companyName}`, {
        messageUser: "Iniciar conversación",
        infoUser,
      });
      //!GUARDAR INICIO DEL TIEMPO
      dispatch(setResponseTime(new Date().getTime()));

      dispatch(setConversation(data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleInitTestRealAgent = async () => {
    try {
      dispatch(setIsLoading(true));
      const { data } = await axios.post(
        "https://send-training-to-avaya-fohynjygzq-uc.a.run.app/?instance=InsideOne",
        { messageUser: "Iniciar conversación" }
      );

      console.log(data);
      dispatch(setConversation(data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    dispatch(resetPlaygroundTraining());
  };

  const handlePopUpFeatures = () => {
    setPopUp(!popUp);
  };

  return (
    <div>
      <Sidebar />
      <NewPlayground
        imgLogoGalicia={imgLogoGalicia}
        messageToSend={messageToSend}
        darkModeState={darkTheme}
        language={language}
        screenSize={screenSize}
        handleSubmit={handleSubmit}
        handleChangeText={handleChangeText}
        handleReset={handleReset}
        status={"ready"}
        loginUser={loginUser}
        handlePopUpFeatures={handlePopUpFeatures}
        popUpFeatures={popUp}
        handleInitTest={handleInitTest}
        companyName={companyName}
        playgroundTrainingState={playgroundTrainingState}
        handleInitTestRealAgent={handleInitTestRealAgent}
        testRealAgentState={testRealAgentState}
      />
    </div>
  );
};

export default PlaygroundClient;
