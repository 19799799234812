import React, { useState, memo } from "react";
import Sidebar from "../components/Sidebar";
import { useHistory } from "react-router-dom";
import {
  useSelector,
  useDispatch,
} from "react-redux";
import AuthService from "../services/auth.service";
import {
  loginUserPreferences,
  logoutUser,
  companyInfo,
  surveysInfo,
} from "../store/actions/loginAction";
import {
  saveMessagesSelected,
  saveChatSelected,
  savePersonSelected,
  saveChannel,
} from "../store/actions/messageAction";
/* import firebase from '../config/firebaseBBVA';
 */
import firebase from "../config/firebaseIO";

import { useLocation } from "react-router";

const db = firebase.firestore();

const SidebarContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginUser = useSelector(
    (state) => state.user.loginUser
  );
  const companyUser = useSelector(
    (state) => state.user.companyInfo
  );
  const language = useSelector(
    (state) => state.user.language
  );
  const darkTheme = useSelector(
    (state) => state.user.darkTheme
  );
  const location = useLocation();
  const playgroundState = useSelector(
    (state) => state.playground
  );
  const settings = useSelector(
    (state) => state.settingsReducer.value
  );
  const screenSize = useSelector(
    (state) => state.screenSizeReducer.size
  );
  const [toggleSidebar, setToggleSidebar] =
    useState(false);

  const mail = loginUser.mail.split('@')[1]

  const handleToggleSidebar = () => {
    setToggleSidebar((prev) => !prev);
  };

  const handleProfile = (e) => {
    history.push("/profile");
  };

  const handleLogout = (e) => {
    AuthService.logout();
    dispatch(logoutUser());
    dispatch(
      loginUserPreferences("status", "pending")
    );
    dispatch(saveChatSelected(""));
    dispatch(savePersonSelected(""));
    dispatch(saveChannel(""));
    dispatch(companyInfo({}));
    dispatch(surveysInfo([]));
    dispatch(saveMessagesSelected([]));
    history.push("/login");
  };

  return (
    <Sidebar
      handleLogout={handleLogout}
      handleProfile={handleProfile}
      loginUser={loginUser}
      darkModeState={darkTheme}
      language={language}
      companyUser={companyUser}
      screenSize={screenSize}
      toggleSidebar={toggleSidebar}
      handleToggleSidebar={handleToggleSidebar}
      mail={mail}
    />
  );
};

export default React.memo(SidebarContainer);
