import React, { useState, useEffect } from "react";
//components
import Knowledge from "../components/Knowledge";
import Sidebar from "./Sidebar";
// * Redux
import { useDispatch, useSelector } from "react-redux";
//axios
import axios from "axios";
import firebase from "../config/firebaseIO";

import { getStorage, ref } from "firebase/storage";
import { toast } from "react-toastify";

import jsPDF from "jspdf";
import { SubTitle } from "chart.js";
import {
  setIsLoading,
  setManualKnowledge,
} from "../store/reducers/slice/infoAssistant/infoAsssistant";

const KnowledgeClient = () => {
  //redux
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const screenSize = useSelector((state) => state.screenSizeReducer.size);
  const infoAssistant = useSelector((state) => state.infoAssistant);
  const isLoading = useSelector((state) => state.infoAssistant.isLoading);
  const { name: companyName } = useSelector((state) => state.user.companyInfo);
  const dispatch = useDispatch();
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  //infoAssitant.isLoading

  //useState
  const [modalUpdateFile, setModalUpdateFile] = useState({
    toggleModal: false,
    text: "",
    isLoading: false,
    filename: "",
    show_file: false
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnabledFile, setIsEnabledFile] = useState(false);

  const handleChooseFile = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return toast.warn(
        language === "english"
          ? "Error uploading file"
          : "Error al cargar el archivo"
      );
    }
    setLoading(true);
    try {
      const storageRef = firebase
        .storage()
        .ref(`${companyName}/${selectedFile.name}`);
      await storageRef.put(selectedFile);

      const filePath = await storageRef.getDownloadURL();
      console.log(filePath);
      let url;
      if (
        selectedFile.name.endsWith(".mp3") ||
        selectedFile.name.endsWith(".mp4")
      ) {
        url = "https://transcriptionaudiototext-cfcs6xjhea-uc.a.run.app";
      } else {
        url = 'https://updatefileknowledge-cfcs6xjhea-uc.a.run.app'
        // url =
        //   "http://localhost:5000/insideone-onetraining/us-central1/updateFileKnowledge";
      }

      const response = await axios.post(`${url}?instanceName=${companyName}`, {
        filePath,
        checkboxChecked,
        fileName: selectedFile?.name || "",
        created_at: selectedFile?.lastModified,
      });

      if (
        selectedFile.name.endsWith(".mp3") ||
        selectedFile.name.endsWith(".mp4")
      ) {
        let nameToPDF;
        if (selectedFile.name.endsWith(".mp3")) {
          nameToPDF = selectedFile.name.replace(".mp3", ".pdf");
        }
        if (selectedFile.name.endsWith(".mp4")) {
          nameToPDF = selectedFile.name.replace(".mp4", ".pdf");
        }

        //nameToPDF = selectedFile.name.replace('.mp4', '.pdf')
        console.log("nameToPDF==>", nameToPDF);
        const filePath = await uploadFilesToFirestorage({
          filename: nameToPDF,
          text: response.data,
        });
        await uploadFileToOpenaiTwo({ filePath , checkboxChecked,
          fileName: selectedFile?.name || "",
          created_at: selectedFile?.lastModified, });
      }
      // ! const decodedFilePath = decodeURIComponent(filePath)
      setSelectedFile(null);
      toast.success(
        language === "english"
          ? "File uploaded successfully"
          : "File uploaded successfully"
      );
    } catch (error) {
      console.error("error en la carga", error);
      toast.warn(
        language === "english"
          ? "Error uploading file"
          : "Error al cargar el archivo"
      );
    }
    setLoading(false);

    if(checkboxChecked){
      setCheckboxChecked(false)

    }
  };

  const handleUpdateDoc = async ({filename , file_path , showFile}) => {
    try {
      const fileExtension = filename.split(".").pop().toLowerCase();
      console.log(showFile)
      setModalUpdateFile({
        ...modalUpdateFile,
        isLoading: true,
        toggleModal: true,
        show_file: showFile
      });

      // const urlLocal = "http://localhost:5000/insideone-onetraining/us-central1/getInfoFile"
      const urlDeploy = "https://getinfofile-cfcs6xjhea-uc.a.run.app"

      const { data: text } = await axios.post(
        urlDeploy,
        {
          fileUrl: file_path,
          fileExtension: fileExtension,
        }
      );

      setModalUpdateFile({
        isLoading: false,
        toggleModal: true,
        text: text,
        filename: filename,
        show_file: showFile
      });
    } catch (error) {
      console.error("Error al obtener el archivo:", error);
    }
  };

  const handleSaveDoc = async () => {
    const { text, filename, show_file } = modalUpdateFile;
    const fileExtension = filename.split(".").pop().toLowerCase();
    
    switch (fileExtension) {
      case "pdf":
        setModalUpdateFile({ ...modalUpdateFile, isLoading: true });
        const pdfDoc = new jsPDF();
        const margin = 10;
        const pageWidth = pdfDoc.internal.pageSize.width;
        const pageHeight = pdfDoc.internal.pageSize.height;
        const lineHeight = 1; // Ajusta el valor según el espaciado vertical deseado

        let y = margin; // Posición vertical inicial
        const maxPageHeight = pageHeight - 2 * margin;

        // Establecer el tamaño de la letra
        pdfDoc.setFontSize(10);

        const addTextToPage = (text) => {
          const textHeight = pdfDoc.getTextDimensions(text).h;
          if (y + textHeight + lineHeight > maxPageHeight) {
            // Agregar nueva página si el texto supera el límite vertical
            pdfDoc.addPage();
            y = margin;
          }

          pdfDoc.text(text, margin, y);
          y += textHeight + lineHeight; // Añadir el espaciado vertical
        };

        // Dividir el texto en líneas y agregarlas al PDF
        const lines = pdfDoc.splitTextToSize(text, pageWidth - 2 * margin);
        lines.forEach((line) => {
          addTextToPage(line);
        });

        // Guardar el PDF
        const blob = pdfDoc.output("blob");
        // Sube el blob a Firebase Storage
        const storageRef = firebase.storage().ref(`${companyName}/` + filename);
        await storageRef.put(blob);
        const filePath = await storageRef.getDownloadURL(); /// obtener URL

        // const url = "http://localhost:5000/insideone-onetraining/us-central1/updateFileKnowledge";
      
        const url = 'https://updatefileknowledge-cfcs6xjhea-uc.a.run.app'

        const created_at = Date.now();


        

        const response = await axios.post(
          url + "?instanceName=" + companyName,
          { filePath , fileName  : filename , created_at  , checkboxChecked: show_file }
        );

        setModalUpdateFile({
          ...modalUpdateFile,
          isLoading: false,
          toggleModal: false,
        });
        toast.success(
          language === "english"
            ? "File uploaded successfully"
            : "Archivo subido con exito"
        );

        break;
      default:
        toast.warn(
          language === "english"
            ? "Format not availeble yet"
            :  "Formato todavia no esta disponible"
           
        );

        break;
    }
  };

  const handleDeleteDoc = async ({ fileId, file_path }) => {
     const endpoint = "https://deletefile-cfcs6xjhea-uc.a.run.app/";
   
      //  const endpoint ="http://localhost:5000/insideone-onetraining/us-central1/deleteFile";

    try {
      const storageRef = firebase.storage().refFromURL(file_path);
      await storageRef.delete();

      await axios.delete(
        `${endpoint}?fileID=${fileId}&instanceName=${companyName}`
      );
    } catch (error) {
      console.error(error);
    }
  };

  

  const uploadFilesToFirestorage = async ({ filename, text }) => {
    const pdfDoc = new jsPDF();
    const margin = 10;
    const pageWidth = pdfDoc.internal.pageSize.width;
    const pageHeight = pdfDoc.internal.pageSize.height;
    const lineHeight = 1; // Ajusta el valor según el espaciado vertical deseado

    let y = margin; // Posición vertical inicial
    const maxPageHeight = pageHeight - 2 * margin;

    // Establecer el tamaño de la letra
    pdfDoc.setFontSize(10);

    const addTextToPage = (text) => {
      const textHeight = pdfDoc.getTextDimensions(text).h;
      if (y + textHeight + lineHeight > maxPageHeight) {
        // Agregar nueva página si el texto supera el límite vertical
        pdfDoc.addPage();
        y = margin;
      }

      pdfDoc.text(text, margin, y);
      y += textHeight + lineHeight; // Añadir el espaciado vertical
    };

    // Dividir el texto en líneas y agregarlas al PDF
    const lines = pdfDoc.splitTextToSize(text, pageWidth - 2 * margin);
    lines.forEach((line) => {
      addTextToPage(line);
    });

    // Guardar el PDF
    const blob = pdfDoc.output("blob");
    // Sube el blob a Firebase Storage
    const storageRef = firebase.storage().ref(`${companyName}/` + filename);
    await storageRef.put(blob);
    const filePath = await storageRef.getDownloadURL();

    return filePath;
  };

  const uploadFileToOpenaiTwo = async ({ filePath , checkboxChecked,
    fileName,
    created_at }) => {
    // const url = "http://localhost:5000/insideone-onetraining/us-central1/updateFileKnowledge";
     
    const url = "https://updatefileknowledge-cfcs6xjhea-uc.a.run.app";
    const response = await axios.post(url + "?instanceName=" + companyName, {
      filePath,
      checkboxChecked,
      created_at,
      fileName
    });
    return response;
  };

  const convertToText = (array) => {
    console.log("arr===>", array);
    let text = "";
    array.forEach((element) => {
      text += `${element.title}: \n ${element.subtitle}: \n ${element.description}. \n`;
    });
    return text;
  };

  const generateManualKnowleadge = async (
    inputsKnowleadgeValues,
    setIsLoadingAddManual,
    setShowInputField,
    setInputsKnowleadgeValues
  ) => {
    const storageRef = firebase.storage().ref(`${companyName}`);
    const endpointUploadManualKnowleadge =
      "https://uploadmanualknowleadge-cfcs6xjhea-uc.a.run.app";
    //*1: cheakear si existe el documento "manual_knowleadge_test.pdf" en firebase storage
    const filename = "manual_knowleadge_test.pdf";
    //*Si no existe lo crea en el storage y lo sube a openai, con todo lo que eso conlleva

    const { data: arrayKnowledge } = await axios.post(
      endpointUploadManualKnowleadge + `?instanceName=${companyName}`,
      {
        singleManualKnowledge: {
          title: inputsKnowleadgeValues.title,
          subtitle: inputsKnowleadgeValues.subtitle,
          description: inputsKnowleadgeValues.textarea,
        },
      }
    );
    setIsLoadingAddManual(false);
    setShowInputField(false);
    setInputsKnowleadgeValues({
      title: "",
      subtitle: "",
      textarea: "",
    });
    const newText = convertToText(arrayKnowledge);
    const filePath = await uploadFilesToFirestorage({
      storageRef,
      filename,
      text: newText,
    });
    console.log(filePath);
    const created_at = Date.now()
    const res = await uploadFileToOpenaiTwo({ filePath , checkboxChecked , fileName: filename , created_at });
    return res;
  };

  const handleDeleteManualKnowledge = async ({ fileId }) => {
    console.log(setIsLoading);
    dispatch(setIsLoading(true));

    const storageRef = firebase.storage().ref(`${companyName}`);
    const endpointDeleteManualKnowleadge =
      "https://deletemanualknowleadge-cfcs6xjhea-uc.a.run.app";

    const filename = "manual_knowleadge_test.pdf";
    const { data: arrayKnowledge } = await axios.put(
      endpointDeleteManualKnowleadge +
        `?instanceName=${companyName}&fileId=${fileId}`,
      { isEnabled }
    );

    if (Array.isArray(arrayKnowledge)) {
      const newText = convertToText(arrayKnowledge);
      const filePath = await uploadFilesToFirestorage({
        storageRef,
        filename,
        text: newText,
      });

      const created_at = Date.now()

      const res = await uploadFileToOpenaiTwo({ filePath , fileName: filename , checkboxChecked , 
        created_at });

      return res;
    } else {
      console.error("error al eliminar");
    }
  };

  const handleEditKnowledge = async (knowledgeToUpdate) => {
    const storageRef = firebase.storage().ref(`${companyName}`);
    const knowledgeArray = infoAssistant.manualKnowledge;

    const newKnowledge = knowledgeArray.map((knowledge) =>
      knowledge.id === knowledgeToUpdate.id ? knowledgeToUpdate : knowledge
    );

    const endpoint = "https://editmanualknowledge-cfcs6xjhea-uc.a.run.app";
    const filename = "manual_knowleadge_test.pdf";
    const { data: newKnowledgeArray } = await axios.post(
      endpoint + `?instanceName=${companyName}`,
      { newKnowledge, isEnabled }
    );
    if (Array.isArray(newKnowledgeArray)) {
      dispatch(setManualKnowledge(newKnowledge));
      const fileConverted = convertToText(newKnowledgeArray);
      const filePath = await uploadFilesToFirestorage({
        storageRef,
        filename,
        text: fileConverted,
      });
      console.log(filePath);
      const created_at = Date.now()

      uploadFileToOpenaiTwo({ filePath , checkboxChecked, fileName: filename , created_at  });
    } else {
      console.error("Error al editar");
    }
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    console.log(checkboxChecked);
  };

  // const handleViewFile = async () => {
  //   if (checkboxChecked) {
  //     await handleUpload();
  //   }
  // };

  return (
    <div>
      <Sidebar />

      <Knowledge
        language={language}
        darkModeState={darkTheme}
        screenSize={screenSize}
        handleChooseFile={handleChooseFile}
        infoAssistant={infoAssistant}
        handleUpload={handleUpload}
        loading={loading}
        selectedFile={selectedFile}
        handleUpdateDoc={handleUpdateDoc}
        modalUpdateFile={modalUpdateFile}
        setModalUpdateFile={setModalUpdateFile}
        handleSaveDoc={handleSaveDoc}
        handleDeleteDoc={handleDeleteDoc}
        generateManualKnowleadge={generateManualKnowleadge}
        handleDeleteManualKnowledge={handleDeleteManualKnowledge}
        handleEditKnowledge={handleEditKnowledge}
        setIsEnabled={setIsEnabled}
        isEnabled={isEnabled}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsEnabledFile={setIsEnabledFile}
        isEnabledFile={isEnabledFile}
        handleCheckboxChange={handleCheckboxChange}
        checkboxChecked={checkboxChecked}
      />
    </div>
  );
};

export default KnowledgeClient;
