import React, { useEffect, useRef, useState } from "react";
//components
import Tables from "../commons/Tables";
import SingleView from "../commons/singleView/SingleView";
import { toast } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import ReactExport from "react-export-excel";
import Select from "react-select";
import FilterOneFeelClient from "../containers/filter/FilterOneFeel";
//utils
import { dateFormater } from "../utils/dateFormater";
import { PlaygroundModeAlert } from "../commons/PlaygroundModeAlert";
// Para exportar los archivos en Excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default ({
  paginatedData,
  darkModeState,
  screenSize,
  handleOpenModal,
  handleCloseModal,
  currentPage,
  totalPages,
  handlePreviousClick,
  handleNextClick,
  modalState,
  language,
  location,
  totalPagesArr,
  history,
  playgroundMode,
  isFetching,
}) => {
  const styles = {
    backColor: {
      minHeight: "100vh",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    container: {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      height: "100%",
    },
    main: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      padding: "10px",
      marginTop: "20px",
    },
    buttonMain: {
      width: "auto",
    },
    disabledButton: {
      backgroundColor: "gray",
      cursor: "default",
    },
    defaultButton: {
      backgroundColor: "rgb(77, 194, 241)",
      boxShadow: "2px 3px 10px rgba(0, 0, 0, 0.127)",
      outline: "none",
      padding: "10px 15px",
      border: "transparent",
      borderRadius: "7px",
      fontSize: ".9rem",
      width: "auto",
      transition: "all ease-in 0.3s",
      color: "white",
    },
    playgroundButton: {
      backgroundColor: "rgb(77, 194, 241)",
      boxShadow: "2px 3px 10px rgba(0, 0, 0, 0.127)",
      outline: "none",
      padding: "10px 15px",
      border: "transparent",
      borderRadius: "7px",
      fontSize: ".9rem",
      width: "20%",
      transition: "all ease-in 0.3s",
      color: "white",
      margin: "5% 40%",
    },
    containerLoader: {
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      height: "80%",
    },
    navbar: {
      display: "flex",
      /* flex: 1.5, */
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      /*  marginBottom: `${screenSize > 800 ? "0px" : "30px"}`, */
    },
    textsNavbar: {
      display: "flex",
      /* flex: 8, */
      flexDirection: "column",
    },
    titleNavbar: {
      textAlign: "left",
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    textUser: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: `transparent`,
    },
    buttonNew: {
      textDecoration: "none",
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      margin: "20px",
      // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      width: `${screenSize > 800 ? "180px" : <></>}`,
    },
    total: {
      display: "flex",
      /* flex: 1, */
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: `${screenSize > 800 ? "10px 20px 10px" : "0px"}`,
    },
    filters: {
      display: "flex",
      /* flex: 1, */
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      /* margin: `${screenSize > 800 ? "10px 20px 20px" : "0px"}`, */
      marginTop: "20px",
    },
    filterGroup: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: "0px 20px 0px 0px",
    },
    filterDateGroup: {
      display: "flex",
      flexDirection: "row",
    },
    filterLabel: {
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      /* padding: "0px 5px", */
    },
    filterInputDate: {
      border: "none",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      padding: "5px 10px",
      margin: "0px 5px 0px 0px",
    },
    filterInput: {
      border: "none",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      padding: "5px 10px",
    },
    filterButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      borderRadius: 5,
      margin: 0,
      padding: "8px 13px",
      width: "100px",
    },
    filterButton2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      borderRadius: 5,
      margin: 0,
      padding: "8px 13px",
    },
    resetButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#4dc2f1",
      backgroundColor: "transparent",
      border: "1px #4dc2f1 solid",
      borderRadius: 5,
      margin: "0px 0px 0px 10px",
      padding: "8px 13px",
      width: "100px",
    },
    singleReportReference: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      margin: `${screenSize > 800 ? "30px 20px" : "0px"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "20px 15px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      // margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    reports: {
      display: "flex",

      flex: 11,
      flexDirection: "column",
      alignItems: "center",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
    },
    singleReport: {
      display: "flex",
      //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "10px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    lineDetail: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    time: {
      display: "flex",
      flex: 1,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent: "flex-start",
      //   paddingRight: '0px'
    },
    singleCounter: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "10px 25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: `${screenSize > 800 ? "2.4% 0px -10px" : "10px"}`,
      backgroundColor: "#4dc2f1",
      color: "#fff",
    },
    titleCounter: {
      display: "flex",
      flex: 4,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: "0px",
      fontSize: "18px",
      fontFamily: "InterBold",
      textAlign: "start",
      alignSelf: "center",
      color: "#fff",
    },
    numberCounter: {
      display: "flex",
      flex: 2,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "end",
      alignSelf: "center",
      justifyContent: "flex-end",
      color: "#fff",
    },
    downloadsContainer: {
      display: "flex",
      width: screenSize > 800 ? "100%" : "80vw",
      flexDirection: screenSize <= 600 ? "column" : "row",
      alignItems: "center",
      justifyContent: "flex-end",
      borderRadius: "5px",
      padding: "10px 0px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      margin: screenSize > 800 ? "1.4% 0px 0px" : "10px",
      backgroundColor: "transparent",
      gap: 10,
    },
    downloadsButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      outline: 0,
      color: "#4dc2f1",
      backgroundColor: "transparent",
      border: "1px #4dc2f1 solid",
      borderRadius: 5,
      margin: "0px 0px 0px 10px",
      padding: "8px 13px",
      width: screenSize <= 600 ? "20rem" : screenSize <= 450 ? "19.5rem " : "180px",
    },
    loader: {
      display: "flex",
      alignSelf: "center",
    },
    boxToRelative: {
      position: "relative",
    },
    boxFilter: {
      display: "none",
      backgroundColor: "white",
      border: "1px solid #4dc2f1",
      borderRadius: 5,
      width: 200,
    },
    boxFilterAbs: {
      position: "absolute",
      zIndex: 999,
      bottom: -135,
      right: 75,
      padding: "20px",
    },
    boxFilterFlex: {
      display: "flex",
      flexDirection: "column",
    },
    marginLabel: {
      marginBottom: "-1rem",
    },
  };
  const handleWarnData = () => {
    return toast.warn(
      language === "english" ? "There is no data to download" : "No hay datos para descargar"
    );
  };

  const dateToday = dateFormater(new Date(), false);
  const headersOptions = {
    agents:
      language === "english"
        ? ["Agent", "Average rating", "Interactions", "Index"]
        : ["Agente", "Promedio rating", "Interacciones", "Índice"],
    conversations:
      language === "english"
        ? [
            "Date",
            "Agent",
            "Channel",
            "Rating",
            "Satisfaction index",
            "DNI",
            "Phone number",
            "Emotions",
            "Keywords",
          ]
        : [
            "Fecha",
            "Teléfono",
            "DNI",
            "Calificación",
            "Índice de satisfacción",
            "Emociones",
            "Palabras clave",
            "Resumen",
            "Agente",
            "Canal",
          ],
  };

  console.log(paginatedData)

  const options = {
    fieldSeparator: ",",
    filename: `${
      location.pathname === "/conversations"
        ? language === "english"
          ? `Conversations`
          : "Conversaciones"
        : language === "english"
        ? `Agents`
        : "Agentes"
    }-CSV-${dateToday}-${language === "english" ? "page" : "página"}-${currentPage}`,
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useBom: true,
    useTextFile: false,
    useKeysAsHeaders: false,
    headers: headersOptions[location.pathname.slice(1)],
  };

  let handleDownloadCSV = () => {
    if (!paginatedData) return handleWarnData();

    let arrConversations;
    if (location.pathname === "/conversations") {
      arrConversations = paginatedData.map((el) => {
        return {
          date: el.dateFormated,
          phone: el.client.phone_number,
          dni: el.client.dni,
          rating: el.client.rating,
          satisfaction: el.client.satisfaction_index,
          emotions: el.client.emotions.join(", "),
          keywords: el.client.keywords.join(" ,"),
          summary: el.client.summary,
          agent: el.agent,
          channel: el.channel,
        };
      });
    }

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(
      location.pathname === "/conversations" ? arrConversations : paginatedData
    );
  };


  return (
    <div style={styles.backColor}>
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>
              {language === "english"
                ? "Agents"
                : "Agentes"}{" "}
              😃
            </h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english"
                ? "Individual agent average."
                : "Promedio individual de los agentes."}
            </h3>
          </div>
        </div>
  
        {paginatedData ? (
          <>
            {/* Filtros */}
            <FilterOneFeelClient />

            {/* Descarga de excel y csv */}
            {/* <div style={styles.reports}>
              <>
                <div
                  className={darkModeState ? "buttonDark" : "buttonLight"}
                  style={styles.downloadsContainer}
                >
                  {!paginatedData ? (
                    <button onClick={handleWarnData} style={styles.downloadsButton}>
                      {language === "english" ? "Download Excel" : "Descargar Excel"}
                    </button>
                  ) : (
                    <ExcelFile
                      element={
                        <button style={styles.downloadsButton}>
                          {language === "english" ? "Download Excel" : "Descargar Excel"}
                        </button>
                      }
                      filename={`OneFeel-Excel-${dateToday}-page-${currentPage}`}
                    >
                     { 
                        <ExcelSheet
                          data={paginatedData}
                          name={`${
                            language === "english"
                              ? `Agents - ${dateToday}`
                              : `Agentes - ${dateToday}`
                          }`}
                        >
                          <ExcelColumn
                            label={language === "english" ? "Agent" : "Agente"}
                            value="agentName"
                          />
                          <ExcelColumn
                            label={language === "english" ? "Average Rating" : "Rating promedio"}
                            value="averageTotalRating"
                          />

                          <ExcelColumn
                            label={language === "english" ? "Interactions" : "Interacciones"}
                            value="totalInteractions"
                          />

                          <ExcelColumn
                            label={
                              language === "english"
                                ? "Average Satisfaction"
                                : "Promedio de satisfacción"
                            }
                            value="satisfactionAverageIndex"
                          />
                        </ExcelSheet>
                      }
                    </ExcelFile>
                  )}

                  <button style={styles.downloadsButton} onClick={handleDownloadCSV}>
                    {language === "english" ? "Download CSV" : "Descargar CSV"}
                  </button>
                </div>
              </>
            </div> */}
            

            {/* Tablas */}
            {!paginatedData ? (
              <button onClick={history} style={styles.playgroundButton}>
                {language === "english"
                  ? "Generate data in Playground"
                  : "Generar datos en Playground"}
              </button>
            ) : (
              <Tables
                paginatedData={paginatedData}
                openModal={handleOpenModal}
                darkModeState={darkModeState}
                language={language}
                screenSize={screenSize}
              />
            )}
            {modalState && (
              <SingleView
                onClose={handleCloseModal}
                darkModeState={darkModeState}
                language={language}
              />
            )}
            {totalPages > 1 && <div style={styles.main}>
              <button
                style={{
                  ...styles.defaultButton,
                  ...(currentPage === 1 ? styles.disabledButton : {}),
                }}
                onClick={handlePreviousClick}
                disabled={currentPage === 1}
              >
                {language === "english" ? "PREVIOUS" : "ANTERIOR"}
              </button>
              <div
                style={{
                  display: "flex",
                  height: "37px",
                }}
              >
                {totalPagesArr?.map((page, index) => (
                  <p
                    key={index}
                    style={{
                      height: "fit-content",
                      color:
                        page.isCurrent && darkModeState
                          ? "white"
                          : page.isCurrent && !darkModeState
                          ? "black"
                          : "grey",
                      marginRight: "10px",
                    }}
                  >
                    {page.number}
                  </p>
                ))}

                
              </div>
              <button
                style={{
                  ...styles.defaultButton,
                  ...(currentPage === totalPages || isNaN(totalPages) ? styles.disabledButton : {}),
                }}
                onClick={handleNextClick}
                disabled={currentPage === totalPages || isNaN(totalPages)}
              >
                {language === "english" ? "NEXT" : "SIGUIENTE"}
              </button>
            </div>}
          </>
        ) : (
          <>
            <h1
              style={{
                textAlign: "center",
                marginTop: "10%",
                color: darkModeState ? "#f2f2f2" : "#000000",
              }}
            >
              {language === "english" ? "There is no data available..." : "Datos no disponibles..."}
            </h1>
          </>
        )}
      </div>
    </div>
  );
};
