import React from "react";

export function ResetIcon({ styles }) {
  return (
    <svg
      style={styles.iconActions}
      fill="currentColor"
      className="bi bi-arrow-counterclockwise"
      viewBox="0 0 16 16"
    >
      <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z" />
      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
    </svg>
  );
}
