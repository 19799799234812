import React, { useState, useEffect } from "react";
//*redux
import {
  setQuestionsConversation,
  setLoadingQuestionsConversation,
  resetQuestionsTraining,
  pushToQuestionsConversation,
  setLoadingDelete
} from "../store/reducers/slice/questionsTraining";
import { useSelector, useDispatch } from "react-redux";
//*container
import Sidebar from "./Sidebar";
//*components
import QuestionsPlayground from "../components/QuestionsPlayground";
//*images
import { imgLogoGalicia } from "../assets/imgs_logo/imgs";
//*toastify
import { toast } from "react-toastify";
//*axios
import axios from "axios";

const QuestionsPlaygroundClient = () => {
  const [messageToSend, setMessageToSend] = useState("");
  const screenSize = useSelector((state) => state.screenSizeReducer.size);

  const playgroundQuestionsState = useSelector(
    (state) => state.questionsTraining
  );

  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);

  const { name: companyName } = useSelector((state) => state.user.companyInfo);

  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.user.loginUser);
  const [popUp, setPopUp] = useState(false);

  const handleChangeText = (e) => {
    const value = e.target.value;
    setMessageToSend(value);
  };

  //SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (messageToSend.length <= 5)
      return toast.warn(
        language === "english"
          ? "Your message must be at least 5 characters long"
          : "Tu mensaje debe tener al menos 5 caracteres"
      );
    const message = messageToSend;

    dispatch(pushToQuestionsConversation(message));

    setMessageToSend("");

    dispatch(setLoadingQuestionsConversation(true));
    const infoUser = {
      name: loginUser.firstName + " " + loginUser.lastName,
      userId: loginUser.userUID,
    };
    // const url = 'http://localhost:5000/insideone-onetraining/us-central1/questionsAssistant'
    const url = "https://questionsassistant-cfcs6xjhea-uc.a.run.app/";
    const { data } = await axios.post(
      `${url}?threadID=${playgroundQuestionsState.threadID}&instanceName=${companyName}`,
      { messageUser: message, infoUser }
    );
    dispatch(setQuestionsConversation(data));
  };

  const handleReset = () => dispatch(resetQuestionsTraining());

  const handlePopUpFeatures = () => setPopUp(!popUp);

  const handleSelectHistory = (selectedHistory) =>
    dispatch(setQuestionsConversation(selectedHistory));

  const handleDeleteHistory = async (selectedHistory) => {
    const enpoint = "https://deletechatfromhistory-cfcs6xjhea-uc.a.run.app/";
    dispatch(setLoadingDelete(true))
    try {
      await axios.delete(
        `${enpoint}?docId=${selectedHistory.id}&instanceName=${companyName}`
      );
      handleReset()
      dispatch(setLoadingDelete(false))
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const chatQuestionsAssistant = document.getElementById(
      "chatQuestionsAssistant"
    );
    chatQuestionsAssistant.scrollTop =
      chatQuestionsAssistant.scrollHeight - chatQuestionsAssistant.clientHeight;
  }, [playgroundQuestionsState.conversation]);

  return (
    <div>
      <Sidebar />
      <QuestionsPlayground
        imgLogoGalicia={imgLogoGalicia}
        darkModeState={darkTheme}
        language={language}
        screenSize={screenSize}
        handleReset={handleReset}
        status={"ready"}
        loginUser={loginUser}
        handlePopUpFeatures={handlePopUpFeatures}
        popUpFeatures={popUp}
        companyName={companyName}
        // Chatbot
        handleDeleteHistory={handleDeleteHistory}
        handleSubmit={handleSubmit}
        handleChangeText={handleChangeText}
        playgroundQuestionsState={playgroundQuestionsState}
        messageToSend={messageToSend}
        handleSelectHistory={handleSelectHistory}
      />
    </div>
  );
};

export default QuestionsPlaygroundClient;
