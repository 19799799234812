export const groupByAgents = (data) => {
  if (!data || !data.length) return [];

  let agents = [];

  data.forEach((agentData) => {
    const agentName = agentData.infoUser.name;

    // Check if the agent is already in the array
    const existingAgent = agents.find((agent) => agentName === agent.name);

    if (!existingAgent) {
      // Use filter to get all evaluations for the current agent
      const filteredAgent = data.filter((agent) => agent.infoUser.name === agentName);

      // Use reduce to calculate total evaluations, average calification, average technicality,
      // last evaluation, and average response time
      const agentStats = filteredAgent.reduce(
        (accumulator, agent) => {
          const calification = parseInt(agent.analyze.calification);
          const technicality = parseInt(agent.analyze.technicality);
          const responseTime = parseFloat(agent.responseTime);

          if (!isNaN(calification) && !isNaN(technicality)) {
            accumulator.totalEvaluations++;
            accumulator.averageCalification += calification;
            accumulator.averageTechnicality += technicality;
          }

          if (!isNaN(responseTime)) {
            if (!accumulator.lastEvaluation || agent.date > accumulator.lastEvaluation.date) {
              accumulator.lastEvaluation = agent;
            }
            accumulator.totalResponseTime += responseTime;
          }

          return accumulator;
        },
        {
          totalEvaluations: 0,
          averageCalification: 0,
          averageTechnicality: 0,
          lastEvaluation: null,
          totalResponseTime: 0,
        }
      );

      // Calculate averages
      agentStats.averageCalification /= agentStats.totalEvaluations;
      agentStats.averageTechnicality /= agentStats.totalEvaluations;

      // Calculate average response time
      agentStats.averageResponseTime =
        agentStats.totalResponseTime / agentStats.totalEvaluations || null;

      const objAgent = {
        name: agentName,
        totalEvaluations: agentStats.totalEvaluations,
        averageCalification: agentStats.averageCalification,
        averageTechnicality: agentStats.averageTechnicality,
        lastEvaluation: agentStats.lastEvaluation,
        averageResponseTime: agentStats.averageResponseTime,
      };

      agents.push(objAgent);
    }



  });


  return agents;

};
