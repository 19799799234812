import React, { useState } from "react";
//*Router
import { useLocation } from "react-router-dom";
//*styles
import "./singleView.scss";
//*img
import iconFeliz from "../../assets/icons/bichiii-feli.png";
import iconSorprendido from "../../assets/icons/bichito-sorprendido.png";
//*redux
import { useSelector } from "react-redux";
//*Icons
import { AgentIcon } from "../icons/AgentIcon";
import { CalificationIcon } from "../icons/CalificationIcon";
import ConversationIcon from "../icons/ConversationIcon";
import DateIcon from "../icons/DateIcon";
import ResponseTimeIcon from "../icons/ResponseTimeIcon";
import { TechnicalityIcon } from "../icons/TechnicalityIcon";
import RecomendationIcon from "../icons/RecomendationIcon";
import CrossIcon from "../icons/CrossIcon";
//*utils
import { formatTime } from "../../utils/formatTime";
import { TotalInteractionsIcon } from "../icons/TotalInteractionsIcon";
import ViewConversation from "./ViewConversation";
const SingleView = ({ onClose, darkModeState, language }) => {
  const [isViewConversation, setIsViewConversation] = useState(false);

  const { selectedReport, modalState } = useSelector(
    (state) => state.singleViewReducer
  );

  const location = useLocation().pathname.slice(1);
  const isEvaluationView = location === "evaluations";

  if (!modalState) return null;

  const styles = {
    icons: {
      marginRight: "7px",
      maxWidth: "26px",
      maxHeight: "30px",
      minWidth: "26px",
      minHeight: "30px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  const isEnglish = language === "english";

  const {
    infoUser: { name: agentName } = {},
    analyze: { calification, technicality, recommendation } = {},
    responseTime = "",
    dateFormated = "",
    name = "",
    averageCalification = "",
    averageTechnicality = "",
    lastEvaluation: { dateFormated: date } = {},
    totalEvaluations = "",
  } = selectedReport;

  const conversation = selectedReport?.allConversation?.slice(1, 3);

  const auxTitles = {
    agents: {
      english: {
        agent: "Agent",
        calification: "Average score",
        technicality: "Average technicality",
        date: "Last evaluation",
        totalEvaluations: "Total evaluations",
      },

      spanish: {
        agent: "Agente",
        calification: "Calificación promedio",
        technicality: "Tecnicidad promedio",
        date: "Última evaluación",
        totalEvaluations: "Evaluaciones totales",
      },
    },
    evaluations: {
      english: {
        agent: "Agent",
        calification: "Score",
        technicality: "Technicality",
        date: "Date",
        recommendation: "Recommendation",
        responseTime: "Response time",
        conversation: "Conversation",
      },

      spanish: {
        agent: "Agente",
        calification: "Calificación",
        technicality: "Tecnicidad",
        date: "Fecha",
        recommendation: "Recomendación",
        responseTime: "Tiempo de respuesta",
        conversation: "Conversación",
      },
    },
  };

  let infoData = [
    {
      title: auxTitles[location][language].agent,
      value: isEvaluationView ? agentName : name,
      icon: <AgentIcon styles={styles.icons} />,
    },
    {
      title: auxTitles[location][language].calification,
      value: isEvaluationView
        ? !isNaN(parseInt(calification))
          ? parseInt(calification) + "%"
          : "--"
        : !isNaN(parseInt(averageCalification))
        ? parseInt(averageCalification) + "%"
        : "--",
      icon: <CalificationIcon styles={styles.icons} />,
    },
    {
      title: auxTitles[location][language].technicality,
      value: isEvaluationView
        ? !isNaN(parseInt(technicality))
          ? parseInt(technicality) + "%"
          : "--"
        : !isNaN(parseInt(averageTechnicality))
        ? parseInt(averageTechnicality) + "%"
        : "--",
      icon: <TechnicalityIcon styles={styles.icons} />,
    },
    {
      title: auxTitles[location][language].date,
      value: isEvaluationView ? dateFormated.split(" ")[0] : date.split(" ")[0],
      icon: <DateIcon styles={styles.icons} />,
    },
  ];

  if (isEvaluationView) {
    infoData = [
      ...infoData,
      {
        title: auxTitles[location][language].responseTime,
        value: formatTime(responseTime),
        icon: <ResponseTimeIcon styles={styles.icons} />,
      },
      {
        title: auxTitles[location][language].recommendation,
        value: recommendation,
        icon: <RecomendationIcon styles={styles.icons} />,
      },
      {
        title: auxTitles[location][language].conversation,
        value: (
          <ListConversation
            conversation={conversation}
            darkModeState={darkModeState}
            language={language}
          />
        ),
        icon: <ConversationIcon styles={styles.icons} />,
      },
    ];
  } else {
    infoData = [
      ...infoData,
      {
        title: auxTitles[location][language].totalEvaluations,
        value: totalEvaluations,
        icon: <TotalInteractionsIcon styles={styles.icons} />,
      },
    ];
  }

  const titleView = {
    evaluations: isEnglish ? "Evaluation" : "Evaluación",
    agents: isEnglish ? "Agents" : "Agentes",
  };

  const handleDisplayConversation = () => {
    setIsViewConversation(!isViewConversation);
  };

  return (
    modalState && (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div
          className={`${
            darkModeState
              ? "singleView__container__dark"
              : "singleView__container__light"
          }`}
        >
          <button className="close-button" onClick={onClose}>
            <CrossIcon styles={styles.icons} />
          </button>

          {isViewConversation ? (
            <ViewConversation
              selectedReport={selectedReport}
              handleDisplayConversation={handleDisplayConversation}
              language={language}
              darkModeState={darkModeState}
            />
          ) : (
            <>
              <img
                className="singleView__image_eric"
                src={calification > 69 ? iconFeliz : iconSorprendido}
                alt="eric sorprendido"
              />

              <h3
                className={`singleView__title__${
                  darkModeState ? "dark" : "light"
                }`}
              >
                {titleView[location]}
              </h3>

              <div className="singleView__cont_info">
                {infoData.map((data, i) => {
                  const isRecommendation =
                    data.title === "Recommendation" ||
                    data.title === "Recomendación"
                      ? true
                      : false;

                  const isConversation =
                    data.title === "Conversation" ||
                    data.title === "Conversación"
                      ? true
                      : false;

                  return (
                    <div
                      key={i}
                      className={`singleView__info ${
                        isRecommendation
                          ? "singleView__recomendation"
                          : isConversation
                          ? "singleView__conversation"
                          : ""
                      }`}
                    >
                      <div className="singleView__cont_title">
                        {data.icon}
                        <span
                          className={`singleView__title__${
                            darkModeState ? "dark" : "light"
                          }`}
                        >
                          {data.title}
                        </span>
                      </div>
                      {isConversation ? (
                        data.value
                      ) : (
                        <span
                          className={`singleView__subtitle__${
                            darkModeState ? "dark" : "light"
                          }`}
                        >
                          {data.value}
                        </span>
                      )}
                    </div>
                  );
                })}

                {location === "agents" && (
                  <button
                    className={`singleView__seeChartBtn__${
                      darkModeState ? "dark" : "light"
                    }`}
                    onClick={handleDisplayConversation}
                  >
                    {isEnglish ? "Chart" : "Gráfico"}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};
const ListConversation = ({ conversation, darkModeState, language }) => (
  <ul>
    {conversation?.reverse().map(({ message, role }, i) => {
      const selectRole = {
        assistant: {
          spanish: "Cliente",
          english: "Client",
        },
        user: {
          spanish: "Agente",
          english: "Agent",
        },
      };
      return (
        <li key={i} style={{ fontWeight: "100" }}>
          <span
            style={{ fontWeight: "100" }}
            className={`singleView__subtitle__${
              darkModeState ? "dark" : "light"
            }  singleView__recomendation_span`}
          >
            {selectRole[role][language]}: {message}
          </span>
        </li>
      );
    })}
  </ul>
);

export default SingleView;
