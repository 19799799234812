import React, { useState, useRef , useEffect} from "react";
import Login from "../components/Login";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../store/actions/loginAction";
import AuthService from "../services/auth.service";
//import { useTranslation } from 'react-i18next';

const LoginClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userLogin = useSelector((state) => state.user.userLogin);
  const isAuth = useSelector((state) => state.user.isAuth);
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [recoverMail, setRecoverMail] = useState("");
  const [recoverMessage, setRecoverMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [popUp, setPopUp] = useState(false);
  const screenSize = useSelector(state=>state.screenSizeReducer.size)


  const handlePopUp = (e) => {
    setPopUp(!popUp);
    setRecoverMail("");
    setRecoverMessage(false);
  };

  const handleRecoverMail = (e) => {
    const value = e.target.value;
    setRecoverMail(value);
  };

  const handleRecover = (e) => {
    if (recoverMail != "") {
      AuthService.recover(recoverMail).then(
        (res) => {
          if (res) {
            setRecoverMail("");
            setRecoverMessage("yes");
          }
        },
        (error) => {
          setRecoverMessage("no");
        }
      );
    }
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");

    if (username != "" && password != "") {
      setLoading(true);
      AuthService.login(username, password).then(
        (res) => {
          if (res) {
        
            setMessage("");
            dispatch(loginUser(res.user.uid, history));
          }
        },
        (error) => {
          setLoading(false);
          setMessage("The email or password is incorrect.");
        }
      );
    }
  };


  return (
    <Login
      screenSize={screenSize}
      username={username}
      password={password}
      message={message}
      loading={loading}
      onChangeUsername={onChangeUsername}
      onChangePassword={onChangePassword}
      handleLogin={handleLogin}
      darkModeState={darkTheme}
      language={language}
      popUp={popUp}
      handlePopUp={handlePopUp}
      handleRecover={handleRecover}
      handleRecoverMail={handleRecoverMail}
      recoverMessage={recoverMessage}
    />
  );
};

export default LoginClient;
