import React from "react";
import PopUpRecover from "./PopUpRecover";

export default ({ screenSize, handleLogin, username, password, message, loading, onChangeUsername, onChangePassword, darkModeState, language, popUp, handlePopUp, handleRecover, handleRecoverMail, recoverMessage }) => {
    const styles = {
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            margin: 0,
            padding: 0,
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
        },
        columnImage: {
            display: "flex",
            flex: 7,
            height: "100vh",
            width: "50vw",
            margin: 0,
            padding: 0,
            //backgroundImage: `url(${"https://i.postimg.cc/L8GMkJ7M/background-01.jpg"})`,
            //   backgroundImage: `url(${"https://i.postimg.cc/wMqKScXD/background-isles.jpg"})`,
            backgroundImage: `url(${"https://i.postimg.cc/tCMGTZK4/background-image.png"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        imageLogo: {
            height: "10%",
            width: "auto",
            position: "relative",
            left: "10%",
            top: "80%",
        },
        columnInputs: {
            display: "flex",
            flex: 5,
            justifyContent: 'center',
            alignItems: 'center',
            width: `${screenSize > 800 ? ("50vw") : ("100vw")}`,
            margin: 0,
            padding: 0,
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
        },
        form: {
            width: "100%",
            padding: 80
        },
        formTitle: {
            fontSize: `${screenSize > 800 ? ("40px") : ("30px")}`,
            marginBottom: 30,
            //   fontFamily: 'InterRegular'
            fontFamily: 'InterBold',
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("#000")}`,
        },
        formLabel: {
            marginBottom: 10,
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
        },
        formInput: {
            marginBottom: 20,
            fontFamily: "InterRegular",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(0, 0%, 100%)")}`,
            border: `${darkModeState ? ('none') : (<></>)}`,
        },
        formButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '8px 32px',
            margin: "30px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: 130,
            height: 40
        },
        formLoading: {
            width: 20,
            height: 20
        },
        textError: {
            margin: 0,
            fontSize: 16,
            fontFamily: "InterBold",
            color: 'hsl(356, 69%, 56%)',
            alignSelf: 'center'
        },
        textRecover: {
            margin: '10px 0px',
            fontSize: 16,
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            alignSelf: 'center',
            backgroundColor: `${darkModeState ? ("hsl(228, 28%, 20%)") : ("hsl(0, 0%, 100%)")}`,
            border: 'none',
            padding: 0,
            outline: 0,
        },
        buttonFloat: {
            position: "absolute",
            bottom: 15,
            right: -100,
            display: "flex",
            justifyContent: "flex-end",
            height: screenSize <= 1000 ? '200px' : '280px'
        },
    }

    return (
        <div style={styles.container}>
            {screenSize > 800 ? (
                <div style={styles.columnImage} >
                    <img
                        src="https://i.postimg.cc/ydB9XXzt/insideone-white.png"
                        alt="Logo of Inside One"
                        style={styles.imageLogo}
                    />
                </div>
            ) : (<></>)}
            <div style={styles.columnInputs} >
                {popUp === true ? (
                    <PopUpRecover
                        screenSize={screenSize}
                        darkModeState={darkModeState}
                        language={language}
                        handlePopUp={handlePopUp}
                        handleRecover={handleRecover}
                        handleRecoverMail={handleRecoverMail}
                        recoverMessage={recoverMessage}
                    />
                ) : (<></>)}
                <form onSubmit={handleLogin} style={styles.form}>
                    <div className="form-group">
                        <h1 style={styles.formTitle}>OneTraining</h1>
                        <label style={styles.formLabel} htmlFor="username">{language === 'english' ? ('Email') : ('Correo electrónico')}</label>
                        <input
                            name="username"
                            type="email"
                            className="form-control"
                            id="username"
                            value={username}
                            onChange={onChangeUsername}
                            style={styles.formInput}
                        //  validations={[required]}
                        />
                        {/* <small id="emailHelp" className="form-text text-muted"> No compartiremos su información. </small> */}
                        <label style={styles.formLabel} htmlFor="inputPassword">{language === 'english' ? ('Password') : ('Contraseña')}</label>
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            id="inputPassword"
                            value={password}
                            onChange={onChangePassword}
                            style={styles.formInput}
                        //   validations={[required]}
                        />
                        {message ? <h3 style={styles.textError}>{language === 'english' ? ('The email or password is incorrect.') : ('El email o la contraseña son incorrectos.')}</h3> : <></>}
                        {message ? <button style={styles.textRecover} onClick={handlePopUp}>{language === 'english' ? ('Recover password') : ('Recuperar contraseña')}</button> : <></>}
                        {loading ? (
                            <button type="submit" style={styles.formButton} disabled>
                                <div className="spinner-border text-light spinner-border-sm" role="status" />
                            </button>
                        ) : (
                            <button type="submit" style={styles.formButton}>{language === 'english' ? ('Sign in') : ('Ingresar')}</button>
                        )}
                    </div>
                </form>
            </div>
            {screenSize > 800 && !darkModeState && !popUp ? (
                <img
                    style={styles.buttonFloat }
                    src="https://i.postimg.cc/SRjc48Cv/Eric-Apuntando-Alpha-baja.gif"
                    alt="Bot of Inside One"
                />
            ) : (<></>)}
        </div>
    )
}
