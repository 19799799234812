import firebase from '../../config/firebaseIO'



const db = firebase.firestore()
// d4c9s7pwy2UwYPg2OMfr
export const loginUser = (userUID, historyRoute) => (dispatch) => {
  // const history = useHistory(); //campaigns: user.data().campaigns,
  // oneMessage: user.data().oneMessage ? user.data().oneMessage : "false",
  db.collection("users").doc(userUID)
    .get()
    .then((user) => {
      dispatch({
        type: "SET_LOGIN",
        user: {
          userUID: userUID,
          firstName: user.data().firstName,
          lastName: user.data().lastName,
          rol: user.data().rol,
          firstLogin: user.data().firstLogin,
          company: user.data().company,
          mail: user.data().mail
          
        },
      });
      return user
    })
    .then((user) => {
      let companyUser = user.data().company
      db.collection("companies").doc(companyUser)
        .get()
        .then((company) => {
          dispatch({
            type: "SET_COMPANY_INFO",
            companyInfo: {
              companyId: companyUser,
              surveys: company.data().surveys ? company.data().surveys : false,
              reports: company.data().reports ? company.data().reports : false,
              charts: company.data().charts ? company.data().charts : false,
              licence: company.data().licence ? company.data().licence : false,
              name: company.data().name ? company.data().name : false,
              description: company.data().description ? company.data().description : false,
              assistants_id: company.data().assistants_id ? company.data().assistants_id : null,
              files_openai: company.data().files_openai || null
            },
          });
          if (user.data().oneMessage === "true") {
            historyRoute.push("/onemessage");
          } else {
            if (user.data().firstLogin === true) {
              historyRoute.push("/onboarding");
            } else {
              if (user.data().company == "VF0rMotbgtFU3FapbUo6") {
                //X28
                historyRoute.push("/charts/x28");
                // } else if (user.data().company == "8iJ3nj8J4B3H9vECq67J") {
                //   historyRoute.push("/licenses");
              } else if (user.data().company == "8iJ3nj8J4B3H9vECq67J") {
                historyRoute.push("/charts");
              } else if (user.data().company == "HZZb5MxJI1zVkQJ8MJwk") {
                //GALICIA SEGUROS
                historyRoute.push("/answers/galicia-seguros");
              } else if (user.data().company == "d4c9s7pwy2UwYPg2OMfr") {
                //BBVA
                historyRoute.push("/charts/bbva-seguros/whatsapp");
              } else if (user.data().company == "0XCHlrimdm5mi0Hmg3yU") {
                //AON
                historyRoute.push("/analytics");
              } else {
                // ruteo al loguearse en InsideOne
                historyRoute.push("/metrics");
              }
            }
          }
          return user
        })
      

    })
};

export const loginUserPreferences = (typeOfDispatch, valueOfDispatch) => (dispatch) => {
  if (typeOfDispatch === 'darkTheme') {
    dispatch({
      type: "SET_DARK_THEME",
      darkTheme: valueOfDispatch
    });
  } else if (typeOfDispatch === 'language') {
    dispatch({
      type: "SET_LANGUAGE",
      language: valueOfDispatch
    });
  } else if (typeOfDispatch === 'status') {
    dispatch({
      type: "SET_READY",
      status: valueOfDispatch
    });
  }
};

export const companyInfo = (chats) => (dispatch) =>
  dispatch({
    type: "SET_COMPANY_INFO",
    companyInfo: chats,
  });

export const surveysInfo = (chats) => (dispatch) =>
  dispatch({
    type: "SET_SURVEYS_INFO",
    surveys: chats,
  });

export const logoutUser = () => (dispatch) =>
  dispatch({
    type: "SET_LOGOUT",
    user: {},
  });