import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { theRealTruncateString } from "../utils/truncateSrings";
import { useDispatch, useSelector } from "react-redux";
import "./rowTable.css";
import { formatTime } from "../utils/formatTime";

const RowTable = ({
  openModal,
  paginatedData,
  screenSize,
  darkModeState,
  language,
}) => {
  const location = useLocation();
  const isEvaluationView = location.pathname === "/evaluations";

  const styles = {
    singleReportReference: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      margin: `${screenSize > 800 ? "5px 20px" : "0px"}`,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "20px 15px",
      textDecoration: "none",
      border: "none",
      outline: "none",
      cursor: "pointer",
      minWidth: "427px",
      /*       background: paginatedData?.map((el) => {
        return parseInt(el.client?.rating) < 40
          ? "#da7990" // negative
          : parseInt(el.client?.rating) >= 40 &&
            parseInt(el.client?.rating) < 70
          ? "#f4c871" // medium
          : "#86e49d"; // positive
      }),  */
    },
    tableHead: {
      display: "flex",
      flex: 2,
      color: darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)",
      margin: 0,
      fontSize: screenSize <= 800 ? ".8rem" : "1rem",
      fontFamily: "InterBold",
      textAlign: "left",
      alignSelf: "center",
      justifyContent:
        location.pathname === "/conversations" ? "flex-start" : "center",
      paddingRight: "15px",
    },
    negative: {
      background: "#da799056",
    },
    medium: {
      background: "#f4c871",
    },
    positive: {
      background: "#86e49d",
    },
    darkModeColor: {
      backgroundColor: "hsl(228, 28%, 20%)",
    },
  };

  const backgroundClass = (rating) => {
    if (rating < 40) {
      return "status-negative";
    } else if (darkModeState) {
      return "buttonDark";
    } else {
      return "buttonLight";
    }
  };

  const o = {
    infoUser: { userId: "dOjqlVcjT2Ub090GW96Mw3ZpU0C3", name: "Nicolas Faure" },
    date: "Mon Jan 15 2024 10:00:52 GMT-0300 (hora estándar de Argentina)",
    responseTime: 44.558,
    analyze: {
      calification: "50",
      recommendation:
        "Asegúrese de mencionar todas las coberturas adicionales relevantes al seguro de automóvil, incluida la cobertura integral que sí cubre robos, vandalismo y fenómenos naturales.",
      technicality: "50",
    },
    threadID: "thread_KBUV5K24tEgSYsSVkiLWvlDG",
    dateFormated: "15/01/2024 10:00:52",
    id: "b3N20Ub3B11lmh7JZYJH",
  };

  return (
    <>
      {isEvaluationView && paginatedData
        ? paginatedData?.map((el, i) => {
            const responseTime = formatTime(el.responseTime);
            const technicality = parseInt(el.analyze?.technicality);
            const calification = parseInt(el.analyze?.calification);
            const agentName = el?.infoUser?.name;
            return (
              <div
                key={i}
                // className={darkModeState ? "buttonDark" : "buttonLight"}
                className={`${backgroundClass(el.analyze?.calification)}`}
                onClick={() => openModal(el)}
                style={{
                  ...styles.singleReportReference,
                }}
              >
                <div style={styles.tableHead}>{el?.dateFormated}</div>
                <div style={styles.tableHead}>
                  {isNaN(calification) ? "-" : calification + "%"}
                </div>
                <div style={styles.tableHead}>
                  {isNaN(technicality) ? "-" : technicality + "%"}
                </div>
                <div style={styles.tableHead}>
                  {isNaN(parseInt(responseTime)) ? "-" : responseTime}
                </div>

                <div style={styles.tableHead}>
                  {agentName && screenSize <= 800
                    ? theRealTruncateString(agentName, 7)
                    : agentName}
                </div>
              </div>
            );
          })
        : paginatedData?.map((agent, i) => {
            return (
              <div
                key={i}
                onClick={() => openModal(agent)}
                className={`${backgroundClass(agent.averageTotalRating)}`}
                style={{
                  ...styles.singleReportReference,
                }}
              >
                <div style={styles.tableHead}>
                  {agent?.name && screenSize <= 800
                    ? theRealTruncateString(agent?.name, 8)
                    : agent?.name}
                </div>
                <div style={styles.tableHead}>{agent?.totalEvaluations}</div>
                <div style={styles.tableHead}>
                  {Math.round(agent?.averageCalification) + "%"}
                </div>
                <div style={styles.tableHead}>
                  {Math.round(agent?.averageTechnicality) + "%"}
                </div>
                <div style={styles.tableHead}>
                  {formatTime(agent?.averageResponseTime)}
                </div>
                <div style={styles.tableHead}>
                  {agent?.lastEvaluation?.dateFormated}
                </div>
              </div>
            );
          })}
    </>
  );
};

export default RowTable;
