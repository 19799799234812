import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qualifications: [],
  recommendations: [],
  currentAnalysisInstruction: null,
  dropdownRecommendations: false,
  technicalityLevel: 'formal',
  dropdownQualifications: false,
  originalData: null,
};

export const fineTuning = createSlice({
  name: "fineTuning",

  initialState,
  reducers: {
    setFineTuning: (state, { payload }) => {
      console.log(payload, "payload")
      if(payload){

        state.recommendations = payload.recommendations;
        state.currentAnalysisInstruction = payload.currentAnalysisInstruction;
        state.technicalityLevel = payload.technicalityLevel;
        state.qualifications = payload.qualification
        state.originalData = payload
      }

    },
    pushToRecommendations: (state, { payload }) => {
      state.recommendations.push(payload);
    },
    setDropdownRecommendations: (state, { payload }) => {
      state.dropdownRecommendations = payload;
    },
    removeRecommendation: (state, { payload }) => {
      state.recommendations = state.recommendations.filter((value, index) =>(
        index !== payload.index
        )) 
    },
    removeQualification: (state, { payload }) => {
      state.qualifications = state.qualifications.filter((value, index) =>(
        index !== payload.index
        )) 
    },
    setTechnicalityLevel: (state, {payload})=>{
      state.technicalityLevel = payload
    },
    pushToQualification: (state, {payload})=> {
      state.qualifications.push(payload)
    },
    setDropdownQualification: (state, {payload}) =>{
      state.dropdownQualifications = payload;
    }

  },
});

export const {
  setFineTuning,
  pushToRecommendations,
  setDropdownRecommendations,
  removeRecommendation,
  setTechnicalityLevel,
  pushToQualification,
  setDropdownQualification,
  removeQualification
} = fineTuning.actions;
