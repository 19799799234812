import { createSlice } from "@reduxjs/toolkit";

export const firestoreSlice = createSlice({
  name: "firestore",

  initialState: {
    data: null,
    isFetching: false,
    paginatedData: null,
    currentPage: 1,
    itemsPerPage: 14,
    latestDocId: null,
    firstDocId: null,
    lastDocId: null,
    filter: null,
  },
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
      state.isFetching = false
      localStorage.setItem("data", JSON.stringify(payload));
    },
    setPaginatedData: (state, { payload }) => {
      state.paginatedData = payload;
      localStorage.setItem("paginatedData", JSON.stringify(payload));
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setLatestDocId: (state, { payload }) => {
      state.latestDocId = payload;
    },
    setFirstDocId: (state, { payload }) => {
      state.firstDocId = payload;
    },
    setLastDocId: (state, { payload }) => {
      state.lastDocId = payload;
    },
    setFilter: (state, { payload }) => {
      state.currentPage = 1;
      state.filter = payload;
    },
    setPlaygroundMode: (state, { payload }) => {
      // state.playgroundMode = payload
      localStorage.setItem("playgroundMode", JSON.stringify(payload))
      state.playgroundMode = JSON.parse(localStorage.getItem('playgroundMode'))
    },
    setIsFetching: (state, { payload }) => {
      state.isFetching = payload
    },
  },
});

export const {
  setData,
  setPaginatedData,
  setCurrentPage,
  setLatestDocId,
  setFirstDocId,
  setLastDocId,
  setFilter,
  setPlaygroundMode,
  setIsFetching
} = firestoreSlice.actions;
