import React, { useState } from "react";
import { TrashIcon } from "../commons/icons/TrashIcon";
import "../assets/styles/settings.scss";

export default ({
  screenSize,
  darkModeState,
  handleDarkMode,
  language,
  handleLanguage,
  handleRealTest,
  testRealAgentState,
  isOriginalDataChange,
  isLoadingSave,
  handleSendFineTuning,
  handleDropdownRecommendations,
  handleSubmitRecommendations,
  handleRemoveRecommendation,
  hanldeSelectTechnicalityLevel,
  handleDropdownQualification,
  handleSubmitQualification,
  handleRemoveQualification,
  fineTuningState,
  dataChange,
  setDataChange,
}) => {
  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      height: "100%",
      overflowY: `auto`,
    },
    containerLoader: {
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      height: "80%",
    },
    navbar: {
      display: "flex",
      flex: 1.5,
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitle: {
      fontSize: "22px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    textUser: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      backgroundColor: `transparent`,
    },
    buttonNew: {
      textDecoration: "none",
      color: "#fff",
      backgroundColor: "#4dc2f1",
      border: "none",
      margin: "20px",
      // margin: `${screenSize > 800 ? ('1.4%') : ("10px")}`,
      width: `${screenSize > 800 ? "180px" : <></>}`,
    },
    settings: {
      display: "flex",
      //flex: 11,
      flexDirection: "column",
      //alignItems: "center",
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      gap: 5,
    },
    singleSettingQualification: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      outline: "none",
     /*  margin: `${screenSize > 800 ? "1.4% 0px 10px" : "10px"}`, */
    },
    singleSetting: {
      display: "flex",
      //   width: `${screenSize > 800 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",

      outline: "none",
      /* margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`, */
    },
    lineDetail: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    nameSetting: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      margin: "0px 0px 5px 0px",
      fontSize: "18px",
      fontFamily: "InterBold",
    },
    valueSetting: {
      display: "flex",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: 0,
      fontSize: "14px",
      fontFamily: "InterBold",
      textAlign: "center",
      position: "relative",
    },
    singleSettingRecommendation: {
      display: "flex",
      width: `${screenSize > 800 ? "100%" : "80vw"}`,
      alignItems: "space-between",
      borderRadius: "5px",
      padding: "25px",
      textDecoration: "none",
      border: "none",
      justifyContent: "space-between",
      outline: "none",
      margin: `${screenSize > 800 ? "1.4% 0px -10px" : "10px"}`,
    },
    loader: {
      display: "flex",
      alignSelf: "center",
    },
    sliderTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "15px",
    },
    slider: {
      width: "25%",
      height: "5px",
      borderRadius: "7px",
      cursor: "pointer",
      backgroundColor: "rgb(77, 194, 241)",
      WebkitAppearance: "none", // remueve los estilos por defecto del browser
    },
    inputWords: {
      display: "flex",
      justifyContent: "space-between",
      alignItem: "center",
    },
    badWordsInput: {
      border: 'none',
      borderBottom: '1px solid  hsl(228, 12%, 44%)',
      color: `${darkModeState ? 'white' : 'black'}`,
      fontFamily: 'InterBold',
      height: '40px',
      padding: '5px 10px',
      backgroundColor: 'transparent',
      outline: 'none',
    },
    defaultButton: {
      backgroundColor: "rgb(77, 194, 241)",
      boxShadow: "2px 3px 10px rgba(0, 0, 0, 0.127)",
      fontWeight: "400",
      outline: "none",
      padding: "10px 15px",
      border: "transparent",
      borderRadius: "7px",
      fontSize: ".9rem",
      width: "auto",
      transition: "all ease-in 0.3s",
      color: "white",
    },
    wordContainer: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "space-between",
    },
    boxWords: {
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "#fff"}`,
      borderRadius: 7,
      padding: 10,
      height: "80%",
      // width: '10%',
      overflowY: "auto",
      display: "none",
      marginTop: "8px",
      color: `${darkModeState ? "white" : "hsl(228, 12%, 44%)"}`,
    },
    ul: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      margin: 0,
      gap: 10,
    },
    li: {
      borderRadius: 7,
      listStyle: "none",
      padding: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    crossImg: {
      paddingLeft: 5,
      marginTop: "1px",
      height: "20px",
      cursor: "pointer",
    },
    custom: {
      color: "rgb(77, 194, 241)",
    },
  };
  const translateTechnicality = {
    english: {
      formal: "Formal",
      informal: "Friendly",
    },
    spanish: {
      formal: "Formal",
      informal: "Amigable",
    },
  };

  return (
    <>
      <div style={styles.backColor} />
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>{language === "english" ? "Settings" : "Ajustes"} ⚙️</h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english" ? "All your settings" : "Todas tus configuraciones actuales."}
            </h3>
          </div>
        </div>
        <div style={{ ...styles.navbar, marginTop: 10 }}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.subtitle}>{language === "english" ? "General" : "General"}</h3>
          </div>
        </div>
        {/* <PopUpAnswers
                    screenSize={screenSize}
                    darkModeState={darkModeState}
                    language={language}
                // handlePopUpAnswers={handlePopUpAnswers}
                // popUpInformationAnswers={popUpInformationAnswers}
                // handleAssign={handleAssign}
                /> */}
        <div style={styles.settings}>
          <button
            className={darkModeState ? "buttonDark" : "buttonLight"}
            style={styles.singleSetting}
            onClick={handleLanguage}
          >
            <div style={styles.nameSetting}>{language === "english" ? "Language" : "Idioma"}</div>
            <div style={styles.valueSetting}>{language === "english" ? "English" : "Español"}</div>
          </button>
          <button
            className={darkModeState ? "buttonDark" : "buttonLight"}
            style={styles.singleSetting}
            onClick={handleDarkMode}
          >
            <div style={styles.nameSetting}>{language === "english" ? "Theme" : "Tema"}</div>
            <div style={styles.valueSetting}>
              {darkModeState ? (
                <>{language === "english" ? "Dark theme" : "Oscuro - Noche"}</>
              ) : (
                <>{language === "english" ? "Light theme" : "Claro - Día"}</>
              )}
            </div>
          </button>
          <button
            className={darkModeState ? "buttonDark" : "buttonLight"}
            style={styles.singleSetting}
            onClick={handleRealTest}
          >
            <div style={styles.nameSetting}>
              {language === "english" ? "Start real agent test" : "Prueba de agente real"}
            </div>
            <div style={styles.valueSetting}>
              {testRealAgentState ? (
                <>{language === "english" ? "Disable" : "Deshabilitado"}</>
              ) : (
                <>{language === "english" ? "Enabled" : "Habilitado"}</>
              )}
            </div>
          </button>
        </div>
        <FineTuningComponent
          darkModeState={darkModeState}
          translateTechnicality={translateTechnicality}
          styles={styles}
          fineTuningState={fineTuningState}
          screenSize={screenSize}
          language={language}
          isLoadingSave={isLoadingSave}
          //Handlers
          //*Recomendation
          handleDropdownRecommendations={handleDropdownRecommendations}
          handleSubmitRecommendations={handleSubmitRecommendations}
          handleRemoveRecommendation={handleRemoveRecommendation}
          //*Technicality
          hanldeSelectTechnicalityLevel={hanldeSelectTechnicalityLevel}
          //*Qualification
          handleSubmitQualification={handleSubmitQualification}
          handleDropdownQualification={handleDropdownQualification}
          handleRemoveQualification={handleRemoveQualification}
          //*Send fine tuning
          handleSendFineTuning={handleSendFineTuning}
          //Functions
          isOriginalDataChange={isOriginalDataChange}
          dataChange={dataChange}
          setDataChange={setDataChange}
        />
      </div>
    </>
  );
};

function FineTuningComponent({
  translateTechnicality,
  darkModeState,
  styles,
  language,
  isOriginalDataChange,
  isLoadingSave,
  handleSendFineTuning,
  handleDropdownRecommendations,
  handleSubmitRecommendations,
  handleRemoveRecommendation,
  hanldeSelectTechnicalityLevel,
  handleDropdownQualification,
  handleSubmitQualification,
  handleRemoveQualification,
  fineTuningState,
  dataChange,
  setDataChange,
}) {
  return (
    <>
      {/* <div style={styles.navbar}>
        <div style={styles.textsNavbar}>
          <h3 style={{ ...styles.titleNavbar, marginTop: "30px" }}>
            {language === "english" ? "Fine Tuning" : "Fine Tuning"} 🛠️
          </h3>
          <h3 style={styles.subtitleNavbar}>
            {language === "english"
              ? "Here you can customize your assistant"
              : "Aquí puedes personalizar a tu bot"}
          </h3>
        </div>
      </div> */}
      <div style={{ ...styles.navbar, marginTop: 10 }}>
        <div style={styles.textsNavbar}>
          <h3 style={styles.subtitle}>{language === "english" ? "Evaluator" : "Evaluador"}</h3>
        </div>
      </div>
      {dataChange ? (
        <button
          onClick={handleSendFineTuning}
          style={{
            alignSelf: "start",
            margin: "10px 25px ",
            marginBottom: "20px",
            backgroundColor: "rgb(77, 194, 241)",
            outline: "none",
            cursor: "pointer",
          }}
          className="defaultButton"
        >
          {isLoadingSave ? (
            <div class="spinner-border text-primary" role="status" />
          ) : language === "english" ? (
            "Save"
          ) : (
            "Guardar"
          )}{" "}
        </button>
      ) : (
        <></>
      )}

      <div style={{...styles.settings,gap:0}}>
        <div
          className={darkModeState ? "buttonDark" : "buttonLight"}
          style={{
            ...styles.singleSettingQualification,
            userSelect: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            hanldeSelectTechnicalityLevel();
          }}
        >
          <div>
            <div style={styles.nameSetting}>
              {language === "english" ? "Technicality" : "Tecnicidad"}
            </div>
            <div
              style={{
                ...styles.valueSetting,
                textTransform: "capitalize",
              }}
            >
              {translateTechnicality[language][fineTuningState.technicalityLevel]}
            </div>
          </div>
        </div>

        <div style={{...styles.titleNavbar, marginTop: "10px"}}>
          <h2
            style={{...styles.subtitleNavbar, fontSize: "16px"}}
          >
            {language === "english" ? "Rating" : "Calificación"}
          </h2>
        </div>
        <form
          className={darkModeState ? "buttonDark" : "buttonLight"}
          style={{
            ...styles.singleSettingRecommendation,
            padding: "20px 25px",
            margin: 0,
            marginBottom: "10px",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitQualification(e);
            //isOriginalDataChange();
          }}
        >
          <input
            required
            name="calification"
            className={darkModeState ? "buttonDark textDarkToggle" : "buttonLight textLightToggle"}
            style={{
              ...styles.badWordsInput,
              width: "100%",
              color: darkModeState ? "white" : "black",
            }}
            placeholder={
              language === "english"
                ? "Condition to alter the rating. For example: It will go down if you don't greet."
                : "Condición para alterar la calificación. Por ejemplo: El índice bajará si no saludas."
            }
          />
        </form>

        {fineTuningState.qualifications?.map((qualification, i) => (
          <ItemsFineTuning
            key={i}
            value={qualification}
            darkModeState={darkModeState}
            i={i}
            handleRemove={handleRemoveQualification}
            styles={styles}
            language={language}
            isOriginalDataChange={isOriginalDataChange}
          />
        ))}

        <div style={{...styles.titleNavbar, marginTop: "10px"}}>
          <h2 style={{...styles.subtitleNavbar, fontSize: "16px"}}
          >
            {language === "english" ? "Recommendation" : "Recomendación"}
          </h2>
        </div>
        <form
          className={darkModeState ? "buttonDark" : "buttonLight"}
          style={{
            ...styles.singleSettingRecommendation,
            padding: "20px 25px",
            margin: 0,
            marginBottom: "10px",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitRecommendations(e);
            //isOriginalDataChange();
          }}
        >
          <input
            required
            name="calification"
            className={darkModeState ? "buttonDark textDarkToggle" : "buttonLight textLightToggle"}
            style={{
              ...styles.badWordsInput,
              width: "100%",
              color: darkModeState ? "white" : "black",
            }}
            placeholder={
              language === "english"
                ? "Write here a new recommendation. For example: It should say good morning."
                : "Escriba aquí una nueva recomendación. Por ejemplo: Debe decir buenos días."
            }
          />
        </form>

        {fineTuningState.recommendations?.map((rec, i) => (
          <ItemsFineTuning
            key={i}
            value={rec}
            darkModeState={darkModeState}
            i={i}
            handleRemove={handleRemoveRecommendation}
            styles={styles}
            language={language}
            isOriginalDataChange={isOriginalDataChange}
          />
        ))}
      </div>
 
    </>
  );
  function ItemsFineTuning({ darkModeState, styles, value, i, handleRemove, language , isOriginalDataChange }) {
    const [sureToDeleteChatModal, setSureToDeleteChatModal] = useState(false);
    const styleIcons = {
      marginRight: 7,
      maxWidth: 26,
      maxHeight: 30,
      minWidth: 26,
      minHeight: 30,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
    };
    return (
      <div
        className={darkModeState ? "buttonDark" : "buttonLight"}
        style={{
          ...styles.singleSettingRecommendation,
          minHeight: 90,
          margin: "0px 0px 10px 0px",
          position: "relative",
          alignItems: "center",
          padding: "15px 25px",
        }}
      >
        {!sureToDeleteChatModal && (
          <>
            <span
              style={{
                color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
              }}
            >
              {value}
            </span>
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setSureToDeleteChatModal(true)}
            >
              <TrashIcon styles={styleIcons} />
            </span>
          </>
        )}

        {sureToDeleteChatModal && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 2,
              backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
              top: 0,
              left: 0,
              borderRadius: 5,
            }}
          >
            <span
              style={{
                color: darkModeState ? "rgb(255, 255, 255)" : "rgb(99, 104, 126)",
              }}
            >
              {language === "english"
                ? "Are you sure you want to delete this recommendation?"
                : "¿Seguro de que quieres eliminar esta recomendacion?"}
            </span>
            <div style={{ display: "flex", gap: 10 }}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove({
                    value: value,
                    index: i,
                  });
                  setSureToDeleteChatModal(false);
                  //isOriginalDataChange()
                }}
                className="defaultButton"
                style={{ padding: "5px 20px", backgroundColor: "red" }}
              >
                Sí
              </button>

              <button
                className="defaultButton"
                style={{
                  padding: "5px 20px",
                  backgroundColor: "transparent",
                  color: darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)",
                  border: darkModeState
                    ? "1px solid hsl(228, 34%, 66%)"
                    : "1px solid hsl(228, 12%, 44%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSureToDeleteChatModal(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
