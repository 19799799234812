import React, { useState } from "react";
import crossIcon from "../assets/icons/cross.svg";
import "../assets/styles/settings.scss";
import { TrashIcon } from "../commons/icons/TrashIcon";
import { EditIcon } from "../commons/icons/Editicon";
//Utils
import { dateFormater } from "../utils/dateFormater";

import { useHistory } from "react-router-dom";

export default function Knowledge({
  darkModeState,
  language,
  screenSize,
  handleChooseFile,
  handleUpload,
  infoAssistant,
  loading,
  selectedFile,
  handleUpdateDoc,
  modalUpdateFile,
  setModalUpdateFile,
  handleSaveDoc,
  handleDeleteDoc,
  generateManualKnowleadge,
  handleDeleteManualKnowledge,
  handleEditKnowledge,
  setIsEnabled,
  isEnabled,
  isLoading,
  setIsEnabledFile,
  isEnabledFile,
  handleCheckboxChange,
  checkboxChecked
}) {
  const styles = {
    badWordsInput: {
      /* border: "1px solid hsl(228, 12%, 44%)", */
      border: "none",
      borderBottom: "1px solid  hsl(228, 12%, 44%)",
      color: `${darkModeState ? "white" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
      height: "40px",
      /* borderRadius: "5px", */
      padding: "5px 10px",
      /* backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "#fff"}`, */
      backgroundColor: "transparent",
      outline: "none",
    },
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //   padding: `${screenSize > 800 ? ("3% 2% 3% 20%") : ("30px")}`,
      padding: `${screenSize > 800 ? "3% 3.5% 3% 280px" : "30px"}`,
      height: "100%",
      overflow: `auto`,
    },
    navbar: {
      display: "flex",
      /* flex: 1.5, */
      margin: `${screenSize > 800 ? "0px 20px" : "0px"}`,
      /*  marginBottom: `${screenSize > 800 ? "0px" : "30px"}`, */
    },
    textsNavbar: {
      display: "flex",
      /* flex: 8, */
      flexDirection: "column",
    },
    titleNavbar: {
      textAlign: "left",
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    listFiles: {
      width: "100%",
      padding: 5,
      borderRadius: "3px",
      display: "flex",
      flexDirection: "column",
    },
    listHours: {
      display: "flex",
      // width: "100%",
      height: "25px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      alignItems: "baseline",
    },
    knowledge2: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      marginTop: "40px",
      minHeight: 75,
      padding: 3,
      paddingLeft: 10,
      borderRadius: 5,
      margin: 0,
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      position: "relative",
    },

    knowledge: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      marginTop: "40px",
      minHeight: 130,
      padding: 3,
      paddingLeft: 10,
      borderRadius: 5,
      margin: 0,
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      position: "relative",
    },
    boxListFilesContainer: {
      borderRadius: "0 0 5px 5px",
      zIndex: 20,
      top: 0,
      width: "60%",
      position: "absolute",
      right: 0,
    },
    listFilesContainer: {
      paddingLeft: 0,
      marginBottom: 0,
    },
    buttonUpload: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      padding: "10px",
      border: "0px",
      cursor: "pointer",
      height: "2.8rem",
    },
    addStyleUpload: {
      width: "fit-content",
      position: "relative",
      cursor: "pointer",
    },
    inputChoose: {
      position: "absolute",
      left: "0",
      top: 0,
      zIndex: 3,
      opacity: "0",
      // cursor:"pointer",
      width: "100%",
      height: "100%",
    },
    boxFileAndUpload: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "start",
      marginTop: "40px",
      backgroundColor: `${
        darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
      }`,
      flexDirection: "column",
      height: 100,
      padding: 25,
      borderRadius: 5,
    },
    fileSelected: {},
    boxUploadSpinner: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    boxSpinner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    modalOverlay: {
      position: "fixed",
      zIndex: "2000",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      overflowY: "auto",
    },
    modalContainer: {
      width: "50%",
      height: "90%",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      borderRadius: 5,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "50px",
    },
    textarea: {
      height: "100px",
      width: "100%",
      borderRadius: "3px",
      color: "Black",
      outline: "none",
    },
    modalButtonCloseModal: {
      position: "absolute",
      right: 5,
      top: 5,
      borderRadius: "100%",
      backgroundColor: "white",
      cursor: "pointer",
    },
    modalTextarea: () => ({
      ...styles.subtitleNavbar,
      fontSize: "1rem",
      padding: 5,
      backgroundColor: "transparent",
      outline: "none",
      borderRadius: 5,
      width: "90%",
    }),
    modalSaveButton: () => ({
      ...styles.buttonUpload,
      position: "absolute",
      right: 5,
      bottom: 5,
      marginBottom: "3px",
      marginRight: "40px",
    }),
  };

  const inputsInitalValue = {
    title: "",
    subtitle: "",
    textarea: "",
  };

  const history = useHistory();
  const [toggleDocuments, setToggleDocuments] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [showInputField, setShowInputField] = useState(false);
  const [inputsKnowleadgeValues, setInputsKnowleadgeValues] =
    useState(inputsInitalValue);
  const [isLoadingAddManual, setIsLoadingAddManual] = useState(false);
  const [isLoadingFinally, setIsLoadingFinally] = useState(false);
  

  const handleChangeInputs = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputsKnowleadgeValues({ ...inputsKnowleadgeValues, [name]: value });
  };

  const handleSubmitNewManualKnowleadge = async (e) => {
    e.preventDefault();
    setIsLoadingAddManual(true);
    setIsLoadingFinally(true);
    await generateManualKnowleadge(
      inputsKnowleadgeValues,
      setIsLoadingAddManual,
      setShowInputField,
      setInputsKnowleadgeValues
    );
    setIsLoadingFinally(false);
    //setIsLoadingAddManual(false);
    /* setShowInputField(false);
    setInputsKnowleadgeValues({
      title: "",
      subtitle: "",
      textarea: "",
    }); */
  };

  const titleNavbar = {
    english: {
      title: "Knowledge 📁",
      subtitle: "Manage the documentation and questions.",
    },
    spanish: {
      title: "Conocimiento 📁",
      subtitle: "Gestione la documentacion y las preguntas.",
    },
  };

  const subTitleMap = {
    english: {
      documents: "Documents",
      manual: "Manual",
    },
    spanish: {
      documents: "Documentos",
      manual: "Manual",
    },
  };

  //   position: fixed;
  //   inset: 0px;
  //   display: flex;
  //   flex-direction: column;
  //   padding: 3% 2% 3% 230px;
  //   height: 100%;
  //   overflow: auto;
  // }

  return (
    <>
      <div style={styles.backColor}>
        {modalUpdateFile.toggleModal && (
          <div style={styles.modalOverlay}>
            <div style={styles.modalContainer}>
              <span
                style={styles.modalButtonCloseModal}
                onClick={() =>
                  setModalUpdateFile({
                    isLoading: false,
                    toggleModal: false,
                    text: "",
                  })
                }
              >
                <img
                  src={crossIcon}
                  onClick={() => {
                    setIsEnabledFile(false);
                  }}
                />
              </span>

              {modalUpdateFile.isLoading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <textarea
                    className="badWordsDropdown"
                    onChange={(e) =>
                      setModalUpdateFile({
                        ...modalUpdateFile,
                        text: e.target.value,
                      })
                    }
                    value={modalUpdateFile.text}
                    style={styles.modalTextarea()}
                    cols="60"
                    rows="30"
                  ></textarea>

                  <button
                    onClick={() => {
                      handleSaveDoc();
                      setIsEnabledFile(false);
                    }}
                    // onClick={handleSaveDoc}
                    style={styles.modalSaveButton()}
                    type="button"
                  >
                    {" "}
                    {language === "english" ? "Save" : "Guardar"}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        <div style={styles.container}>
          <div style={{ ...styles.textsNavbar, position: "relative" }}>
            <h3 style={styles.titleNavbar}>{titleNavbar[language].title}</h3>
            <h3 style={styles.subtitleNavbar}>
              {titleNavbar[language].subtitle}
            </h3>
            <button
              style={{ ...styles.buttonUpload, position: "absolute", right: 0 }}
              onClick={() => {
                history.push("/questions-assistant");
              }}
            >
              {language === "english" ? "Questions" : "Preguntas"}
            </button>
          </div>

          {/* UPLOAD FILE */}
          <div style={{ display: "flex", gap: 10, position: "relative" }}>
            <div
              style={{
                ...styles.buttonUpload,
                ...styles.addStyleUpload,
                marginTop: 30,
              }}
            >
              {language === "english" ? "Choose file" : "Seleccionar archivo"}

              <input
                onChange={handleChooseFile}
                style={{ ...styles.inputChoose, cursor: "pointer" }}
                type="file"
                name=""
                id=""
              />
            </div>
            <button
              style={{
                ...styles.buttonUpload,
                marginTop: 30,
              }}
              onClick={() => setShowInputField(!showInputField)}
            >
              {language === "english" ? "Add Knowledge" : "Añadir Conocimiento"}
            </button>
          </div>
          {showInputField && (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 25,
                marginBottom: "30px",
              }}
              onSubmit={handleSubmitNewManualKnowleadge}
            >
              {!isLoadingAddManual ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      backgroundColor: darkModeState
                        ? "hsl(228, 28%, 20%)"
                        : "hsl(227, 47%, 96%)",
                      padding: "15px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        style={{
                          marginBottom: 0,
                          paddingBottom: "5px",
                          color: darkModeState
                            ? "rgb(255, 255, 255)"
                            : "rgb(30, 32, 42)",
                        }}
                        htmlFor="title"
                      >
                        {language === "english" ? "Title:" : "Titulo:"}
                      </label>
                      <input
                        value={inputsKnowleadgeValues.title}
                        onChange={handleChangeInputs}
                        type="text"
                        name="title"
                        id="title"
                        style={{
                          border: "none",
                          borderBottom: "1px solid hsl(228, 12%, 44%)",
                          color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                          fontFamily: "InterBold",
                          height: "40px",
                          backgroundColor: "transparent",
                          outline: "none",
                          border: "none",
                        }}
                        placeholder={
                          language === "english"
                            ? "Add a title here"
                            : "Agregue un título aquí"
                        }
                        required
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        style={{
                          marginBottom: 0,
                          paddingBottom: "5px",
                          color: darkModeState
                            ? "rgb(255, 255, 255)"
                            : "rgb0, 32, 42)",
                        }}
                        htmlFor="subtitle"
                      >
                        {language === "english" ? "Subtitle:" : "Subtitulo:"}
                      </label>
                      <input
                        value={inputsKnowleadgeValues.subtitle}
                        onChange={handleChangeInputs}
                        type="text"
                        name="subtitle"
                        id="subtitle"
                        style={{
                          border: "none",
                          borderBottom: "1px solid hsl(228, 12%, 44%)",
                          color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                          fontFamily: "InterBold",
                          height: "40px",
                          backgroundColor: "transparent",
                          outline: "none",
                          border: "none",
                        }}
                        placeholder={
                          language === "english"
                            ? "Add here a subtitle "
                            : "Agrega un subtítulo acá"
                        }
                        required
                      />
                    </div>
                    <div>
                      <label
                        style={{
                          color: darkModeState
                            ? "rgb(255, 255, 255)"
                            : "rgb(30, 32, 42)",
                        }}
                        htmlFor="description"
                      >
                        {language === "english"
                          ? "Description:"
                          : "Descripción:"}
                      </label>
                      <textarea
                        value={inputsKnowleadgeValues.textarea}
                        onChange={handleChangeInputs}
                        required
                        name="textarea"
                        cols="30"
                        rows="10"
                        style={{
                          ...styles.textarea,
                          border: "none",
                          fontFamily: "InterBold",
                          color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                          backgroundColor: "transparent",
                          borderBottom: "1px solid hsl(228, 12%, 44%)",
                        }}
                        placeholder={
                          language === "english"
                            ? "Add here a description "
                            : "Agrega una descripción acá"
                        }
                      ></textarea>
                    </div>
                    <button style={{ ...styles.buttonUpload }} type="submit">
                      {language === "english"
                        ? "Add New File"
                        : "Añadir Nuevo Archivo"}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 10,
                    padding: "15px 0px 15px 0px",
                    backgroundColor: darkModeState
                      ? "hsl(228, 28%, 20%)"
                      : "hsl(227, 47%, 96%)",
                  }}
                >
                  {!darkModeState ? (
                    <>
                      <div class="spinner-border text-dark" role="status"></div>
                    </>
                  ) : (
                    <div class="spinner-border text-light" role="status"></div>
                  )}
                </div>
              )}
            </form>
          )}

          {selectedFile && (
            <div style={styles.boxFileAndUpload}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",

                  width: "100%",
                  height: "fit-content",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <strong
                    style={{
                      ...styles.subtitleNavbar,
                      fontSize: "1 rem",
                      marginTop: "12px",
                    }}
                  >
                    {selectedFile?.name}
                  </strong>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label style={{ marginBottom: "0px"  ,  color: darkModeState  ?  "#f2f2f2" : "#1c1c1c"}}>
                    <input
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      id="cbox1"
                      value="first_checkbox"
                    />{" "}
                    {language === "english"
                      ? "Add in Training"
                      : "Agregar en Capacitacion"}
                  </label>
                  <br />
                </div>

                <div style={styles.boxUploadSpinner}>
                  {selectedFile && !loading ? (
                    <button onClick={handleUpload} style={styles.buttonUpload}>
                      {language === "english" ? "Upload file" : "Subir Archivo"}
                    </button>
                  ) : (
                    <div style={{ ...styles.boxSpinner }}>
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {/* {loading && (
                    <div style={{ ...styles.boxSpinner }}>
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              ...styles.textsNavbar,
              position: "relative",
              marginTop: "20px",
            }}
          >
            <h2 style={styles.subtitleNavbar}>
              {subTitleMap[language].documents}
            </h2>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 25,
              marginTop: 25,
            }}
          >
            {infoAssistant.filesOpenAI?.map(
              ({ created_at, file_id, file_name , file_path , show_file }) => {
          
                return (
                  file_name !==
                    "manual_knowleadge_test.pdf" && (
                    <FileOpenAi
                      key={file_id}
                      language={language}
                      created_at={created_at}
                      id={file_id}
                      filename={file_name}
                      file_path={file_path}
                      styles={styles}
                      darkModeState={darkModeState}
                      handleDeleteDoc={handleDeleteDoc}
                      handleUpdateDoc={handleUpdateDoc}
                      showFile= {show_file}
                      setIsEnabledFile={setIsEnabledFile}
                      isEnabledFile={isEnabledFile}
                    />
                  )
                );
              }
            )}

            <AddManualKnowledge
              isLoading={isLoading}
              language={language}
              darkModeState={darkModeState}
              styles={styles}
              generateManualKnowleadge={generateManualKnowleadge}
              infoAssistant={infoAssistant}
              handleDeleteManualKnowledge={handleDeleteManualKnowledge}
              handleEditKnowledge={handleEditKnowledge}
              setIsEnabled={setIsEnabled}
              isEnabled={isEnabled}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const FileOpenAi = ({
  created_at,
  id,
  filename,
  styles,
  language,
  darkModeState,
  handleDeleteDoc,
  handleUpdateDoc,
  setIsEnabledFile,
  file_path,
  isEnabledFile,
  showFile
}) => {
  const [modalSureToDelete, setModalSureToDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const dateSplit = dateFormater(new Date(created_at * 1000)).split(" ");
  const hour = dateSplit[1];
  const date = dateSplit[0];


  const styleIcons = {
    marginRight: 7,
    maxWidth: 26,
    maxHeight: 30,
    minWidth: 26,
    minHeight: 30,
    color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
  };

  const translateSureToDeleteModal = {
    english: {
      title: "Are you sure you want to delete this document?",
      yes: "Yes",
      no: "No",
    },
    spanish: {
      title: "¿Estás seguro de eliminar este documento?",
      yes: "Sí",
      no: "No",
    },
  };

  return (
    <>
      <div style={styles.knowledge2}>
        {modalSureToDelete && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 2,
              backgroundColor: `${
                darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"
              }`,
              top: 0,
              left: 0,
              borderRadius: 5,
            }}
          >
            <span
              style={{
                color: darkModeState
                  ? "rgb(255, 255, 255)"
                  : "rgb(99, 104, 126)",
              }}
            >
              {translateSureToDeleteModal[language].title}{" "}
            </span>
            {!isLoadingDelete ? (
              <>
                <div style={{ display: "flex", gap: 10 }}>
                  <button
                    onClick={async () => {
                      setIsLoadingDelete(true);
                      await handleDeleteDoc({
                        fileId: id,
                        file_path
                      });
                      setIsLoadingDelete(false);
                      setIsEnabledFile(false);
                      console.log(
                        "Después de eliminar: isEnabledFile",
                        isEnabledFile
                      );
                    }}
                    style={{
                      padding: "5px 20px",
                      backgroundColor: "red",
                      width: "70px",
                    }}
                    className="defaultButton"
                  >
                    {language === "english" ? "Yes" : "Sí"}
                  </button>

                  <button
                    className="defaultButton"
                    style={{
                      padding: "5px 20px",
                      backgroundColor: "transparent",
                      color: darkModeState
                        ? "hsl(228, 34%, 66%)"
                        : "hsl(228, 12%, 44%)",
                      border: darkModeState
                        ? "1px solid hsl(228, 34%, 66%)"
                        : "1px solid hsl(228, 12%, 44%)",
                      width: "70px",
                    }}
                    onClick={() => {
                      setModalSureToDelete(false);
                      setIsEnabledFile(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 10,

                    padding: "15px 0px 15px 0px",
                  }}
                >
                  {!darkModeState ? (
                    <div class="spinner-border text-dark" role="status"></div>
                  ) : (
                    <div class="spinner-border text-light" role="status"></div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        <div
          style={{
            ...styles.knowledge2,
            height: "fit-content",
            justifyContent: "space-around",
            // ...styles.knowledge2,
            // position: "relative",
            // width: "100%",
            // height: "fit-content",
            // marginTop: "10px",
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            // padding: "10px",
          }}
        >
          <div style={{ ...styles.listHours }}>
            <p style={{ fontSize: "1rem", marginRight: "100px" }}>
              {date} {hour}
            </p>

            <h3
              style={{
                ...styles.subtitleNavbar,
                fontSize: "1rem",
                margin: 0,
              }}
            >
              {filename}
            </h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",

            position: "absolute",
            alignItems: "center",
            right: 5,
            top: 15,

            flexDirection: "row-reverse",
          }}
        >
          {!isEnabledFile && (
            <span
              onClick={() => {
                setModalSureToDelete(true);
                setIsEnabledFile(true);
              }}
              style={{
                ...styles.buttonUpload,
                backgroundColor: "none",
                cursor: isEnabledFile ? "not-allowed" : "pointer",
              }}
              type="button"
              disabled={isEnabledFile}
            >
              <TrashIcon styles={styleIcons} />
            </span>
          )}

          {!isEnabledFile && (
            <span
              onClick={() => {
                setIsEnabledFile(true);
                handleUpdateDoc({filename , file_path  , showFile});
              }}
              style={{
                ...styles.buttonUpload,
                backgroundColor: "none",
                cursor: isEnabledFile ? "not-allowed" : "pointer",
              }}
              type="button"
              disabled={isEnabledFile}
            >
              <EditIcon styles={styleIcons} />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const AddManualKnowledge = ({
  language,
  darkModeState,
  styles,
  generateManualKnowleadge,
  infoAssistant,
  handleDeleteManualKnowledge,
  handleEditKnowledge,
  setIsEnabled,
  isEnabled,
  isLoading,
}) => {
  const inputsInitalValue = {
    title: "",
    subtitle: "",
    textarea: "",
  };

  const [showInputField, setShowInputField] = useState(false);
  const [inputsKnowleadgeValues, setInputsKnowleadgeValues] =
    useState(inputsInitalValue);
  const [isLoadingAddManual, setIsLoadingAddManual] = useState(false);
  const [isLoadingFinally, setIsLoadingFinally] = useState(false);

  const handleChangeInputs = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputsKnowleadgeValues({ ...inputsKnowleadgeValues, [name]: value });
  };

  const handleSubmitNewManualKnowleadge = async (e) => {
    e.preventDefault();
    setIsLoadingAddManual(true);
    setIsLoadingFinally(true);
    await generateManualKnowleadge(
      inputsKnowleadgeValues,
      setIsLoadingAddManual,
      setShowInputField,
      setInputsKnowleadgeValues
    );
    setIsLoadingFinally(false);
    //setIsLoadingAddManual(false);
    /* setShowInputField(false);
    setInputsKnowleadgeValues({
      title: "",
      subtitle: "",
      textarea: "",
    }); */
  };
  const subTitleMap = {
    english: {
      documents: "Documents",
      manual: "Manual",
    },
    spanish: {
      documents: "Documentos",
      manual: "Manual",
    },
  };

  return (
    <section style={{ width: "100%" }}>
      {/* <div style={styles.textsNavbar}>
        <h3 style={styles.titleNavbar}>{titleNavbar[language].title}</h3>
        <h3 style={styles.subtitleNavbar}>{titleNavbar[language].subtitle}</h3>
      </div> */}

      {showInputField && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: "30px",
          }}
          onSubmit={handleSubmitNewManualKnowleadge}
        >
          {!isLoadingAddManual ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  backgroundColor: darkModeState
                    ? "hsl(228, 28%, 20%)"
                    : "hsl(227, 47%, 96%)",
                  padding: "15px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{
                      marginBottom: 0,
                      paddingBottom: "5px",
                      color: darkModeState
                        ? "rgb(255, 255, 255)"
                        : "rgb(30, 32, 42)",
                    }}
                    htmlFor="title"
                  >
                    {language === "english" ? "Title:" : "Titulo:"}
                  </label>
                  <input
                    value={inputsKnowleadgeValues.title}
                    onChange={handleChangeInputs}
                    type="text"
                    name="title"
                    id="title"
                    style={{
                      border: "none",
                      borderBottom: "1px solid hsl(228, 12%, 44%)",
                      color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                      fontFamily: "InterBold",
                      height: "40px",
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "none",
                    }}
                    placeholder={
                      language === "english"
                        ? "Add a title here"
                        : "Agregue un título aquí"
                    }
                    required
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{
                      marginBottom: 0,
                      paddingBottom: "5px",
                      color: darkModeState
                        ? "rgb(255, 255, 255)"
                        : "rgb0, 32, 42)",
                    }}
                    htmlFor="subtitle"
                  >
                    {language === "english" ? "Subtitle:" : "Subtitulo:"}
                  </label>
                  <input
                    value={inputsKnowleadgeValues.subtitle}
                    onChange={handleChangeInputs}
                    type="text"
                    name="subtitle"
                    id="subtitle"
                    style={{
                      border: "none",
                      borderBottom: "1px solid hsl(228, 12%, 44%)",
                      color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                      fontFamily: "InterBold",
                      height: "40px",
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "none",
                    }}
                    placeholder={
                      language === "english"
                        ? "Add here a subtitle "
                        : "Agrega un subtítulo acá"
                    }
                    required
                  />
                </div>
                <div>
                  <label
                    style={{
                      color: darkModeState
                        ? "rgb(255, 255, 255)"
                        : "rgb(30, 32, 42)",
                    }}
                    htmlFor="description"
                  >
                    {language === "english" ? "Description:" : "Descripción:"}
                  </label>
                  <textarea
                    value={inputsKnowleadgeValues.textarea}
                    onChange={handleChangeInputs}
                    required
                    name="textarea"
                    cols="30"
                    rows="10"
                    style={{
                      ...styles.textarea,
                      border: "none",
                      fontFamily: "InterBold",
                      color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                      backgroundColor: "transparent",
                      borderBottom: "1px solid hsl(228, 12%, 44%)",
                    }}
                    placeholder={
                      language === "english"
                        ? "Add here a description "
                        : "Agrega una descripción acá"
                    }
                  ></textarea>
                </div>
                <button style={{ ...styles.buttonUpload }} type="submit">
                  {language === "english"
                    ? "Add New File"
                    : "Añadir Nuevo Archivo"}
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 10,
                padding: "15px 0px 15px 0px",
                backgroundColor: darkModeState
                  ? "hsl(228, 28%, 20%)"
                  : "hsl(227, 47%, 96%)",
              }}
            >
              {!darkModeState ? (
                <>
                  <div class="spinner-border text-dark" role="status"></div>
                </>
              ) : (
                <div class="spinner-border text-light" role="status"></div>
              )}
            </div>
          )}
        </form>
      )}

      <div
        style={{
          ...styles.textsNavbar,
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <h2 style={styles.subtitleNavbar}>{subTitleMap[language].manual}</h2>
      </div>
      {/* Sera la lista de los nuevos documentos que se añadan cuando se añada la nueva documentacion recientemente añadida cuando el usuario añada manualmente la documentacion nueva. */}
      <article>
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: 9,
          }}
        >
          {infoAssistant.manualKnowledge?.map((knowledge) => {
            return (
              <ItemKnowledge
                knowledge={knowledge}
                styles={styles}
                language={language}
                handleDeleteManualKnowledge={handleDeleteManualKnowledge}
                handleEditKnowledge={handleEditKnowledge}
                setIsEnabled={setIsEnabled}
                isEnabled={isEnabled}
                isLoading={isLoading}
                infoAssistant={infoAssistant}
                darkModeState={darkModeState}
              />
            );
          })}
        </ul>
      </article>
    </section>
  );
};

function ItemKnowledge({
  knowledge,
  darkModeState,
  styles,
  language,
  handleDeleteManualKnowledge,
  handleEditKnowledge,
  isEnabled,
  setIsEnabled,
}) {
  const [knowledgeInput, setKnowledgeInput] = useState(null);
  const [sureToDeleteChatModal, setSureToDeleteChatModal] = useState(false);
  const [isLoadingManual, setIsLoadingManual] = useState(false);
  // const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [handleEditClick, setHandleEditClick] = useState(false);

  const handleSubmitEditManualKnowledge = async (e) => {
    e.preventDefault();
    setIsLoadingManual(true);
    await handleEditKnowledge(knowledgeInput);
    setIsLoadingManual(false);
    setKnowledgeInput(null);
    setIsEnabled(false);
  };
  const styleIcons = {
    marginRight: 7,
    maxWidth: 26,
    maxHeight: 30,
    minWidth: 26,
    minHeight: 30,
    color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
  };

  // const handleEditClick =  () =>{

  //   setIsEnabled(true)
  //   setKnowledgeInput(knowledge)

  // }

  return (
    <>
      <div style={styles.knowledge2}>
        <li
          key={knowledge.id}
          style={{
            ...styles.knowledge2,
            height: "fit-content",
            justifyContent: "space-around",
            // ...styles.knowledge2,
            // position: "relative",
            // width: "100%",
            // height: "fit-content",
            // marginTop: "10px",
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            // padding: "10px",
          }}
        >
          {!isLoadingManual ? (
            <>
              {!knowledgeInput ? (
                <>
                  <div style={{ ...styles.listHours }}>
                    <p style={{ fontSize: "1rem", marginRight: "100px" }}>
                      23/02/2024 15:30:55
                    </p>
                    <h3
                      style={{
                        ...styles.subtitleNavbar,
                        margin: 0,
                        fontSize: "1rem",
                      }}
                    >
                      {knowledge.title}
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmitEditManualKnowledge}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        style={{
                          border: "none",
                          borderBottom: "1px solid hsl(228, 12%, 44%",
                          fontFamily: "interBold",
                          backgroundColor: "transparent",
                          outline: "none",
                          height: "40px",
                          color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                        }}
                        type="text"
                        value={knowledgeInput.title}
                        onChange={(e) => {
                          setKnowledgeInput({
                            ...knowledgeInput,
                            title: e.target.value,
                          });
                        }}
                      />
                      <input
                        style={{
                          border: "none",
                          borderBottom: "1px solid hsl(228, 12%, 44%",
                          fontFamily: "interBold",
                          backgroundColor: "transparent",
                          outline: "none",
                          height: "40px",
                          color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                        }}
                        type="text"
                        value={knowledgeInput.subtitle}
                        onChange={(e) => {
                          setKnowledgeInput({
                            ...knowledgeInput,
                            subtitle: e.target.value,
                          });
                        }}
                      />
                      <textarea
                        style={{
                          ...styles.textarea,
                          border: "none",
                          color: darkModeState ? "white" : "hsl(228, 12%, 44%)",
                          fontFamily: "interBold",
                          backgroundColor: "transparent",
                          borderBottom: "1px solid hsl(228, 12%, 44%",
                          /* height:"fit-content" */
                        }}
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        value={knowledgeInput.description}
                        onChange={(e) => {
                          setKnowledgeInput({
                            ...knowledgeInput,
                            description: e.target.value,
                          });
                        }}
                      ></textarea>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <button style={{ ...styles.buttonUpload }} type="submit">
                        {language === "english" ? "Update" : "Actualizar"}
                      </button>
                      <button
                        style={{ ...styles.buttonUpload }}
                        type="button"
                        onClick={() => {
                          setKnowledgeInput(null);
                          setIsEnabled(false);
                        }}
                      >
                        {language === "english" ? "Cancel" : "Cancelar"}
                      </button>
                    </div>
                  </form>
                </>
              )}

              {!sureToDeleteChatModal && (
                <div
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 14,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  {/* BUTTON Delete FILE */}

                  {!knowledgeInput ? (
                    <>
                      {!isEnabled && (
                        <span
                          onClick={() => {
                            setSureToDeleteChatModal(true);
                            setIsEnabled(true);
                          }}
                          style={{
                            ...styles.buttonUpload,
                            backgroundColor: "none",

                            cursor: isEnabled ? "not-allowed" : "pointer",
                          }}
                          type="button"
                          disabled={isEnabled}
                        >
                          <TrashIcon styles={styleIcons} />
                        </span>
                      )}
                      {!isEnabled && (
                        <span
                          onClick={() => {
                            setIsEnabled(true);
                            setKnowledgeInput(knowledge);
                          }}
                          // onClick={handleEditClick}
                          style={{
                            ...styles.buttonUpload,
                            backgroundColor: "none",

                            cursor: isEnabled ? "not-allowed" : "pointer",
                            display: isEnabled ? "none" : "pointer",
                          }}
                          type="button"
                          disabled={isEnabled}
                        >
                          <EditIcon styles={styleIcons} />
                        </span>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {sureToDeleteChatModal && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 2,
                    backgroundColor: `${
                      darkModeState
                        ? "hsl(228, 28%, 20%)"
                        : "hsl(227, 47%, 96%)"
                    }`,
                    top: 0,
                    left: 0,
                    borderRadius: 5,
                  }}
                >
                  <span
                    style={{
                      color: darkModeState
                        ? "rgb(255, 255, 255)"
                        : "rgb(99, 104, 126)",
                    }}
                  >
                    {language === "english"
                      ? "Are you sure you want to delete this chat?"
                      : "¿Está seguro de eliminar esto?"}
                  </span>

                  <div style={{ display: "flex", gap: 10 }}>
                    <button
                      onClick={async (e) => {
                        e.stopPropagation();
                        setIsEnabled(false);
                        setIsLoadingManual(true);
                        await handleDeleteManualKnowledge({
                          fileId: knowledge.id,
                        });
                        setSureToDeleteChatModal(false);
                        setIsLoadingManual(false);
                      }}
                      style={{
                        padding: "5px 20px",
                        backgroundColor: "red",
                        width: "70px",
                      }}
                      className="defaultButton"
                    >
                      {language === "english" ? "Yes" : "Sí"}
                    </button>

                    <button
                      className="defaultButton"
                      style={{
                        padding: "5px 20px",
                        backgroundColor: "transparent",
                        color: darkModeState
                          ? "hsl(228, 34%, 66%)"
                          : "hsl(228, 12%, 44%)",
                        border: darkModeState
                          ? "1px solid hsl(228, 34%, 66%)"
                          : "1px solid hsl(228, 12%, 44%)",
                        width: "70px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEnabled(false);
                        setSureToDeleteChatModal(false);
                      }}
                    >
                      {language === "english" ? "No" : "No"}
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 10,

                padding: "15px 0px 15px 0px",
              }}
            >
              {!darkModeState ? (
                <div class="spinner-border text-dark" role="status"></div>
              ) : (
                <div class="spinner-border text-light" role="status"></div>
              )}
            </div>
          )}
        </li>
      </div>
    </>
  );
}
