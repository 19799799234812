import React from 'react'

export const LoadingSpinner = () => {
  return (
    <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
    </div>
  )
}

