import React, { useState } from "react";
import "./viewConversation.scss";
import { Chart as ChartJS } from "chart.js/auto"; // do not delete NEVERR

import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { dateFormater } from "../../utils/dateFormater";
import ericAvergonzadoIcon from "../../assets/images/eric-avergonzado.png";
const ViewConversation = ({
  handleDisplayConversation,
  onClose,
  selectedReport,
  language,
  darkModeState,
  setPopUp,
}) => {
  const {
    companyInfo,
    loginUser: { userUID },
  } = useSelector((state) => state.user);

  const { data: dbData } = useSelector((state) => state.firestoreReducer);
  const styles = {
    defaultButton: {
      backgroundColor: "rgb(77, 194, 241)",
      boxShadow: "2px 3px 10px rgba(0, 0, 0, 0.127)",
      outline: "none",
      padding: "10px 15px",
      border: "transparent",
      borderRadius: "7px",
      fontSize: ".9rem",
      width: "auto",
      transition: "all ease-in 0.3s",
      color: "white",
    },
    icons: {
      marginRight: "7px",
      maxWidth: "26px",
      maxHeight: "30px",
      minWidth: "26px",
      minHeight: "30px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
  };
  



  const userId = selectedReport.lastEvaluation.infoUser.userId;

  const filteredUserData = dbData
    .filter(({infoUser ,analyze: {calification}  }) => infoUser.userId === userId  && !isNaN(parseInt(calification))  )
    .map(({ analyze: {calification}  , infoUser: {name} , date , dateFormated }) => ({ calification , name , date , dateFormated }));


  const dates = []
  const califications = []
  for(const userData of filteredUserData){
    dates.push(userData.dateFormated)
    califications.push(userData.calification)
  }




  const data = {
    labels: ["",...dates],
    datasets: [
      {
        label:
          language === "english"
            ? "Conversation timeline"
            : "Línea de conversación",
        data: [0,...califications],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        color: "white",
        borderWidth: 1,
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const config = {
    color: darkModeState ? "white" : "black",
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: (ctx) =>
            "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
        },
      },
    },
  };

  return (
    <div className={`${darkModeState ? "modal-conversation-dark" : "modal-conversation-light"} `} >
      <button className="close-button-conversation" onClick={handleDisplayConversation}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          style={styles.icons}
        >
          <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
        </svg>
      </button>

      <div className="box-conversation">
        <div>
          <h1 className="modal-cont-title-h1">
            {language === "english" ? "Conversation timeline" : "Cronología de la conversación"}
          </h1>
        </div>

  

              <Line options={config} data={data} />
      
            {/* <>
              <h2>
                {language === "english"
                  ? "Oops! Seems there is not enough data 😅"
                  : "Ups! Parece que no hay datos suficientes 😅"}{" "}
              </h2>
              <img
                className="box-conversation-icon"
                src={ericAvergonzadoIcon}
                alt="eric avergonzado :P"
              />
            </> */}
        
      </div>
    </div>
  );
};

export default ViewConversation;



  

  

