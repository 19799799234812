import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import messageReducer from "./messageReducer";
//Redux - Slices

import { firestoreSlice } from "./slice/firestore/firestoreSlice"
import { settingsSlice } from "./slice/settings/settingsSlice"
import { screenSizeSlice } from "./slice/screenSize";
import { singleView } from "./slice/singleView";
import { playgroundTraining } from "./slice/playgroundTraining";
import { infoAssistant } from "./slice/infoAssistant/infoAsssistant";
import { questionsTraining } from "./slice/questionsTraining";
import { fineTuning } from "./slice/fineTuning";
import { questionsAssistant } from "./slice/questionsAssistant";
import { docsGalery } from "./slice/docsGalery/docsGalery";

export default combineReducers({
  user: loginReducer,
  message: messageReducer,

  firestoreReducer: firestoreSlice.reducer,
  settingsReducer: settingsSlice.reducer,
  screenSizeReducer: screenSizeSlice.reducer,
  singleViewReducer: singleView.reducer,
  playgroundTraining: playgroundTraining.reducer,
  infoAssistant: infoAssistant.reducer,
  questionsTraining: questionsTraining.reducer,
  fineTuning: fineTuning.reducer,
  questionsAssistant: questionsAssistant.reducer,
  docsGaleryReducer:  docsGalery.reducer
});
