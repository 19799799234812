import { createSlice } from "@reduxjs/toolkit";
import { dateFormater } from "../../../../utils/dateLogic/dateFormater";
import firebase from "../../../../config/firebaseIO";


const initialState = {
  conversation: null ,
  analyze: null ,
  queryRefID: null,
  isLoading: false,
  questionID: null,
  responseTime: null
}
export const playgroundTraining = createSlice({
  name: "playgroundTraining",

  initialState ,
  reducers: {
    setConversation: (state, { payload }) => {
     
      state.conversation = payload.allConversation.reverse();
      state.analyze = payload.analyze;
      state.queryRefID = payload.queryRefID;

      state.questionID = payload.questionID;

      state.isLoading = false;
    },
    setSingleEmotionAnalisys: (state, { payload }) => {
      state.singleEmotionAnalysis = payload;
      state.loadingAnalysis = false;
    },
    setLoadingConversation: (state, { payload }) => {
      state.loadingConversation = payload;
    },
    setLoadingAnalysis: (state, { payload }) => {
      state.loadingAnalysis = payload;
    }, 
    resetPlaygroundTraining:(state)=>{
      state.analyze = null
      state.conversation = null
      state.threadID = null
    },
    pushToConversation : (state, { payload }) =>{
      state.conversation.push({ role: 'user' , message: payload})
    },
    setIsLoading: (state, {payload}) =>{
    state.isLoading = payload;
    },
    setQuestion: (state,{payload})=>{
    
    },
    setNewKnowledge: (state, {payload})=>{

    },
    setResponseTime: (state, {payload})=>{
      state.responseTime = payload
    }
}});

export const {
  setSingleEmotionAnalisys,
  setConversation,
  setMode,
  setLoadingConversation,
  setLoadingAnalysis,
  resetPlaygroundTraining,
  setAnalyzePlayground,
  setCompany,
  pushToConversation,
  setIsLoading,
  setResponseTime

} = playgroundTraining.actions;
