import { dateFormater } from "../dateLogic/dateFormater";
import { setTimeAgo } from "../dateLogic/setTimeAgo";
import { formatTime } from "../formatTime";
import { groupByAgents } from "../groupByAgents";

const formatedCurrentDate = dateFormater(new Date()).split(" ")[0];

export const todayInteractionsData = (dbData) => {
  const interactionsOfToday = dbData?.filter((item) => {
    return item?.dateFormated.split(" ")[0] == formatedCurrentDate;
  });

  return interactionsOfToday;
};

export const weekInteractionsData = (dbData) =>
  dbData?.filter((interaction) => {
    const interactionDate = new Date(interaction?.date);
    return setTimeAgo(interactionDate, 6);
  });





export const predominantSatisfactionIndexWeek = (dbData, language) => {
  const interactionsThisWeek = dbData?.filter((interaction) => {
    const interactionDate = new Date(interaction?.date);
    return setTimeAgo(interactionDate, 6);
  });

  let suma = 0;

  // const ratings = interactionsThisWeek?.map((el) => {
  //     suma += el.analize.calification;
  // });

  return parseInt(suma / interactionsThisWeek?.length)
    ? `${parseInt(suma / interactionsThisWeek?.length)}%`
    : `${language === "english" ? "No interactions" : "No hay interacciones"}`;
};

export const predominantSatisfactionIndexDay = (dbData, language) => {
  const interactionsOfToday = dbData?.filter((item) => {
    return item?.dateFormated.split(" ")[0] == formatedCurrentDate;
  });

  let suma = 0;

  // const ratings = interactionsOfToday?.map((el) => {
  //     suma += el.analize.calification
  // });

  return parseInt(suma / interactionsOfToday?.length)
    ? `${parseInt(suma / interactionsOfToday?.length)}%`
    : `${language === "english" ? "No interactions" : "No hay interacciones"}`;
};

export const averageTime = (dbData, languaje) => {
  let everyTime =
    dbData?.map(({ responseTime }) => Math.floor(responseTime)).filter((n) => !isNaN(n)) || [];

  const totalSeconds = Math.floor(everyTime.reduce((acc, el) => acc + el, 0) / everyTime.length);

  if(!isNaN(totalSeconds)) return formatTime(totalSeconds);
};

export function topAgents(data, ascendingOrder = true) {
  // Agrupa los datos por agentes
  const agentData = groupByAgents(data);

  // Crea una copia profunda del array para evitar mutaciones inesperadas
  const agentDataClone = agentData.map(agent => ({ ...agent }));

  // Ordena el array clonado por calificación promedio
  agentDataClone.sort((a, b) => {
    if (a.averageCalification > b.averageCalification) {
      return ascendingOrder ? 1 : -1;
    }
    if (a.averageCalification < b.averageCalification) {
      return ascendingOrder ? -1 : 1;
    }
    return 0;
  });

  // Muestra el array clonado ordenado en la consola


  return agentDataClone;
}