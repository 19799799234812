export function formatTime (totalSeconds){      
    let minutes = Math.floor(totalSeconds / 60).toString();
    let seconds = Math.floor(totalSeconds % 60).toString();

    const minLength = minutes.length 
    const secLength = seconds.length 

    // minutes =  minLength  >= 2 ? minutes : "0"+minutes
    // seconds =  secLength >= 2 ? seconds : "0"+seconds
    // let aux = minutes === "00" ? "s" : "m"
    return (`${ minutes}m ${seconds}s`)
}

