import React, { useState } from "react";
//redux
import { useSelector } from "react-redux";
//components
import MetricsTraining from "../components/MetricsTraining";
import Sidebar from "./Sidebar";
// utils
import { todayInteractionsData, weekInteractionsData, predominantSatisfactionIndexDay, predominantSatisfactionIndexWeek, averageTime, topAgents  } from "../utils/totalizators/totalizator";
import { indexDataChart, interactionsDataChart, indexPerDayDataChart, technicalityDataChart, timeDataChart, indexDataCalificationChart , weeklyAvarageRating } from "../utils/charts";

import { dateFormater } from "../utils/dateLogic/dateFormater";
import { updatedTitle } from "../utils/updatedTitle";

const MetricsTrainingContainer = () => {
  const [messages, setMessages] = useState([]);
  const [displayCharts, setDisplayCharts] = useState(false);
  const screenSize = useSelector(state => state.screenSizeReducer.size);
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const { data: dbData , isFetching } = useSelector((state) => state.firestoreReducer);
  const formatedCurrentDate = dateFormater(new Date()).split(" ")[0];

  //chartsData
  const chartsData = {
    indexDataChart: indexDataChart(dbData, false, true),
    indexTimeDataChart: timeDataChart(dbData,language),
    indexTechnicality: technicalityDataChart(dbData,language),
    indexPerDay: indexPerDayDataChart(dbData),
    interactionsChart: interactionsDataChart(dbData, language),
    indexDataCalificationChart: indexDataCalificationChart (dbData, language),
    weeklyAvarageRatingData: weeklyAvarageRating(dbData , language)
  };





  
/*   const worstAgents = 
  const bestAgents = topAgents(dbData, false).slice(0,5) */

  const dataTopAgents = {
    bestAgents:{
       title: language === "english" ? "High ratings" : 'Calificaciones altas',
        data :topAgents(dbData, false).slice(0,5),
      },
      worstAgents:{
        title:language === "english" ? "Low ratings" :'Calificaciones bajas',
         data :topAgents(dbData, true).slice(0,5),
       }
   
  }


  //totalizatorData
  const totalizatorData = [
    {
      title: language === "english" ? "Evaluations count" : "Cantidad de evaluaciones",
      subtitle: formatedCurrentDate,
      quantity: {
        todayInteractions: todayInteractionsData(dbData)?.length,
      },
    },
    {
      title: language === "english" ? "Evaluations count" : "Cantidad de evaluaciones",
      subtitle: language === 'english' ? 'Last 7 days': 'Últimos 7 dias',
      quantity: {
        weekInteractions: weekInteractionsData(dbData)?.length,
      },
    },
    {
      title: language === "english" ? "Predominant rating" : "Calificación predominante",
      subtitle: formatedCurrentDate,
      quantity: {
        predominantSatisfactionIndexDay: predominantSatisfactionIndexDay(dbData, language),
      },
    },
    {
      title: language === "english" ? "Predominant rating" : "Calificación predominante",
      subtitle: language === 'english' ? 'Last 7 days': 'Últimos 7 dias',
      quantity: {
        predominantSatisfactionIndexWeek: predominantSatisfactionIndexWeek(dbData, language),
      },
    },
    {
      title: language === "english" ? "Average Response Time" : "Tiempo promedio de respuesta",
      quantity:{
        totalTime : averageTime(dbData, language)
      }
    }
  ];

  return (
    <div>
      <Sidebar />
      <MetricsTraining
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        messages={messages}
        chartsData={chartsData}
        displayCharts={displayCharts}
        totalizatorData={totalizatorData}
        updatedTitle={updatedTitle}
        isFetching={isFetching}
        dataTopAgents={dataTopAgents}
       
      />
    </div>
  );
};

export default MetricsTrainingContainer;
