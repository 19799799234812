import React, { useState } from "react";
import Confetti from "react-confetti";
//components
import PopUpStatus from "./PopUpStatus";
import PopUpFeatures from "./PopUpFeatures";
import PopUpClose from "./PopUpClose";
import PopUpAssign from "./PopUpAssign";
//icons
import cross from "../assets/icons/cross.svg";
import { ResetIcon } from "../commons/icons/ResetIcon";
import { SaveIcon } from "../commons/icons/SaveIcon";
import { ShareIcon } from "../commons/icons/ShareIcon";
import { UserIcon } from "../commons/icons/UserIcons";
import { TrashIcon } from "../commons/icons/TrashIcon";
//utils

export default ({
  screenSize,
  loginUser,
  messageToSend,
  handleDeleteHistory,
  handleReset,
  handleChangeText,
  darkModeState,
  handleReady,
  language,
  handleSubmit,
  handleStatus,
  chartState,
  status,
  handleSelectHistory,
  handleClose,
  popUpFeatures,
  handlePopUpFeatures,
  handlePopUpClose,
  popUpClose,
  popUpAssign,
  handlePopUpAssign,
  handleAssign,
  playgroundQuestionsState,
  companyName,
}) => {
  const styles = {
    backColor: {
      position: "fixed",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    container: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      padding: `${screenSize > 800 ? "3% 2% 3% 280px" : "30px"}`,
      margin: 0,
      height: "100%",
    },
    navbar: {
      display: "flex",
      flex: 1.5,

      marginBottom: `${screenSize > 800 ? "0px" : "30px"}`,
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 800 ? "5px" : "10px"}`,
      fontSize: "28px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      fontFamily: "InterBold",
      lineHeight: "1.4",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
      border: "none",
      outline: "none",
      padding: 0,
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterBold",
    },
    toogleNavbar: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: `${screenSize > 800 ? "flex-end" : "center"}`,
      flexDirection: `${screenSize > 800 ? "row" : "column"}`,
    },
    textToogle: {
      fontSize: "14px",
      fontFamily: "InterBold",
      alignSelf: "center",
      textDecoration: "none",
      color: "inherit",
      border: "none",
      outline: "none",
      padding: "12px",
      backgroundColor: `transparent`,
    },
    indicators: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
    },
    containerNoReady: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "80%",
    },
    notReady: {
      display: "flex",
      alignSelf: "center",
      marginTop: "30px",
      fontSize: "20px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    noChats: {
      display: "flex",
      alignSelf: "center",
      fontSize: "16px",
      fontFamily: "InterRegular",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    formButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#4DC2F1",
      borderRadius: 25,
      border: "none",
      padding: "12px 32px",
      margin: "30px 0px 0px",
      color: "#fff",
      outline: 0,
    },
    chatContainer: {
      //backgroundColor: "green",
      height: "80vh",
      borderRadius: "8px",
      display: "flex",
      // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
    },
    chatSessions: {
      flex: 4,
      display: "flex",
      padding: "10px",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,

      borderRadius: "10px",
      flexDirection: "column",
      margin: 0,
      boxShadow: `${
        darkModeState ? "0 3px 16px 0 hsl(228, 28%, 11%)" : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
      overflowY: "auto",
    },
    chatSessionsTitle: {
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      margin: "10px 10px 10px 20px",
    },
    chatNoSessions: {
      flex: 4,
      display: "flex",
      justifyContent: "center",
      padding: "10px",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
      borderRadius: "10px",
      flexDirection: "column",
      boxShadow: `${
        darkModeState ? "0 3px 16px 0 hsl(228, 28%, 11%)" : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
    },
    chatSessionPadding2: {
      display: "flex",
      flex: 2,
      padding: "0px 0px 10px 0px",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    chatSessionPadding3: {
      display: "flex",
      flex: 1,
      padding: "0px 0px 10px 0px",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    chatSessionPadding4: {
      display: "flex",
      flex: 4,
      padding: "0px",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
      overflow: "auto",
    },
    chatSession2: {
      display: "flex",
      flex: 1,
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      borderRadius: "8px",
      flexDirection: "row",
      border: "none",
      //    height: "18%"
      // padding: '6px 0px'
    },
    chatSession3: {
      display: "flex",
      flex: 1,
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      borderRadius: "8px",
      flexDirection: "row",
      border: "none",
      //    height: "18%"
      // padding: '6px 0px'
    },
    chatSession4: {
      display: "flex",
      flex: 1,
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      borderRadius: "8px",
      flexDirection: "row",
      border: "none",
      // overflow: "auto",
      //        height: "100%"
      // padding: '6px 0px'
    },
    chatChannel: {
      display: "flex",
      flex: 3,
      alignItems: "center",
      justifyContent: "center",
      // height: '100%',
    },
    chatChannelImage: {
      display: "flex",
      alignSelf: "center",
      height: 40,
    },
    chatInformation: {
      display: "flex",
      flex: 3,
      alignItems: "flex-start",
      // justifyContent: 'center',
      flexDirection: "column",
      overflow: "auto",
    },
    chatSessionName: {
      margin: "5% 10px 0px 10px",
      display: "flex",
      fontSize: 14,
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    chatSessionTextDiv: {
      display: "flex",
      //    alignSelf: 'flex-start',
      alignItems: "flex-end",
      justifyContent: "flex-end",
      height: "100%",
      //      overflow: "auto",
    },
    chatSessionTextDiv2: {
      display: "flex",
      //    alignSelf: 'flex-start',
      alignItems: "flex-end",
      justifyContent: "flex-end",
      //   height: '100%',
      //      overflow: "auto",
    },
    chatSessionText: {
      margin: "10px",
      display: "flex",
      alignSelf: "center",
      fontSize: "1.2rem",
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      whiteSpace: "initial",
      textAlign: "start",
      //    overflow: "auto",
      //lineBreak: 'anywhere'
    },
    chatSessionTextParagraph: {
      margin: "10px",
      display: "flex",
      alignSelf: "center",
      fontSize: 14,
      fontFamily: "InterBold",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      whiteSpace: "initial",
      textAlign: "start",
      //     overflow: "auto",
      // lineBreak: 'anywhere'
    },
    chatReceived: {
      display: "flex",
      flexDirection: "column",
      margin: "0px 10px",
      padding: "15px 20px",
      maxWidth: "60%",
      alignSelf: "flex-start",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: "#4dc2f1",
      color: "#fff",
      //boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
      borderRadius: "10px 10px 10px 0px",
      fontFamily: "InterRegular",
    },
    hourOfChatReceived: {
      display: "flex",
      margin: "8px 20px 10px 20px",
      alignSelf: "flex-end",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterRegular",
      fontSize: 12,
    },
    chatSent: {
      display: "flex",
      flexDirection: "column",
      padding: "15px 20px",
      maxWidth: "60%",
      margin: "10px 15px",
      alignSelf: "flex-end",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,
      //boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
      borderRadius: "10px 10px 0px",
      fontFamily: "InterRegular",
    },
    hourOfChatSent: {
      display: "flex",
      margin: "8px 20px 10px",
      alignSelf: "flex-end",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterRegular",
      fontSize: 12,
    },
    chatMessages: {
      flex: 6,
      display: "flex",
      // backgroundColor: "blue",
      borderRadius: "10px",
      flexDirection: "column",
      height: "100%",
      margin: "0px 20px 0px 0px",
      boxShadow: `${
        darkModeState ? "0 3px 16px 0 hsl(228, 28%, 11%)" : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
    },
    chatAll: {
      flex: 11,
      display: "flex",
      flexDirection: "column",
      height: "50%",
    },
    chatNoMessagesAll: {
      flex: 11,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: "0 10px 10px 0",
    },
    chatMessagesActions: {
      flex: 1,
      //  height: '150px',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      // backgroundColor: 'white',
      // boxShadow: `${darkModeState ? ("none") : ("0 3px 16px 0 rgba(90,90,90,0.2)")}`,
      borderRadius: "8px",
      padding: "0px 20px",
      margin: "10px 10px 10px 10px",
    },
    singleMessages: {
      display: "flex",
      flex: 11,
      overflow: "auto",
      flexDirection: "column",
    },
    userInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    chatChannelSingle: {
      display: "flex",
      // flex: 1,
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    chatChannelImageSingle: {
      display: "flex",
      alignSelf: "center",
      height: 20,
    },

    nameUser: {
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
      margin: "0px 10px",
    },
    buttonActionsGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonActions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      border: "none",
      color: "#fff",
      outline: 0,
      padding: "0px 10px",
    },
    iconActions: {
      width: "20px",
      height: "20px",
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
      margin: "0px 10px 0px 0px",
    },
    iconAnalyticsDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "45px",
      height: "45px",
      backgroundColor: "#4dc2f1",
      borderRadius: "50px",
      padding: "13px",
    },
    iconAnalytics: {
      display: "flex",
      flex: 1,
      alignSelf: "center",
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
      margin: "0px",
    },
    iconAnalyticsDiv2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "25px",
      height: "25px",
      backgroundColor: "#4dc2f1",
      borderRadius: "50px",
      padding: "6px",
    },
    iconAnalytics2: {
      display: "flex",
      flex: 1,
      alignSelf: "center",
      color: `#fff`,
      margin: "0px",
    },
    textActions: {
      color: "#fff",
      margin: "0px",
      fontSize: "12px",
      fontFamily: "InterRegular",
      margin: "0px",
      color: `${darkModeState ? "#fff" : "hsl(228, 12%, 44%)"}`,
    },
    textBoxChat: {
      flex: 1,
      display: "flex",
      borderRadius: "0px 10px 10px 10px",
      borderTop: `${
        darkModeState ? "solid 1px rgba(90,90,90,0.3)" : "solid 0.2px rgb(240,240,240)"
      }`,
      //  flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    inputChat: {
      display: "flex",
      flex: 10,
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      border: "0px",
      padding: "15px 20px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontFamily: "InterRegular",
      fontSize: "16px",
      backgroundColor: `${darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"}`,
    },
    textChat: {
      margin: "15px",
      fontSize: "16px",
      color: "black",
    },
    button: {
      display: "flex",
      flex: 2,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      backgroundColor: playgroundQuestionsState.loadingConversation ? "gray" : "#4dc2f1",
      height: "100%",
      border: "0px",
      color: "#fff",
      outline: 0,
      fontSize: "16px",
      fontFamily: "InterRegular",
    },
    buttonTest: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      marginTop: "20px",
      padding: "10px",
      border: "0px",
    },
    textButton: {
      fontSize: "16px",
      color: "black",
    },
    counter: { textAlign: "center" },
    textCounter: {
      fontSize: "100px",
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
    },
    buttonFloat: {
      position: "absolute",
      bottom: 0,
      left: 250,
      display: "flex",
      justifyContent: "flex-end",
      height: "280px",
    },
    custom: {
      color: "rgb(77, 194, 241)",
    },
    loadingsBox: {
      display: "flex",
      marginLeft: 5,
      gap: 20,
      height: 100,
    },
    oneConversation: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
      color: `${darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}`,
      padding: "10px 15px",
      marginBottom: 10,
      borderRadius: 5,
      cursor: "pointer",
      minHeight: "6rem",
      alignItems: "center",
    },
    buttonConfirm: {
      backgroundColor: "#4dc2f1",
      fontSize: "16px",
      fontFamily: "InterRegular",
      borderRadius: "5px",
      color: "#fff",
      padding: "5px 10px",
      fontWeight: "bold",
      border: "0px",
      cursor: "pointer",
      minWidth: "3.5rem",
    },
  };

  let isOurCompanyName =
    companyName.toLowerCase() === "insideone" || companyName.toLowerCase() === "nubemax";

  const buttonActionsGroups = [
    {
      action: handlePopUpFeatures,
      title: language === "english" ? "Share" : "Compartir",
      icon: <ShareIcon styles={styles} />,
    },
    {
      action: handlePopUpFeatures,
      title: language === "english" ? "Save" : "Guardar",
      icon: <SaveIcon styles={styles} />,
    },

    {
      action: handleReset,
      title: language === "english" ? "Reset" : "Reiniciar",
      icon: <ResetIcon styles={styles} />,
    },
  ];

  return (
    <>
      {status === "ready" ? (
        <Confetti
          numberOfPieces={200}
          gravity={0.1}
          recycle={false}
          width={window.screen.width - 50}
          height={window.screen.height - 50}
        />
      ) : (
        <></>
      )}
      <div style={styles.backColor} />
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>
              {language === "english" ? <>Chat Assistant</> : <>Asistente de chat</>} 😎
            </h3>
            <h3 style={styles.subtitleNavbar}>
              {language === "english"
                ? "Use this site to ask questions."
                : "Utilice este sitio para hacer preguntas."}
            </h3>
          </div>
          {/* <div className={darkModeState ? ("textDarkToogle") : ("textLightToogle")} style={styles.toogleNavbar}>
                        {darkModeState === true ? (<button style={styles.textToogle} onClick={handleDarkMode}><>{language === 'english' ? ('Light Mode') : ('Tema Claro')}</></button>) : (<button style={styles.textToogle} onClick={handleDarkMode}>{language === 'english' ? ('Dark Mode') : ('Tema Oscuro')}</button>)}
                    </div> */}
        </div>
        <div style={styles.indicators}>
          {chartState === true ? (
            <PopUpStatus
              darkModeState={darkModeState}
              screenSize={screenSize}
              handleReady={handleReady}
              handleStatus={handleStatus}
            />
          ) : (
            <></>
          )}
          {popUpFeatures === true ? (
            <PopUpFeatures
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUpFeatures={handlePopUpFeatures}
            />
          ) : (
            <></>
          )}
          {popUpClose === true ? (
            <PopUpClose
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUpClose={handlePopUpClose}
              handleClose={handleClose}
            />
          ) : (
            <></>
          )}
          {popUpAssign === true ? (
            <PopUpAssign
              screenSize={screenSize}
              darkModeState={darkModeState}
              language={language}
              handlePopUpAssign={handlePopUpAssign}
              handleAssign={handleAssign}
            />
          ) : (
            <></>
          )}
          <div style={styles.chatContainer}>
            <div style={styles.chatMessages}>
              <div style={styles.chatAll}>
                <div style={styles.chatMessagesActions}>
                  <div style={styles.userInformation}>
                    <div style={styles.chatChannelSingle}>
                      {/* User icon */}
                      <div style={styles}>
                        <UserIcon styles={styles} />
                      </div>
                    </div>
                    <span style={styles.nameUser}>
                      {loginUser.firstName} {loginUser.lastName}
                    </span>
                  </div>
                  {/* SHARE SAVE RESET BUTTONS */}
                  <div style={styles.buttonActionsGroup}>
                    {buttonActionsGroups.map((e, i) => (
                      <button key={i} style={styles.buttonActions} onClick={e.action}>
                        {e.icon}
                        <span style={styles.textActions}>{e.title}</span>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Mensajes */}
                <div
                  id="chatQuestionsAssistant"
                  className="chatBotScrollBar"
                  style={styles.singleMessages}
                >
                  {/* Content conversation */}
                  {playgroundQuestionsState.conversation.length ? (
                    <>
                      {playgroundQuestionsState.conversation.map((singleMessage, i) => {
                        const role = singleMessage.role;
                        const switchStyleMessage = {
                          user: i ? styles.chatSent : { ...styles.chatSent, marginTop: 0 },
                          assistant: styles.chatReceived,
                        };

                        return (
                          <React.Fragment key={i}>
                            <div id={"chatSingleMessage"} style={switchStyleMessage[role]}>
                              {singleMessage.message}
                            </div>
                          </React.Fragment>
                        );
                      })}

                      {playgroundQuestionsState.loadingConversation && (
                        <div style={{ display: "flex", gap: 10 , margin: '10px  0 0 20px' }}>
                          <div className={`spinner-grow spinner-grow-sm ${darkModeState ?  'text-light' :  'text-info'}`} role="status" />

                          <div className={`spinner-grow spinner-grow-sm ${darkModeState ?  'text-light' :  'text-info'}`} role="status" />

                          <div className={`spinner-grow spinner-grow-sm ${darkModeState ?  'text-light' :  'text-info'}`} role="status" />
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <form style={styles.textBoxChat} onSubmit={handleSubmit}>
                <input
                  name="textChat"
                  type="text"
                  // rows="1"
                  className="form-control"
                  placeholder={language === "english" ? "Write here..." : "Escribe aquí..."}
                  style={styles.inputChat}
                  value={messageToSend}
                  onChange={handleChangeText}
                  disabled={playgroundQuestionsState.loadingConversation}
                />

                <button
                  type="submit"
                  style={styles.button}
                  onClick={handleSubmit}
                  disabled={playgroundQuestionsState.loadingConversation}
                >
                  {language === "english" ? "Send" : "Enviar"}
                </button>
              </form>
            </div>
            <ul className="chatBotScrollBar" style={styles.chatSessions}>
                {playgroundQuestionsState.history.map((chat, i) => {
                  return (
                    <ListHistory
                      key={i}
                      handleSelectHistory={handleSelectHistory}
                      styles={styles}
                      handleDeleteHistory={handleDeleteHistory}
                      chat={chat}
                      darkModeState={darkModeState}
                      playgroundQuestionsState={playgroundQuestionsState}
                      language={language}
                    />
                  );
                })}
              </ul>
            {/* {!playgroundQuestionsState.loadingConversation ? (
              <ul className="chatBotScrollBar" style={styles.chatSessions}>
                {playgroundQuestionsState.history.map((chat, i) => {
                  return (
                    <ListHistory
                      key={i}
                      handleSelectHistory={handleSelectHistory}
                      styles={styles}
                      handleDeleteHistory={handleDeleteHistory}
                      chat={chat}
                      darkModeState={darkModeState}
                      playgroundQuestionsState={playgroundQuestionsState}
                    />
                  );
                })}
              </ul>
            ) : playgroundQuestionsState.loadingConversation ? (
              <div style={styles.chatNoSessions}>
                <div style={styles.noChats}>
                  {language === "english" ? "Loading..." : "Cargando... "}
                </div>
              </div>
            ) : (
              <div style={styles.chatNoSessions}>
                <div style={styles.noChats}>
                  {language === "english" ? "No analysis" : "Sin análisis realizado"}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

const ListHistory = ({
  handleSelectHistory,
  styles,
  handleDeleteHistory,
  chat,
  darkModeState,
  playgroundQuestionsState,
  language
}) => {
  const [sureToDeleteChatModal, setSureToDeleteChatModal] = useState(false);
  const firstMessage = chat?.conversation[0]?.message;
  const createdAt = chat?.dateFormated?.split(" ");

  const date = createdAt[0];
  const time = createdAt[1];

  function firstLetterCapitalized(message) {
    return message.charAt(0).toUpperCase() + message.slice(1);
  }

  return (
    <li
      onClick={() => handleSelectHistory(chat)}
      style={{
        ...styles.oneConversation,
        position: "relative",
      }}
    >
      <div
        style={{
          color: "rgb(77, 194, 241)",
          borderRight: "1px solid rgb(77, 194, 241)",
          display: "flex",  
          flexDirection: "column",
          justifyContent: "center",
          paddingRight: 10 
        }}
      >
        <strong>{time}</strong>
        <strong>{date}</strong>
      </div>
      <span style={{ color: darkModeState ? "rgb(255, 255, 255)" : "rgb(99, 104, 126)" , paddingLeft: 15 }}>
        {firstLetterCapitalized(firstMessage)}
      </span>{" "}
      {sureToDeleteChatModal && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: `${darkModeState ? "hsl(228, 28%, 20%)" : "hsl(227, 47%, 96%)"}`,
            top: 0,
            left: 0,
            borderRadius: 5
            ,

            boxShadow: darkModeState ? "" : "0px 2px 5px rgb(21, 189, 227 )",
          }}
          
        >
          <span style={{ color: darkModeState ? "rgb(255, 255, 255)" : "rgb(99, 104, 126)" }}>

            {language === "english" ? (
              "Are you sure you want to delete this chat?"
            ) : (
              "¿Seguro de que quieres eliminar este chat?"
            )}

          </span>
          {!playgroundQuestionsState.loadingDelete ? (
            <div style={{ display: "flex", gap: 10 }}>
              <button
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleDeleteHistory(chat);
                  setSureToDeleteChatModal(false);
                }}
                style={{...styles.buttonConfirm,backgroundColor: "red"}}
              >
                { language === "english" ? "Yes" :  "Sí"}
              </button>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSureToDeleteChatModal(false);
                }}
                style={{...styles.buttonConfirm, backgroundColor: "transparent", color: "red", border: "1px solid red"}}
              >
                No
              </button>
            </div>
          ) : (
            <div class="spinner-border text-light" role="status"></div>
          )}
        </div>
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setSureToDeleteChatModal(true);
        }}
        style={{
          position: "absolute",
          top: 10,
          right: 11,
          cursor: "pointer",
        }}
      >
        <TrashIcon styles={styles.iconAnalytics} />
      </div>
    </li>
  );
};
