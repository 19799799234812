import React from "react";

export default function DateIcon({ styles }) {
  // const styles = {
  //   marginRight: 7,
  //   maxWidth: 26,
  //   maxHeight: 30,
  //   minWidth: 26,
  //   minHeight: 30,
  //   color: "rgb(139, 151, 198)",
  // };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      style={styles}
    >
      <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
      <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
    </svg>
  );
}
