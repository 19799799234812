import React from "react";
import { Link } from "react-router-dom";

export default ({
  handleLogout,
  handleProfile,
  loginUser,
  darkModeState,
  language,
  companyUser,
  screenSize,
  toggleSidebar,
  handleToggleSidebar,
  mail,
}) => {
  let displaySidebar = screenSize <= 800 && !toggleSidebar;
  const styles = {
    sidebar: {
      display: displaySidebar ? "none" : "block",
      width: screenSize <= 800 ? "80vw" : "250px",
      position: "fixed",
      top: 0,
      left: 0,
      height: "100vh",
      zIndex: 800,
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      //  boxShadow: '0 3px 16px 0 rgba(50, 50, 255, 0.2)',
      //  boxShadow: '0 3px 16px 0 hsl(228, 28%, 1%)',
      boxShadow: `${
        darkModeState
          ? "0 3px 16px 0 hsl(228, 28%, 11%)"
          : "0 3px 16px 0 rgba(90, 90, 90, 0.2)"
      }`,
      opacity: 1,
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      // fontSize: 15
      //  transition: all 0.3s;
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: screenSize <= 800 ? "10px 10px 10px" : "36px 30px 20px",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
    },
    linkContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      height: "80%",
      alignItems: "center",
      // paddingRight: 20,
    },

    link: {
      display: "flex",
      alignItems: "center",
      padding: "20px",
      marginRight: `${language === "english" ? "25px" : ""}`
      
    },
    linkButton: {
      display: "flex",
      alignItems: "center",
      backgroundColor: `${
        darkModeState ? "hsl(230, 17%, 14%)" : "hsl(0, 0%, 100%)"
      }`,
      color: "#63687e",
      border: "none",
      outline: 0,
      width: "100%",
    },
    icons: {
      marginRight: "15px",
      width: "18px",
      height: "20px",
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
    },
    option: {
      color: `${darkModeState ? "hsl(228, 34%, 66%)" : "hsl(228, 12%, 44%)"}`,
      fontSize: "16.5px",
      margin: "0px",
      textAlign: "left",
    },
    toggleButtonBurger: {
      zIndex: 3000,
      position: "absolute",
      left: 7,
      top: 7,
      cursor: "pointer",
    },
    toggleButtonClose: {
      position: "absolute",
      right: 10,
      top: 10,
      cursor: "pointer",
    },
  };

  return (
    <div className="wrapper">
      {screenSize <= 800 && (
        <div style={styles.toggleButtonBurger} onClick={handleToggleSidebar}>
          {!toggleSidebar ? (
            <svg
              color={darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          ) : (
            <></>
          )}
        </div>
      )}
      <nav
        //id="sidebar"
        style={styles.sidebar}
      >
        <div style={styles.toggleButtonClose} onClick={handleToggleSidebar}>
          {toggleSidebar && screenSize <= 800 ? (
            <svg
              color={darkModeState ? "hsl(0, 0%, 100%)" : "hsl(230, 17%, 14%)"}
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          ) : (
            <></>
          )}
        </div>

        <div style={styles.logoContainer}>
          <img
            src={
              mail.includes("nubemax.com")
                ? "https://i.postimg.cc/8zfzzyj3/Logo-full-color.png"
                : "https://i.postimg.cc/bN6SJ6fx/logo-insideone.png"
            }
            height="55"
            alt="Logo"
          />
        </div>
        <hr />
        <div style={styles.linkContainer}>
          <ul
            // style={{
            //   height: "calc(90% - 80px)",
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "space-evenly",
            // }}
            className="list-unstyled"
          >
            <li>
              <Link className="active" style={styles.link} to="/metrics">
                <svg
                  style={styles.icons}
                  fill="currentColor"
                  className="bi bi-pie-chart"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8" />
                </svg>

                {language === "english" ? "Metrics" : "Métricas"}
              </Link>
            </li>
            <li>
              <Link className="active" style={styles.link} to="/evaluations">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-clipboard2-check"
                  viewBox="0 0 16 16"
                  style={{ marginRight: 16 }}
                >
                  <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
                  <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
                  <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                </svg>
                {language === "english" ? "Evaluations" : "Evaluaciones"}
              </Link>
            </li>

            <li>
              <Link className="active" style={styles.link} to="/agents">
                <svg
                  style={styles.icons}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-headset"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5" />
                </svg>
                {language === "english" ? "Agents" : "Agentes"}
              </Link>
            </li>

            <li>
              <Link className="active" style={styles.link} to="/learning">
                <svg
                  style={styles.icons}
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="currentColor"
                  viewBox="0 0 23 23"
                >
                  <path d="M4 8H2v12a2 2 0 0 0 2 2h12v-2H4z"></path>
                  <path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-9 12V6l7 4z"></path>
                </svg>

                {language === "english" ? "Training" : "Capacitación"}
              </Link>
            </li>

            <li>
              <Link className="active" style={styles.link} to="/playground">
                <svg
                  style={styles.icons}
                  fill="currentColor"
                  className="bi bi-play-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445" />
                </svg>

                {language === "english" ? "Playground" : "Playground"}
              </Link>
            </li>

            <li>
              <Link className="active" style={styles.link} to="/questions">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-robot"
                  viewBox="0 0 16 16"
                  style={styles.icons}
                >
                  <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
                  <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
                </svg>

                {language === "english" ? "Assistant" : "Asistente"}
              </Link>
            </li>
          { ["Developer" ,"Supervisor" , "Administrator"].includes(loginUser.rol) && 
        
            <li>
              <Link className="active" style={styles.link} to="/knowledge">
                <svg
                  style={styles.icons}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-book"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                </svg>

                {language === "english" ? "Knowledge" : "Conocimiento"}
              </Link>
            </li>
            }

            {loginUser.rol === "Developer" ||
            loginUser.rol === "Agent" ||
            loginUser.rol === "Supervisor" ||
            loginUser.rol === "Administrator" ? (
              <li>
                <Link className="active" style={styles.link} to="/settings">
                  <svg
                    style={styles.icons}
                    fill="currentColor"
                    className="bi bi-sliders"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"
                    />
                  </svg>
                  {language === "english" ? "Settings" : "Ajustes"}
                </Link>
              </li>
            ) : (
              <></>
            )}
          </ul>
          <ul
            style={{ position: "relative", display: "inline-block" }}
            className="list-unstyled"
          >
            {loginUser ? (
              <li style={{ padding: "10px" }}>
                <button
                  className="nav-link active"
                  style={styles.linkButton}
                  onClick={handleProfile}
                >
                  <svg
                    style={styles.icons}
                    fill="currentColor"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                  <h1 style={styles.option}>
                    {language === "english"
                      ? `Hello ${loginUser.firstName}!`
                      : `Hola ${loginUser.firstName}!`}
                  </h1>
                </button>
              </li>
            ) : (
              <></>
            )}

            <li style={{ padding: "10px" }}>
              <button
                className="nav-link active"
                style={styles.linkButton}
                onClick={handleLogout}
              >
                <svg
                  style={styles.icons}
                  fill="currentColor"
                  className="bi bi-box-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                  />
                </svg>
                <h1 style={styles.option}>
                  {language === "english" ? "Log Out" : "Salir"}
                </h1>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

//TODO Craer mails          //ta
//TODO Cambiar companyID Main //ta
//TODO Subir archivos medife // proccess

// //TODO-A Crear asistentes para medife
//   //1 Analisis de datos
//   //2 Creador de preguntas
//   //3 Assistente de preguntas   //ESTO YA ESTA

// //TODO-B Guardar los asistentes en firebase
//   //
// 1 Guardar los archivos en un nuevo buket con el nombre de la empresa storage de firebase

// //TODO
