const initialState = {
  loginUser: {},
  companyInfo: {},
  surveys: [],
  darkTheme: false,
  language: 'spanish',
  status: 'pending',
  isAuth: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return Object.assign({}, state, {
        loginUser: action.user,
        isAuth: true,
      });
    case "SET_LOGOUT":
      return Object.assign({}, state, {
        loginUser: action.user,
        isAuth: false,
      });
    case "SET_COMPANY_INFO":
      // console.log('como viene al  reducer>', action.companyInfo)
      return Object.assign({}, state, {
        companyInfo: action.companyInfo,
      });
    case "SET_SURVEYS_INFO":
      // console.log('como viene al  reducer>', action.companyInfo)
      return Object.assign({}, state, {
        surveys: action.surveys,
      });
    // case "SET_COMPANY_INFO":
    //   console.log('como viene al  reducer>', action.companyInfo)
    //   return Object.assign({}, state, {
    //     companyInfo: action.companyInfo,
    //   });
    case "SET_DARK_THEME":
      return Object.assign({}, state, {
        darkTheme: action.darkTheme,
      });
    case "SET_LANGUAGE":
      return Object.assign({}, state, {
        language: action.language,
      });
    case "SET_READY":
      return Object.assign({}, state, {
        status: action.status,
      });
    default:
      return state;
  }
};
