import React, { useState, useEffect, useRef } from "react";
//router
import { useLocation } from "react-router";
//redux
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../store/reducers/slice/firestore";
import { firestoreSlice } from "../../store/reducers/slice/firestore";
//compoenent
import FilterOneFeel from "../../components/filters/FilterOneFeel";
import { groupByAgents } from "../../utils/groupByAgents";

import { toast } from "react-toastify";

const filtersInitialValues = {
  date: { start: null, end: null },
  rating: {
    min: 0,
    max: 100,
  },
  inputSearch: "",
};

const FilterOneFeelClient = () => {
  //location
  const locationInstance = useLocation();
  const location = locationInstance.pathname;
  //redux
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const language = useSelector((state) => state.user.language);
  const dataFirestore = useSelector((store) => store.firestoreReducer.data);
  const screenSize = useSelector((state) => state.screenSizeReducer.size);

  const dispatch = useDispatch();
  //Variables para filtrar por Rango
  const refFilter = useRef();
  //states
  const [filters, setFilters] = useState(filtersInitialValues);
  const [isFilterRangeActive, setIsFilterRangeActive] = useState(false);

  const [data, setData] = useState(null);

  // Data input search
  const handleChangeInputSearch = (e) => {
    const name = e.target.name; //inputSearch
    const value = e.target.value; //123456
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  // Clear Button
  const handleClearButton = () => {
    setFilters(filtersInitialValues);
    dispatch(setFilter(null));
    setIsFilterRangeActive(false);
  };

  //Data Date
  const handleChangeDates = (e) => {
    const name = e.target.name;
    const fecha = new Date(e.target.value + " 12:00:00");

    if (name === "start") {
      setFilters({
        ...filters,
        date: { [name]: fecha, end: filters.date.end },
      });
    } else {
      setFilters({
        ...filters,
        date: { [name]: fecha, start: filters.date.start },
      });
    }
  };
  //Rating handler
  const handleChangeRating = (event) => {
    event.stopPropagation();
    const name = event.target.name;
    const value = parseInt(event.target.value);
    if (name === "min" && value > filters.rating.max) {
      return;
    }
    if (name === "max" && value < filters.rating.min) {
      return;
    } else {
      setFilters({ ...filters, rating: { ...filters.rating, [name]: value } });
    }
  };

  const handleFilter = () => {
    if (!dataFirestore) {
      return toast.warn(
        language === "english"
          ? "There is no data to filter"
          : "No hay datos para filtrar"
      );
    }
    setIsFilterRangeActive(false);

    const filterDate = data.filter((obj) => {
      const filterDateStart =
        filters.date.start && new Date(filters.date.start);
      const filterDateEnd = filters.date.end && new Date(filters.date.end);
      const date = new Date(obj.date);
      //filtro por fecha
      if (filterDateStart) {
        // Crear nuevas fechas solo con el año, mes y día de la fecha original
        const dateYearMonthDay = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );

        const filterDateStartYearMonthDay = new Date(
          filterDateStart.getFullYear(),
          filterDateStart.getMonth(),
          filterDateStart.getDate()
        );
        const filterDateEndYearMonthDay =
          filters.date.end &&
          new Date(
            filterDateEnd.getFullYear(),
            filterDateEnd.getMonth(),
            filterDateEnd.getDate()
          );

        // Compara solo el año, mes y día de las fechas

        return filterDateEnd
          ? dateYearMonthDay >= filterDateStartYearMonthDay &&
              dateYearMonthDay <= filterDateEndYearMonthDay
          : dateYearMonthDay >= filterDateStartYearMonthDay &&
              dateYearMonthDay <= filterDateStartYearMonthDay;
      }

      return true;
    });

    if (location === "/evaluations") {
      const dataFiltered = filterDate.filter((obj) => {
        //* Variables
        const technicality = parseInt(obj.analyze.technicality);

        const rating = parseInt(obj.analyze.calification);
        const agent = obj.infoUser.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        //*Filtreo por rating
        const filterForRating =
          rating >= filters.rating.min && rating <= filters.rating.max;

        const conditions = [filterForRating];

        //*Filters for search
        if (filters.inputSearch !== filtersInitialValues.inputSearch) {
          const inputSearch = filters.inputSearch.toLowerCase();
          const isANumber = !isNaN(parseInt(inputSearch));

          if (isANumber) {
            const numberWithOutPlusSign = technicality
              .toString()
              .replace(/\+/g, "");

            //Numbers starts with

            const technicalityStartWith = technicality
              .toString()
              .startsWith(inputSearch);

            const conditionsNumbers =
              numberWithOutPlusSign.indexOf(inputSearch) !== -1 &&
              technicalityStartWith;

            conditions.push(conditionsNumbers ? true : false);
          } else {
            conditions.push(agent.indexOf(inputSearch) !== -1);
          }
        }
        return conditions.every((boolean) => boolean === true);
      });

      if (dataFirestore.length === dataFiltered.length) {
        dispatch(setFilter(null));
      } else {
        dispatch(setFilter(dataFiltered));
      }
    } else {
      //AGENT

      //hacer filtros de interacciones , calificacion promedio y indice de satisfaccion y nombre

      const specificDataForAgents = groupByAgents(filterDate).filter((obj) => {
        console.log(obj);
        const agent = obj.name.toLowerCase();
        const rating = obj.averageCalification;
        const averageTechnicality = Math.round(obj.averageTechnicality).toString();
        const totalEvaluations = obj.totalEvaluations.toString();

        const filterForRating =
          rating >= filters.rating.min && rating <= filters.rating.max;

        const conditions = [filterForRating];

        //*Filters for search
        if (filters.inputSearch !== filtersInitialValues.inputSearch) {
          const inputSearch = filters.inputSearch.toLowerCase().trim();
          const averageTechnicalityStartWith = averageTechnicality.startsWith(inputSearch);
            
          const totalEvaluationstartWith = totalEvaluations.startsWith(inputSearch);
           
          const conditionsToSearch =
            agent.indexOf(inputSearch) !== -1 ||
            (totalEvaluationstartWith &&
              totalEvaluations.indexOf(inputSearch) !== -1) ||
            (averageTechnicalityStartWith &&
              averageTechnicality.indexOf(inputSearch) !== -1);

          conditions.push(conditionsToSearch);
        }

        return conditions.every((boolean) => boolean === true);
      });

      if (dataFirestore.length === specificDataForAgents.length) {
        dispatch(setFilter(null));
      } else {
        dispatch(setFilter(specificDataForAgents));
      }
      console.log(specificDataForAgents);
    }
  };

  const handleClickRange = () => {
    setIsFilterRangeActive(!isFilterRangeActive);
  };

  useEffect(() => {
    if (isFilterRangeActive) {
      refFilter.current.style.display = "block";
    } else {
      refFilter.current.style.display = "none";
    }
  }, [isFilterRangeActive]);

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("data")));
    handleClearButton();
  }, [dataFirestore, location]);

  return (
    <div>
      <FilterOneFeel
        darkModeState={darkTheme}
        language={language}
        handleChangeInputSearch={handleChangeInputSearch}
        handleClickRange={handleClickRange}
        handleChangeRating={handleChangeRating}
        refFilter={refFilter}
        isFilterRangeActive={isFilterRangeActive}
        screenSize={screenSize}
        handleFilter={handleFilter}
        handleChangeDates={handleChangeDates}
        filters={filters}
        handleClearButton={handleClearButton}
      />
    </div>
  );
};

export default FilterOneFeelClient;
