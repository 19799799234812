import React, { useRef, useState } from "react";
import axios from "axios";

import { setIsLoading } from "../store/reducers/slice/questionsAssistant";

import QuestionAssistant from "../components/QuestionsAssistant";
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  removeQuestion,
  setQuestions,
} from "../store/reducers/slice/questionsAssistant";
//components
import Sidebar from "./Sidebar";
import { compareTwoArrays } from "../utils/compareTwoArrays";
const QuestionsAssistantClient = () => {
  const language = useSelector((state) => state.user.language);
  const darkTheme = useSelector((state) => state.user.darkTheme);
  const screenSize = useSelector((state) => state.screenSizeReducer.size);

  const questionsAssistantState = useSelector(
    (state) => state.questionsAssistant
  );
  const { name: companyName } = useSelector((state) => state.user.companyInfo);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { userUID } = useSelector((state) => state.user.loginUser);
  
  

  //<3 y ahora? siguiente paso?

  //TODO: 1. Setear question input con el valor de la question a editar (desde el handle)
  //TODO: 2.pasar questionINput por prop
  //TODO: 3. resolver paso 1 y 2

  const dispatch = useDispatch();
  const inputRef = useRef();
  const handleAddQuestionAssistant = async (e) => {
    e.preventDefault();
    let question = inputRef.current.value;
    setIsLoadingSave(true);
    const endpoint =
      "https://addquestionassistant-cfcs6xjhea-uc.a.run.app";
    await axios.post(`${endpoint}?instanceName=${companyName}`, { question });
    inputRef.current.value = "";
    setIsLoadingSave(false);
  };

  const handleRemoveQuestion = async ({ id: questionId }) => {
    const endpoint =
      "https://deletequestionsassistant-cfcs6xjhea-uc.a.run.app";
    dispatch(removeQuestion(questionId));
    await axios.post(
      `${endpoint}?instanceName=${companyName}&questionId=${questionId}`,
      {
        userUID,
      }
    );
  };

  const handleGenerateQuestions = async ()=>{
    try{
    dispatch(setIsLoading(true))

    // const urlLocal = "http://localhost:5000/insideone-onetraining/us-central1/questionsGeneratorAssistant"
    const url = "https://questionsgeneratorassistant-cfcs6xjhea-uc.a.run.app"
    const {data} =  await axios(`${url}?instanceName=${companyName}`)
    console.log(data)
    dispatch(setIsLoading(false))
    }catch(error){
      console.log(error)
      dispatch(setIsLoading(false))

    }
   
  }

  const handleEditQuestion = async (updatedQuestion) => {
    const questions = questionsAssistantState.questions;

    const newQuestions = questions.map((question) =>
      question.id === updatedQuestion.id ? updatedQuestion : question
    );

    dispatch(setQuestions(newQuestions));

    const endpoint =
      "https://updatequestions-cfcs6xjhea-uc.a.run.app";

    await axios.post(`${endpoint}?instanceName=${companyName}`, {
      newQuestions,
    });
  };

  return (
    <div>
      <Sidebar />
      <QuestionAssistant
        //STATES

        questionsAssistantState={questionsAssistantState}
        screenSize={screenSize}
        darkModeState={darkTheme}
        language={language}
        isLoadingSave={isLoadingSave}
        //Handlers
        handleAddQuestionAssistant={handleAddQuestionAssistant}
        handleRemoveQuestion={handleRemoveQuestion}
        handleEditQuestion={handleEditQuestion}
        handleGenerateQuestions={handleGenerateQuestions}
        //ref
        inputRef={inputRef}
      />
    </div>
  );
};

export default QuestionsAssistantClient;
